import React, { useState, useEffect, useRef } from "react";
import firebase from "firebase";
import {
  Row,
  Label,
  Input,
  Col,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import axios from "axios";
import ScrollToBottom from "react-scroll-to-bottom";
import Swal from "sweetalert2";
import {
  handleError,
  HttpCallPost,
  HttpCallGet,
} from "../../chatapp/apis/UseHttp";
import {
  GetChat,
  UploadFile,
  BaseUrl,
  FIRESTORE_KEY_NAME,
  commonMediaUpload,
  orderDetails,
} from "../../chatapp/apis/Networks";
import { POST, GET } from "../../chatapp/apis/constants";
import Images from "../../chatapp/assets";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { Select, Spin, Tooltip } from "antd";
import { Loader } from "../../Components";
import { Box } from "@material-ui/core";
import { Icons } from "../../Utils";
import { Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/menu";
import { useDisclosure } from "@chakra-ui/hooks";
import { useDispatch, useSelector } from "react-redux";
import { actionCreators } from "../../Redux";
import { bindActionCreators } from "redux";
import toast from "react-hot-toast";
import { API } from "../../Services";
import { TagUserAvtar } from "./trelloStyle";

const ProjectChat = (props) => {
  const [chatlist, setChatList] = useState([]);
  const [textValue, setTextValue] = useState("");
  const [chatDetail, setChatDetail] = useState("");
  const [isSpace, setIsSpace] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [myfiles, setMyfiles] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [modal, setModal] = useState(false);
  const [isArchive, setIsArchive] = useState(false);
  const [archiveChat, setArchiveChat] = useState(null);
  const [isfileUploadLoad,setFileUploadLoad]= useState(false)
  const [selectedMemberId, setSelectedMemberId] = useState([]);
  const [selectedMessageId, setSelectedMessageId] = useState();
  const uploadInputRef = useRef(null);
  const { id } = useParams();
  const mId = JSON.parse(localStorage.getItem("adminDetails"));
  const [isDisabled, setIsDisabled] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();


  const {
    isOpen: isAcknowledgedOpen,
    onOpen: onAcknowledgeOpen,
    onClose: onAcknowledgeClose,
  } = useDisclosure();

  const { Option } = Select;
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { trelloCommentList, admins } = bindActionCreators(
    actionCreators,
    dispatch
  );
  useEffect(() => {
    admins();
  }, []);

  const TagSelectedMember = () => {
    // setLoad((prev)=>!prev)
    console.log(selectedMemberId);
    const IDs = selectedMemberId.map((d) => {
      return d.value;
    });
    console.log(IDs);
    var data = {
      // value:selectedMemberId.value
      messageId: selectedMessageId,
      members: IDs,
    };
    API.tagMembersToMessage(id, data)
      .then((res) => {
        // setLoad((prev)=>!prev)
        console.log(res);
        toast.success(res.data.message || "Member Taged successfully.", {
          position: "top-right",
        });
        listenMessages(id);
        setSelectedMessageId("");
        onClose();
        setSelectedMemberId("");
        trelloCommentList(id, 20, 1);
      })
      .catch((err) => {
        // setLoad((prev)=>!prev)
        setSelectedMessageId("");

        toast.error("Oops! Failed to Add Member.", {
          position: "top-right",
        });
        onClose();
      });
  };
  const AcknowledgeChat = () => {
    // setLoad((prev)=>!prev)
    console.log("selectedMessageId", selectedMessageId);
    var data = {
      // value:selectedMemberId.value
      messageId: selectedMessageId,
      member: mId.adminId,
    };
    API.acknowledgeChatMessage(id, data)
      .then((res) => {
        // setLoad((prev)=>!prev)
        console.log(res);
        toast.success(res.data.message || "Message acknowledge successfully.", {
          position: "top-right",
        });
        listenMessages(id);
        setSelectedMessageId("");
        onAcknowledgeClose();
        trelloCommentList(id, 20, 1);
      })
      .catch((err) => {
        // setLoad((prev)=>!prev)
        setSelectedMessageId("");

        toast.error("Oops! Failed to Acknowledge.", {
          position: "top-right",
        });
        onAcknowledgeClose();
      });
  };

  const getChatDetail = () => {
    let dataObj = [];

    const DataPath = firebase.firestore().collection(FIRESTORE_KEY_NAME);
    DataPath.where("orderCode", "==", `${id}`)
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          dataObj.push(doc.data());
        });
        console.log("getchatDetail", dataObj);
        if(dataObj.length>0)
{        setChatDetail(dataObj[0]);
}else{
          setChatDetail([]);

}      })
      .catch(function (error) {
        console.log("Error getting documents: ", error);
      });
  };
  const toggleModals = () => {
    const userData = localStorage.getItem("token");
    HttpCallGet(`${GetChat}${id}`, GET, userData)
      .then((res) => {
        setIsArchive(true);
        setArchiveChat(res.data);
      })
      .catch((err) => {
        handleError(err); // Assuming handleError is a function for error handling
      });

    setModal(!modal);
  };
  const getFormatedDate = (createdDate) => {
    let dateHere = createdDate.split("T");
    dateHere = dateHere[0].split('"');
    return dateHere[1];
  };
  const getFormatedDate1 = (createdDate) => {
    if (
      createdDate !== undefined ||
      createdDate !== null ||
      createdDate !== ""
    ) {
      let dateHere = createdDate.split("T");
      return dateHere[0];
    } else {
      return "";
    }
  };
  const handleChange = (event) => {
    console.log("message", event.target.value);
    setTextValue(event.target.value);
    setIsSpace(false);
  };

  const onChangeFile = (event) => {
    console.log("file", event.target.files);
    const file = event.target.files[0];

    setSelectedFile(event.target.files);

    for (let x = 0; x < event.target.files.length; x++) {
      setMyfiles((prevMyfiles) => [...prevMyfiles, event.target.files[x]]);
    }

    toggleModal();
  };
  const listenMessages = (code) => {
    setChatList([]);
    const dataPath = firebase
      .firestore()
      .collection(FIRESTORE_KEY_NAME)
      .doc(code || id)
      .collection("MESSAGES");

    dataPath
      .orderBy("createdDate")
      .get()
      .then(function (querySnapshot) {
        const cities = [];
        querySnapshot.forEach(function (doc) {
          // console.log("doc.id",doc.id);
          const messageData = doc.data();
          messageData.messageId = doc.id;
          // console.log("doc.id",messageData);

          cities.push(messageData);
        });
        console.log("chats....list", cities);
        setChatList(cities);
      })
      .catch(function (error) {
        console.log("Error getting documents: ", error);
      });
  };
  const toggleModal = () => {
    setModal(!modal);
  };
  const getName = (name) => {
    if (name) {
      return name?.charAt(0);
    }
  };

  const onSendMessage = () => {
    if (textValue.trim().length === 0) {
      setIsSpace(true);
    } else {
      setIsDisabled(true);

      let userData = JSON.parse(localStorage.getItem("adminDetails"));
      let myDate = new Date();

      var newItem = {
        message: textValue,
        postedby: {
          _id: userData.adminId,
          name: userData.firstName,
          userType: "admin",
        },
        orderCode: id,
        messageType: "T",
      };

      var newItem2 = {
        lastModificationTime: new Date(),
        isadminpendingreply: false,
      };

      var formData = new FormData();
      for (var i in newItem) {
        formData.append(i, newItem[i]);
      }

      setIsLoader(true);
      const userToken = localStorage.getItem("token");

      HttpCallPost(`${GetChat}${id}`, POST, userToken, formData)
        .then((res) => {
          if (res.status === 200) {
            firebase
              .firestore()
              .collection(FIRESTORE_KEY_NAME)
              .doc(id)
              .update(newItem2);

            setTextValue("");
            setIsDisabled(false);
            setIsLoader(false);

            listenMessages(id);
          }
        })
        .catch((err) => {
          setIsLoader(false);
          setTextValue("");
          // Handle error if needed
        });
    }
  };
  useEffect(() => {
    getChatDetail();
    props.scrollToTop();
  }, []);

  useEffect(() => {
    listenMessages(id);
  }, []);
  const onEnterSendMessage = (event) => {
    if (event.keyCode === 13) {
      setIsDisabled(true);
      onSendMessage();
    }
  };

  const uploadFIle = async () => {
    for (var x = 0; x < selectedFile.length; x++) {
      await uploadFileApi(selectedFile[x]);
    }
  };
  const uploadFileApi = (filedata) => {
    setIsLoader(true);
setFileUploadLoad(true);
    setIsDisabled(true);

    let data = {
      media: filedata,
      entityType: "order",
      entityId: id,
      acl: "public",
      returnType: "url",
    };

    let formData = new FormData();
    for (var i in data) {
      formData.append(i, data[i]);
    }

    axios({
      method: POST,
      url: `${BaseUrl}${commonMediaUpload}`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "X-API-KEY": `${localStorage.getItem("token")}`,
      },
      data: formData,
    })
      .then((response) => {
        console.log(" media uploaded");
        var newItem = {
          message: textValue,
          messageType: "M",
          mediaId: response.data.url,
        };

        var fData = new FormData();
        for (var i in newItem) {
          fData.append(i, newItem[i]);
        }

        var newItem2 = {
          lastModificationTime: new Date(),
          isadminpendingreply: false,
        };

        setIsLoader(true);
        const userToken = localStorage.getItem("token");

        HttpCallPost(`${GetChat}${id}`, POST, userToken, fData)
          .then((res) => {
            firebase
              .firestore()
              .collection(FIRESTORE_KEY_NAME)
              .doc(id)
              .update(newItem2);
            setTextValue("");
            setIsDisabled(false);
            setIsLoader(false);
            toggleModal();
            listenMessages(id);
          })
          .catch((err) => {
            setIsLoader(false);
            setFileUploadLoad(false);

            setTextValue("");
            if (err.response && err.response.status === 404) {
              Swal.fire({
                position: "center",
                type: "success",
                title: "You are not authorized to post chat.",
              });
            } else {
              setFileUploadLoad(false);

              handleError(err); // Assuming handleError is a function for error handling
            }
          });
      })
      .catch((err) => {
                      setFileUploadLoad(false);

        setIsLoader(false);
        handleError(err); // Assuming handleError is a function for error handling
      });
  };
  const onEnterMediaSendMessage = (event) => {
    console.log("event.keyCode", event.keyCode);
    if (event.keyCode === 13) {
      setIsDisabled(true);
      uploadFIle();
    }
  };
  const onInputFocusHandle = () => {
    let userData = JSON.parse(localStorage.getItem("adminDetails"));
    var newItem1 = {
      typing: 1,
      blob: userData.firstName + " " + userData.lastName,
      unread: 0,
    };

    firebase
      .firestore()
      .collection(FIRESTORE_KEY_NAME)
      .doc(id)
      .update(newItem1);
    // firebase.database().ref().child('messages').child(id + 'Typing').update(newItem1);
  };

  const onInputBlurHandle = () => {
    let userData = JSON.parse(localStorage.getItem("adminDetails"));
    var newItem1 = {
      typing: 0,
      blob: userData.firstName + " " + userData.lastName,
      unread: 0,
    };

    firebase
      .firestore()
      .collection(FIRESTORE_KEY_NAME)
      .doc(id)
      .update(newItem1);
  };
    const backgroundColor = (() => {
      if (
        chatDetail?.isadminpendingreply &&
        chatDetail?.lastcustomerreply &&
        (new Date().getTime() / 1000 - chatDetail.lastcustomerreply.seconds) /
          60 /
          60 <
          240
      ) {
        const hours =
          (new Date().getTime() / 1000 - chatDetail.lastcustomerreply.seconds) /
          60 /
          60;
        const colorcode = (hours.toFixed(1) / 240) * 255;
        return `rgb(255, ${240 - colorcode}, 0)`;
      } else if (chatDetail?.isadminpendingreply) {
        return "rgb(255, 0, 0)";
      }

      return "";
    })();

  return (
    <div>
      <div className="chatview1" style={{ backgroundColor: "#e8fff0" }}>
        <div className="p-chat-box">
          <Row>
            <Col md={9}>
              {" "}
              <Box display="flex">
                <b>{`Chat With ${chatDetail?.customer?.name}`}</b>
                {chatDetail?.isadminpendingreply && (
                  <div
                    style={{
                      height: "15px",
                      width: "15px",
                      background: backgroundColor,
                      float: "right",
                      marginTop: "5px",
                      borderRadius: "16px",
                      marginLeft: "2px",
                    }}
                  >
                    <p>{""}</p>
                  </div>
                )}
                {chatDetail.unread > 0 && (
                  <div className="circleView">
                    <p>{chatDetail.unread}</p>
                  </div>
                )}
              </Box>
            </Col>
            {/* <button className="buttonLoad" onClick={() => this.loadOldChats(this.props.ordercode)}>
                                <i class="fa fa-comments" aria-hidden="true"></i>
                                Load old messages
                            </button> */}
            {/* <button className="buttonLoad" onClick={() => toggleModals()}>
              <i class="fa fa-comments" aria-hidden="true"></i>
              Archives Chat
            </button> */}
          </Row>
        </div>
        <ScrollToBottom className={"p-chats"}>
          {chatlist.length == 0 ? (
            <p className="NOChat">No Chat Found</p>
          ) : (
            <div className="p-chatList" style={{ marginTop: "2px" }}>
              {chatlist &&
                chatlist.map((item, index) => {
                  return item.postedBy ? (
                    <div key={index}>
                      {item.postedBy.userType === "admin" ||
                      item.userType !== "user" ? (
                        <div
                          className="rowWithoutMarginUser"
                          style={{ float: "inline-end", position: "relative" }}
                        >
                          {item.messageType === "M" ? (
                            <div className="PChatImageBox">
                              {item?.taggedMembers && (
                                <Box
                                  position="absolute"
                                  top="-12px"
                                  right="4.2rem"
                                  height="40px"
                                >
                                  <Box display="flex">
                                    {" "}
                                    {item?.taggedMembers.map((row, index) => (
                                      <div
                                        style={{
                                          position: "relative",
                                        }}
                                        className="ap-flex p-1 "
                                        key={index}
                                      >
                                        <div className="avtar-div">
                                          <Tooltip title={row?.name}>
                                            <TagUserAvtar
                                              onClick={() => {
                                                if (row.userId == mId.adminId) {
                                                  setSelectedMessageId(
                                                    item.messageId
                                                  );

                                                  onAcknowledgeOpen();
                                                } else {
                                                  toast.error(
                                                    "Error! You are not eligible to Acknowledge this message!",
                                                    {
                                                      position: "top-right",
                                                    }
                                                  );
                                                }
                                              }}
                                              style={{
                                                backgroundColor:
                                                  row.acknowledged
                                                    ? "green"
                                                    : "red",
                                              }}
                                            >
                                              <div className="tag-member-initials">
                                                {row?.name?.slice(0, 2)}
                                              </div>
                                            </TagUserAvtar>
                                          </Tooltip>
                                        </div>
                                      </div>
                                    ))}
                                  </Box>
                                </Box>
                              )}
                              <Box display="flex" justifyContent="flex-end">
                                <Menu>
                                  <MenuButton>
                                    <Icons.DotIcon />
                                  </MenuButton>
                                  <MenuList>
                                    <MenuItem
                                      onClick={() => {
                                        setSelectedMessageId(item.messageId);
                                        onOpen();
                                      }}
                                    >
                                      Tag This Message
                                    </MenuItem>
                                  </MenuList>
                                </Menu>
                              </Box>
                              <div>
                                <img
                                  src={
                                    item.mediaDetails.extention === "pdf"
                                      ? Images.pdf
                                      : item.mediaDetails.extention === "png" ||
                                        item.mediaDetails.extention ===
                                          "jpeg" ||
                                        item.mediaDetails.extention === "jpg"
                                      ? item.mediaDetails.url
                                      : Images.file
                                  }
                                />
                                <p>{item.displayName}</p>
                                <p className="msgText">{item.message} </p>
                                <a
                                  href={item.mediaDetails.url}
                                  target="_blank"
                                  style={{ fontSize: "10px" }}
                                >
                                  {item.mediaDetails.url}
                                </a>
                                <p
                                  className="date"
                                  style={{ fontSize: "9px", marginTop: "5px" }}
                                >
                                  {getFormatedDate1(item.createdDate)}
                                </p>
                                {item.isSend === 1 || item.isSend === "1" ? (
                                  <div
                                    style={{
                                      justifyContent: "flex-end",
                                      alignSelf: "flex-end",
                                      textAlign: "end",
                                    }}
                                  >
                                    <img
                                      src={Images.doubleTick}
                                      style={{ height: 20, width: 20 }}
                                    />
                                  </div>
                                ) : (
                                  <div
                                    style={{
                                      justifyContent: "flex-end",
                                      alignSelf: "flex-end",
                                      textAlign: "end",
                                    }}
                                  >
                                    <i
                                      style={{
                                        color: " var( --primary-green)",
                                        alignSelf: "flex-end",
                                      }}
                                      className="fa fa-check"
                                    ></i>
                                  </div>
                                )}
                              </div>
                            </div>
                          ) : item.messageType === "T" &&
                            item.message === "" ? null : (
                            <div className="ChatMessage">
                              {item?.taggedMembers && (
                                <Box
                                  position="absolute"
                                  top="-8px"
                                  right="4.2rem"
                                  height="40px"
                                >
                                  <Box display="flex">
                                    {" "}
                                    {item?.taggedMembers.map((row, index) => (
                                      <div
                                        style={{
                                          position: "relative",
                                        }}
                                        className="ap-flex p-1 "
                                        key={index}
                                      >
                                        <div className="avtar-div">
                                          <Tooltip title={row?.name}>
                                            <TagUserAvtar
                                              onClick={() => {
                                                if (row.userId == mId.adminId) {
                                                  setSelectedMessageId(
                                                    item.messageId
                                                  );

                                                  onAcknowledgeOpen();
                                                } else {
                                                  toast.error(
                                                    "Error! You are not eligible to Acknowledge this message!",
                                                    {
                                                      position: "top-right",
                                                    }
                                                  );
                                                }
                                              }}
                                              style={{
                                                backgroundColor:
                                                  row.acknowledged
                                                    ? "green"
                                                    : "red",
                                              }}
                                            >
                                              <div className="tag-member-initials">
                                                {row?.name?.slice(0, 2)}
                                              </div>
                                            </TagUserAvtar>
                                          </Tooltip>
                                        </div>
                                      </div>
                                    ))}
                                  </Box>
                                </Box>
                              )}{" "}
                              <Box display="flex" justifyContent="flex-end">
                                <Menu>
                                  <MenuButton>
                                    <Icons.DotIcon />
                                  </MenuButton>
                                  <MenuList>
                                    <MenuItem
                                      onClick={() => {
                                        setSelectedMessageId(item.messageId);
                                        onOpen();
                                      }}
                                    >
                                      Tag This Message
                                    </MenuItem>
                                  </MenuList>
                                </Menu>
                              </Box>
                              <p className="date">{item.message} </p>
                              <p
                                className="date"
                                style={{ fontSize: "9px", marginTop: "5px" }}
                              >
                                {getFormatedDate1(item.createdDate)}
                              </p>
                              {item.isSend === 1 || item.isSend === "1" ? (
                                <div
                                  style={{
                                    justifyContent: "flex-end",
                                    alignSelf: "flex-end",
                                    textAlign: "end",
                                  }}
                                >
                                  <img
                                    src={Images.doubleTick}
                                    style={{ height: 20, width: 20 }}
                                  />
                                </div>
                              ) : (
                                <div
                                  style={{
                                    justifyContent: "flex-end",
                                    alignSelf: "flex-end",
                                    textAlign: "end",
                                  }}
                                >
                                  <i
                                    style={{
                                      color: " var( --primary-green)",
                                      alignSelf: "flex-end",
                                    }}
                                    className="fa fa-check"
                                  ></i>
                                </div>
                              )}
                            </div>
                          )}
                          {item.messageType === "T" &&
                          item.message === "" ? null : (
                            <div>
                              <div className="userIcon">
                                {getName(item.postedBy)}
                              </div>
                              <p
                                style={{
                                  textAlign: "center",
                                  color: "black",
                                  fontSize: "11px",
                                  marginTop: "-5px",
                                }}
                              >
                                {item.postedBy}
                              </p>
                            </div>
                          )}
                        </div>
                      ) : (
                        <div
                          className="rowWithoutMargin"
                          key={index}
                          style={{
                            float: "inline-end",
                            position: "relative",
                          }}
                        >
                          {item.messageType === "T" &&
                          item.message === "" ? null : (
                            <div>
                              <div className="userIcon">
                                {getName(item.postedBy)}
                              </div>
                              <p
                                style={{
                                  textAlign: "center",
                                  color: "black",
                                  fontSize: "11px",
                                  marginTop: "-5px",
                                  width: "50px",
                                  overflowWrap: "-moz-initial",
                                }}
                              >
                                {item.postedBy}
                              </p>
                            </div>
                          )}
                          {item.messageType === "M" ? (
                            <div className="ChatImageBox">
                              {item?.taggedMembers && (
                                <Box
                                  position="absolute"
                                  top="-8px"
                                  right="4.2rem"
                                  height="40px"
                                >
                                  <Box display="flex">
                                    {" "}
                                    {item?.taggedMembers.map((row, index) => (
                                      <div
                                        style={{
                                          position: "relative",
                                        }}
                                        className="ap-flex p-1 "
                                        key={index}
                                      >
                                        <div className="avtar-div">
                                          <Tooltip title={row?.name}>
                                            <TagUserAvtar
                                              onClick={() => {
                                                if (row.userId == mId.adminId) {
                                                  setSelectedMessageId(
                                                    item.messageId
                                                  );

                                                  onAcknowledgeOpen();
                                                } else {
                                                  toast.error(
                                                    "Error! You are not eligible to Acknowledge this message!",
                                                    {
                                                      position: "top-right",
                                                    }
                                                  );
                                                }
                                              }}
                                              style={{
                                                backgroundColor:
                                                  row.acknowledged
                                                    ? "green"
                                                    : "red",
                                              }}
                                            >
                                              <div className="tag-member-initials">
                                                {row?.name?.slice(0, 2)}
                                              </div>
                                            </TagUserAvtar>
                                          </Tooltip>
                                        </div>
                                      </div>
                                    ))}
                                  </Box>
                                </Box>
                              )}{" "}
                              <Box display="flex" justifyContent="flex-end">
                                <Menu>
                                  <MenuButton>
                                    <Icons.DotIcon />
                                  </MenuButton>
                                  <MenuList>
                                    <MenuItem
                                      onClick={() => {
                                        setSelectedMessageId(item.messageId);
                                        onOpen();
                                      }}
                                    >
                                      Tag This Message
                                    </MenuItem>
                                  </MenuList>
                                </Menu>
                              </Box>
                              <div>
                                <img
                                  src={
                                    item.extention === "pdf"
                                      ? Images.pdf
                                      : item.extention === "png" ||
                                        item.extention === "jpeg" ||
                                        item.extention === "jpg"
                                      ? item.mediaDetails?.url
                                      : Images.file
                                  }
                                />
                                <p>{item.displayName}</p>
                                <p className="msgText">{item.message} </p>
                                <a
                                  href={item.mediaDetails?.url}
                                  target="_blank"
                                  style={{ fontSize: "10px" }}
                                >
                                  {item.mediaDetails?.url}
                                </a>
                                <p
                                  className="date"
                                  style={{
                                    fontSize: "9px",
                                    marginTop: "5px",
                                  }}
                                >
                                  {getFormatedDate1(item.createdDate)}
                                </p>
                              </div>
                            </div>
                          ) : item.messageType === "T" &&
                            item.message === "" ? null : (
                            <div
                              className="ChatMessage"
                              style={{
                                position: "relative",

                                backgroundColor:
                                  item.messageType === "RC"
                                    ? "#8BD18B"
                                    : "#e6e8eb",
                              }}
                            >
                              {item?.taggedMembers && (
                                <Box
                                  position="absolute"
                                  top="-17px"
                                  right="1rem"
                                  height="40px"
                                >
                                  <Box display="flex">
                                    {" "}
                                    {item?.taggedMembers.map((row, index) => (
                                      <div
                                        style={{
                                          position: "relative",
                                        }}
                                        className="ap-flex p-1 "
                                        key={index}
                                      >
                                        <div className="avtar-div">
                                          <Tooltip title={row?.name}>
                                            <TagUserAvtar
                                              onClick={() => {
                                                if (row.userId == mId.adminId) {
                                                  setSelectedMessageId(
                                                    item.messageId
                                                  );

                                                  onAcknowledgeOpen();
                                                } else {
                                                  toast.error(
                                                    "Error! You are not eligible to Acknowledge this message!",
                                                    {
                                                      position: "top-right",
                                                    }
                                                  );
                                                }
                                              }}
                                              style={{
                                                backgroundColor:
                                                  row.acknowledged
                                                    ? "green"
                                                    : "red",
                                              }}
                                            >
                                              <div className="tag-member-initials">
                                                {row?.name?.slice(0, 2)}
                                              </div>
                                            </TagUserAvtar>
                                          </Tooltip>
                                        </div>
                                      </div>
                                    ))}
                                  </Box>
                                </Box>
                              )}{" "}
                              <Box display="flex" justifyContent="flex-end">
                                <Menu>
                                  <MenuButton>
                                    <Icons.DotIcon />
                                  </MenuButton>
                                  <MenuList>
                                    <MenuItem
                                      onClick={() => {
                                        setSelectedMessageId(item.messageId);
                                        onOpen();
                                      }}
                                    >
                                      Tag This Message
                                    </MenuItem>
                                  </MenuList>
                                </Menu>
                              </Box>{" "}
                              <p>{item.message} </p>
                              {item.messageType === "RC" ? (
                                <p>{"Client requested a call....."}</p>
                              ) : null}
                              <p
                                className="date"
                                style={{ fontSize: "9px", marginTop: "5px" }}
                              >
                                {getFormatedDate1(item.createdDate)}
                              </p>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  ) : null;
                })}
            </div>
          )}
        </ScrollToBottom>{" "}
        <div className="p-inputView">
          <div className="p-boxView">
            <input
              type="text"
              onChange={(e) => handleChange(e)}
              value={textValue}
              onKeyDown={(e) => onEnterSendMessage(e)}
              onFocus={onInputFocusHandle}
              onBlur={onInputBlurHandle}
              disabled={!props.isAllow || isDisabled}
              placeholder={
                props.isAllow
                  ? "Type something here"
                  : "You do not have permission to chat.."
              }
              style={{
                color: props.isAllow ? "#000" : "red",
                fontWeight: props.isAllow ? "normal" : "bold",
              }}
              autoFocus={true}
            />

            <input
              id="myInput"
              type="file"
              ref={uploadInputRef}
              style={{ display: "none" }}
              multiple
              onChange={onChangeFile}
              autoFocus={false}
            />

            <button
              onClick={() => {
                uploadInputRef.current.click();
              }}
              disabled={!props.isAllow}
            >
              <img src={Images.files} alt="Files" />
            </button>

            <button onClick={onSendMessage} disabled={!props.isAllow}>
              <img src={Images.send} alt="Send" />
            </button>
          </div>

          {isSpace ? <p style={{ color: "red" }}>No Space allowed</p> : null}
        </div>
        <Modal
          isOpen={isAcknowledgedOpen}
          toggle={onAcknowledgeClose}
          size="xl"
        >
          <ModalHeader toggle={onAcknowledgeClose}>
            Do you want to acknowledge the message?
          </ModalHeader>

          <ModalBody>
            {/* <span className="ap-flex p-1 ap-fsm ap-fwmd ap-w100">
              Do you want to acknowledge the message?
            </span> */}

            <Button
              className="submit_right1"
              onClick={() => {
                AcknowledgeChat();
              }}
              size="sm"
              style={{ margin: "0 5px" }}
            >
              Yes
            </Button>
            <Button
              className="submit_right1"
              onClick={onAcknowledgeClose}
              size="sm"
              style={{ margin: "0 5px" }}
            >
              Cancel
            </Button>
          </ModalBody>
        </Modal>
        <Modal isOpen={isOpen} toggle={onClose} size="xl">
          <ModalHeader toggle={onClose}>Tag Members To Message</ModalHeader>

          <ModalBody>
            <span className="ap-flex p-1 ap-fsm ap-fwmd ap-w100">
              Select Member
            </span>

            <Select
              showSearch
              size="middle"
              //  value={selectedMemberId}
              mode="multiple"
              labelInValue
              placeholder="Please Select Member name"
              //onSelect={(data) => {setSelectedMemberId(data)}}
              onChange={(data) => {
                setSelectedMemberId(data);
              }}
              style={{ width: "100%" }}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {/* <Option value={""}>Please select Member name</Option> */}
              {state.admins.admins.map((member) => (
                <Option value={member?.admin_id} key={member?.admin_id}>
                  {member?.admin_firstname + " " + member?.admin_lastname}
                </Option>
              ))}
            </Select>
          </ModalBody>

          <ModalFooter>
            <Button
              className="submit_right1"
              onClick={() => {
                TagSelectedMember();
              }}
              size="sm"
              style={{ margin: "0 5px" }}
            >
              Tag
            </Button>
            <Button
              className="submit_right1"
              onClick={onClose}
              size="sm"
              style={{ margin: "0 5px" }}
            >
              Close
            </Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={modal} toggle={toggleModal} className={props.className}>
          <ModalHeader toggle={toggleModal}>
            Upload Attachments for <b>{id}</b>
          </ModalHeader>
          <ModalBody>
            <input
              className="MyInputOnUpload"
              placeholder="Type something here"
              type="text"
              onChange={handleChange}
              value={textValue}
              disabled={isDisabled}
              onKeyDown={(e) => onEnterMediaSendMessage(e)}
              onFocus={() => onInputFocusHandle()}
              onBlur={() => onInputBlurHandle()}
            />
            <div style={{ display: "block ruby" }}>
              {myfiles.map((v, i) => {
                let test = "";
                if (v && v.name !== undefined) {
                  test = v.name.split(".");
                }
                return (
                  <div className="uploadImage" key={i}>
                    {test !== "" &&
                    test[1] !== "png" &&
                    test[1] !== "jpeg" &&
                    test[1] !== "jpg" ? (
                      <img src={Images.file} alt="File" />
                    ) : (
                      <img
                        src={URL.createObjectURL(selectedFile[i])}
                        alt="Uploaded"
                      />
                    )}
                    <p>{v.name}</p>
                  </div>
                );
              })}
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={uploadFIle}
              disabled={isDisabled && !props.isAllow}
            >
{              isfileUploadLoad?<Spin/>:"Upload"
}            </Button>{" "}
            <Button
              color="secondary"
              onClick={() => {
                setModal(false);
                setMyfiles([]);
                selectedFile([]);
              }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </div>
  );
};

export default ProjectChat;
