import { API } from "../../Services"
export const orders = (query='') =>{
    return (dispatch) =>{
        dispatch({
            type:"FETCH_ORDERS",
        })
         API.orders(query).then((res)=>{
            let data = JSON.parse(JSON.stringify(res)).data
            setTimeout(()=>{
                dispatch({
                    type:"RECEIVE_ORDERS",
                    payload:data,
                })
            },1000)
        }).catch((err)=>{
            dispatch({
                type:"ERROR_ORDERS",
            })
        })
    }
}