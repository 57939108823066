import { axiosInstance } from ".."

const projects = async (id,q="") =>{

    return new Promise(async (resolve,reject)=>{
        axiosInstance.get(`internal/mmhprojects?customerID=${id}&q=${q}`).then((res) => { 
            if(res.status === 200){
                return resolve(res)
            }
            return resolve(res)
        }).catch((err) => {
            return reject(err)
        })
    })
}

export default projects;