const projectsCatlogReducer = (state = {
    loading:false,
    projects:null,totalRecords:0,msg:''
}, action) => {
    switch (action.type) {
        case "FETCH_PROJECTS_CATLOG":
            return { ...state ,loading:true,projects:null,totalRecords:0,msg:""}
        case "RECEIVE_PROJECTS_CATLOG":
            return {...state, projects:[...action.payload?.projects],loading:false,totalRecords:action.payload.totalProjects,msg:action.payload.msg}
        case "ERROR_PROJECTS_CATLOG":
            return { ...state ,projects:null,loading:true,totalRecords:0,msg:action.payload}
        case "ERROR_PROJECTS_CATLOG_BLANK":
                return {...state, projects:null,loading:false,totalRecords:0,msg:''}
        default:
            return { ...state }
    }
}

export default projectsCatlogReducer;