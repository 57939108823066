import { API } from "../../Services";

export const currency = (country) =>{
    return (dispatch) =>{
        dispatch({
            type:"FETCH_CURRENCY",
        })
         API.currency(country).then((res)=>{
            let data = JSON.parse(JSON.stringify(res)).data.currencies
            data.map((currency,currencyIndex)=>{
                currency.value = currency.code
                currency.label = currency.code
            })
            setTimeout(()=>{
                dispatch({
                    type:"RECEIVE_CURRENCY",
                    payload:{data,totalRecords:res.data.totalRecords},
                })
            },1000)
        }).catch((err)=>{
            dispatch({
                type:"ERROR_CURRENCY",
            })
        })
    }
}