import {
  Card,
  Spin,
  Tooltip,
  Modal,
  Form,
  Input,
  Button,
  Row,
  Col,
  Select,
} from "antd";
import React, { useEffect, useState } from "react";
import { Icons } from "../../../Utils";
import { useDispatch, useSelector } from "react-redux";
import { actionCreators } from "../../../Redux";
import { bindActionCreators } from "redux";
import { API } from "../../../Services";
import toast from "react-hot-toast";
import { useHistory, useParams } from "react-router";

export default function SiteDetails({ siteDetail }) {
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);
  const dispatch = useDispatch();
  const { Option } = Select;
  const state = useSelector((state) => state);
  const { country, states, project } = bindActionCreators(
    actionCreators,
    dispatch
  );
  const [form] = Form.useForm();
  const [createForm] = Form.useForm();
  const [loadingStates, setLoadingStates] = useState(false);
  const { id, customerId, prjCode } = useParams();

  useEffect(() => {
    country();
  }, []);

  const editableFields = [
    "name",
    "width",
    "type",
    "floors",
    "direction",
    "plot_area",
    "stage",
    "pincode",
    "state",
    "city",
    "address",
    "lat",
    "long",
    "purpose",
  ];

  const basicDetailsKeys = [
    "id",
    "name",
    "created_by",
    "purpose",
    "type",
    "stage",
    "status",
    "width",
    "depth",
    "floors",
    "plot_area",
    "direction",
  ];
  const directions = [
    { value: "EE", label: "East " },
    { value: "WW", label: "West" },
    { value: "NN", label: "North" },
    { value: "SS", label: "South" },
    { value: "NE", label: "North-East" },
    { value: "NW", label: "North-West" },
    { value: "SE", label: "South-East" },
    { value: "SW", label: "South-West" },
  ];
  const addressDetailsKeys = ["address", "city", "state", "pincode"];

  const handleCountryChange = (value) => {
    form.setFieldsValue({ state: undefined }); // Reset state when country changes
    states(value); // Fetch states based on selected country
  };

  const renderKeyValue = (key, value) => {
    if (key === "created_by") {
      return `${value?.first_name} ${value?.last_name || ""}`;
    }
    return typeof value === "object" && value !== null
      ? JSON.stringify(value)
      : value;
  };

  const showEditModal = () => {
    form.setFieldsValue(siteDetail); // Populate form with site details
    setIsEditModalVisible(true);
  };

  const showCreateModal = () => {
    createForm.resetFields(); // Reset form fields
    setIsCreateModalVisible(true);
  };

  const handleEditOk = () => {
    form
      .validateFields()
      .then((values) => {
        // Handle form submission here
        API.updateSiteDetail(siteDetail["id"], values)
          .then((res) => {
            toast.success("Site details are updated successfully.", {
              position: "top-right",
            });
            project(id);
          })
          .catch((err) => {
            const error =
              err?.response?.data?.email ||
              err?.response?.data?.city ||
              err?.response?.data?.firstName ||
              err?.response?.data?.phoneNumber;

            toast.error(`Oops!,${error || "Site details are not updated."}!`, {
              position: "top-right",
            });
          });
        setIsEditModalVisible(false);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleCreateOk = () => {
    createForm
      .validateFields()
      .then((values) => {
        // Handle form submission here
        API.createSite(values)
          .then((res) => {
            toast.success("Site created successfully.", {
              position: "top-right",
            });
            project(id);
          })
          .catch((err) => {
            const error =
              err?.response?.data?.email ||
              err?.response?.data?.city ||
              err?.response?.data?.firstName ||
              err?.response?.data?.phoneNumber;

            toast.error(`Oops!,${error || "Site is not created."}!`, {
              position: "top-right",
            });
          });
        setIsCreateModalVisible(false);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleCancel = () => {
    setIsEditModalVisible(false);
    setIsCreateModalVisible(false);
  };

  return (
    <>
      <Card
        size="small"
        style={{ width: "98%" }}
        title={
          <div className="ap-flex ap-flex-space-bw ap-w100 ap-fmd ap-fwmd">
            <div className="ap-flex  ap-w100 ap-fmd ap-fwmd edit-btn">
              <div className="ap-flex ap-flex-center-center">Site Details</div>
              <div
                className="ap-flex ap-flex-center-center"
                onClick={showEditModal}
              >
                <Tooltip title="Edit" color="#000" placement="bottomLeft">
                  <Icons.Edit />
                </Tooltip>
              </div>
              {/* <Button
                type="primary"
                onClick={showCreateModal}
                style={{ marginLeft: "10px" }}
              >
                Create Site
              </Button> */}
            </div>
          </div>
        }
      >
        {siteDetail && (
          <>
            <h3
              style={{
                fontSize: "16px",
                fontWeight: "600",
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              Basic Details
            </h3>
            {basicDetailsKeys.map((key) => (
              <div className="ap-flex ap-w100 ap-flex-space-bw ap-p5" key={key}>
                <div className="ap-flex ap-flex-center-center ap-fsm ap-fwmd">
                  {key}
                </div>
                <div className="ap-flex ap-flex-center-center ap-fsm">
                  {renderKeyValue(key, siteDetail[key])}
                </div>
              </div>
            ))}
            <hr />
            <h3
              style={{
                fontSize: "16px",
                fontWeight: "600",
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              Address Details
            </h3>
            {addressDetailsKeys.map((key) => (
              <div className="ap-flex ap-w100 ap-flex-space-bw ap-p5" key={key}>
                <div className="ap-flex ap-flex-center-center ap-fsm ap-fwmd">
                  {key}
                </div>
                <div className="ap-flex ap-flex-center-center ap-fsm">
                  {siteDetail[key]}
                </div>
              </div>
            ))}
          </>
        )}
      </Card>

      <Modal
        title="Edit Site Details"
        visible={isEditModalVisible}
        onOk={handleEditOk}
        onCancel={handleCancel}
        width={1000}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleEditOk}>
            Save
          </Button>,
        ]}
        bodyStyle={{ maxHeight: "70vh", overflowY: "auto" }} // Added styles for scrolling
      >
        <Form form={form} layout="vertical">
          <Row gutter={16}>
            {editableFields.map((key, index) => (
              <Col span={12} key={key}>
                <Form.Item
                  name={key}
                  label={key.charAt(0).toUpperCase() + key.slice(1)}
                >
                  {key === "direction" ? (
                    <Select>
                      {directions.map((direction) => (
                        <Option key={direction.value} value={direction.value}>
                          {direction.label}
                        </Option>
                      ))}
                    </Select>
                  ) : (
                    <Input />
                  )}
                </Form.Item>
              </Col>
            ))}
            <Col span={12}>
              <Form.Item name="country" label="Country">
                <Select
                  showSearch
                  size="middle"
                  placeholder="Select Country"
                  onChange={handleCountryChange}
                  style={{ width: "100%" }}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {state.countries.countries.map((country) => (
                    <Option key={country.code} value={country.code}>
                      {country.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="state" label="State">
                <Select
                  showSearch
                  size="middle"
                  placeholder="Select State"
                  style={{ width: "100%" }}
                  disabled={loadingStates}
                  loading={loadingStates}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {state.states.states.map((state) => (
                    <Option key={state.code} value={state.code}>
                      {state.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="city" label="City">
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>

      <Modal
        title="Create Site"
        visible={isCreateModalVisible}
        onOk={handleCreateOk}
        onCancel={handleCancel}
        width={1000}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleCreateOk}>
            Create
          </Button>,
        ]}
      >
        <Form form={createForm} layout="vertical">
          <Row gutter={16}>
            {editableFields.map((key, index) => (
              <Col span={12} key={key}>
                <Form.Item
                  name={key}
                  label={key.charAt(0).toUpperCase() + key.slice(1)}
                >
                  {key === "direction" ? (
                    <Select>
                      {directions.map((direction) => (
                        <Option key={direction.value} value={direction.value}>
                          {direction.label}
                        </Option>
                      ))}
                    </Select>
                  ) : (
                    <Input />
                  )}
                </Form.Item>
              </Col>
            ))}
            <Col span={12}>
              <Form.Item name="country" label="Country">
                <Select
                  showSearch
                  size="middle"
                  placeholder="Select Country"
                  onChange={handleCountryChange}
                  style={{ width: "100%" }}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {state.countries.countries.map((country) => (
                    <Option key={country.code} value={country.code}>
                      {country.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="state" label="State">
                <Select
                  showSearch
                  size="middle"
                  placeholder="Select State"
                  style={{ width: "100%" }}
                  disabled={loadingStates}
                  loading={loadingStates}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {state.states.states.map((state) => (
                    <Option key={state.code} value={state.code}>
                      {state.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="city" label="City">
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}
