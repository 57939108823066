import { axiosInstance } from ".."

const project = async (id) =>{

    return new Promise(async (resolve,reject)=>{
        console.log(id);
        axiosInstance.get(`internal/mmhproject/${id}`).then((res) => { 
            if(res.status === 200){
                return resolve(res)
            }
            return resolve(res)
        }).catch((err) => {
            return reject(err)
        })
    })
}

export default project;