import { API } from "../../Services";
import customers from "../../Services/Apis/customers";

export const projectAttachementCategories = (query='') =>{
    return (dispatch) =>{
        dispatch({
            type:"FETCH_ATTACHEMENT_CATEGORIES_LIST",
        })
    
        API.projectAttachementCategories(query).then((res)=>{
            console.log(res);
            let data = JSON.parse(JSON.stringify(res)).data.categories
           
            setTimeout(()=>{
                dispatch({
                    type:"RECEIVE_ATTACHEMENT_CATEGORIES_LIST",
                    payload:{data:data,totalRecords:res.data.totalRecords},
                })
            },1000)
        }).catch((err)=>{
            let data = JSON.parse(JSON.stringify(err?.response?.data?.message))

            dispatch({
                type:"ERROR_ATTACHEMENT_CATEGORIES_LIST",
                payload:data

            })
        })
    }
}

