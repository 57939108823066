import { axiosInstance } from "..";

const createSite = async (data) => {
  return new Promise(async (resolve, reject) => {
    axiosInstance
      .post(`internal/ConstructionSite`, data)
      .then((res) => {
        if (res.status === 200) {
          return resolve(res);
        }
        return resolve(res);
      })
      .catch((err) => {
        return reject(err);
      });
  });
};

export default createSite;
