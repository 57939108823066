import { API } from "../../Services"

export const requestForms = (id) =>{
    return (dispatch) =>{
        dispatch({
            type:"FETCH_RFORMS",
        })
         API.requestFormList(id).then((res)=>{
            setTimeout(()=>{
                dispatch({
                    type:"RECEIVE_RFORMS",
                    payload:res.data,
                })
            },2000)
        }).catch((err)=>{
            dispatch({
                type:"ERROR_RFORMS",
            })
        })
    }
}