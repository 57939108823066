const orderSummaryReducer = (state = {
    loading:false,
    orderSummary:null
}, action) => {
    switch (action.type) {
        case "FETCH_ORDER_SUMMARY":
            return { ...state ,loading:true,orderSummary:null}
        case "RECEIVE_ORDER_SUMMARY":
            return {...state, orderSummary:action.payload,loading:false,}
        case "ERROR_ORDER_SUMMARY":
            return { ...state ,orderSummary:null,loading:true}
        default:
            return { ...state }
    }
}

export default orderSummaryReducer;