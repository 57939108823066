import React, {useState, useEffect } from "react";
import "./GSTDetails.scss";
import { Checkbox,Input} from "antd";

const GSTDetails = (props) =>{
    const [ formData,setFormData] = useState({
        isGst:"No",
        businessName:"",
        gstNo:""
    })

    const handleFormData = (event) =>{
        setFormData((prev)=>{
            prev = JSON.parse(JSON.stringify(prev))
            prev[`${event.target.name}`] = event.target.value
            return prev
        })
    }

    useEffect(()=>{
        if(props.handleData){
            props.handleData(formData)
        }
    },[formData])


    return <div className="ap-flex ap-flex-center-center ap-w100">
                <div className="ap-flex ap-flex ap-w100">
                    <Checkbox  name="isGst" onChange={(e)=>{
                        e = JSON.parse(JSON.stringify(e))
                        if(e.target.checked){
                            e.target.value="Yes"
                        }else{
                            e.target.value = "No"
                        }
                        handleFormData({...e})
                    }} disabled={props?.disabled}>Do you want to add GST details of customer?</Checkbox>
                </div>
                {formData.isGst === "Yes" &&<><div className="ap-flex ap-flex-center-center ap-w100" style={{marginTop:"10px"}}>
                    <div className="ap-flex ap-w100" style={{marginBottom:"2px"}}>GST Number</div>
                    <Input type="text" size="large" name="gstNo" value={formData.gstNo}placeholder="Enter GST number" onChange={(data)=>{handleFormData(data)}}/>
                </div>
                <div className="ap-flex ap-flex-center-center ap-w100" style={{marginTop:"10px"}}>
                    <div className="ap-flex ap-w100" style={{marginBottom:"2px"}}>Business Name</div>
                    <Input type="text" size="large" name="businessName" value={formData.businessName} placeholder="Enter business name" onChange={(data)=>{handleFormData(data)}}/>
                </div></>}          
        </div>
}

export default GSTDetails;
