import { axiosInstance } from ".."

const partnerDetailsSingle = async (id) =>{

    return new Promise(async (resolve,reject)=>{
        axiosInstance.get(`internal/Partner?partner_id=${id}`).then((res) => { 
            console.log(res)
            if(res.status === 200){
                return resolve(res)
            }
            return resolve(res)
        }).catch((err) => {
            return reject(err)
        })
    })
}

export default partnerDetailsSingle;