import React, { useState } from "react";
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
} from "@chakra-ui/form-control";
import { Select } from "antd";
import toast from "react-hot-toast";
import { Button } from "@chakra-ui/react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { API } from "../../Services";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../Redux";

export default function CollectFeedBack(props) {
  const [selectedService, setSelectedService] = useState("Floor Plan");
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const {trelloCommentList} = bindActionCreators(
    actionCreators,
    dispatch
  );
  const [isloading, setLoader] = useState(false);

  const { Option } = Select;
  const { id } = useParams();

  const collectFeedBacks = () => {
    setLoader(true);
    let data = {
      service: selectedService,
    };
    var formData = new FormData();
    for (var i in data) {
      formData.append(i, data[i]);
    }
    API.getFeedBack(id, formData)
      .then((res) => {
        setLoader(false);
        toast.success("Feed Back Notifications send successfully.", {
          position: "top-right",
        });
        trelloCommentList(id, 20, 1);
        props.onClose();
      })
      .catch((err) => {
        setLoader(false);
        toast.error(err.message, {
          position: "top-right",
        });
        props.onClose();
      });
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "20px 10px",
          flexDirection:'column'
        }}
      >
        <div style={{ width:"65%" }}>
          <FormControl>
            <FormLabel htmlFor="text-input">Select Service</FormLabel>
            <Select
              placeholder="Select Service"
              size="lg"
              labelInValue
              style={{ width: "100%" }}
              defaultValue={"Floor Plan"}
              onChange={(data) => {
                console.log(data.value);
                setSelectedService(data.value);
              }}
            >
              <Option value={"Floor Plan"}>Floor Plan</Option>
            </Select>
          </FormControl>
          <br />
        </div>

        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            // disabled={!selectedListId?.value}
            mt={4}
            className="submit_right1"
            isLoading={isloading}
            type="submit"
            onClick={() => {
              collectFeedBacks();
            }}
          >
            Collect FeedBacks
          </Button>
        </div>
        {/* )} */}
      </div>
    </div>
  );
}
