import { API } from "../../Services";

export const projectsCatlog = (querry,blank=false) =>{
    return (dispatch) =>{
        dispatch({
            type:"FETCH_PROJECTS_CATLOG",
        })
        if(!blank){
            API.projectsCatlog(querry).then((res)=>{
                let data = JSON.parse(JSON.stringify(res)).data
                data.projects = data.projects ? data.projects : []
                data.msg = data.projects ? `${data.totalRecords} records are fetched are successfully !`:"0 record is fetched"
                setTimeout(()=>{
                    dispatch({
                        type:"RECEIVE_PROJECTS_CATLOG",
                        payload:data,
                    })
                },1000)
            }).catch((err)=>{
                let data = JSON.parse(JSON.stringify(err.message))
                dispatch({
                    type:"ERROR_PROJECTS_CATLOG",
                    payload:data

                })
            })
        }else{
            dispatch({
                type:"ERROR_PROJECTS_CATLOG_BLANK",
            })
        }
         
    }
}