const products2Reducer = (state = {
    loading:false,
    products2:[]
}, action) => {
    switch (action.type) {
        case "FETCH_PRODUCTS2":
            return { ...state ,loading:true,products2:[]}
        case "RECEIVE_PRODUCTS2":
            return {...state, products2:[...action.payload],loading:false}
        case "ERROR_PRODUCTS2":
            return { ...state ,products2:[],loading:true,}
        default:
            return { ...state }
    }
}

export default products2Reducer;