import React, {useState,useEffect} from "react";
import "../../../Styles/Master.scss";
import "./ClientsList.scss";
import { Card, Input,Spin,Button,Table,Space, Select} from 'antd';
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../../Redux";
import { Icons } from "../../../Utils";
import { useHistory } from "react-router-dom";
import { Loader} from "../../../Components";
import { API } from "../../../Services";


const ClientsList = (props) =>{
    var selectedPartner
    const state = useSelector((state)=>state)
    const dispatch = useDispatch();
    const [data,setData] = useState({
        value:"",
        key:"",
    });
    const history = useHistory();
    const [tableData,setTableData] = useState([]);
    const [current,setCurrent] =useState({
        page:1,
        size:10,
    })
    const [mainLoad,setMainLoad] = useState(true);
    const { customersList,partnersList } = bindActionCreators(actionCreators,dispatch)
    const { Option } = Select;
    const params = new URLSearchParams(window.location.search);


    useEffect(()=>{
        setTimeout(()=>{
            setMainLoad((prev)=>!prev)
        },3000)
    },[])

    const columns = [
       

        {
            title:<div className="ap-fsm ap-fwmd">Client Id</div>,
            dataIndex:"clientId",
            key:"clientId",
            render:text=><div className="ap-fsm">{text}</div>,
            hidden:true

        },
        {
            title:<div className="ap-fsm ap-fwmd">Client Name</div>,
            dataIndex:"clientName",
            key:"clientName",
            render:text=><div className="ap-fsm">{text}</div>,
        },
        {
            title:<div className="ap-fsm ap-fwmd">Email</div>,
            dataIndex:"email",
            key:"email",
            render:text=><div className="ap-fsm">{text}</div>,
        },
        {
            title:<div className="ap-fsm ap-fwmd">Contact No.</div>,
            dataIndex:"contactNo",
            key:"contactNo",
            render:text=><div className="ap-fsm">{text}</div>,
           
        },
        {
            title:<div className="ap-fsm ap-fwmd">City</div>,
            dataIndex:"city",
            key:"city",
            render:text=><div className="ap-fsm">{text}</div>

        },
        {
            title:<div className="ap-fsm ap-fwmd">State</div>,
            dataIndex:"state",
            key:"state",
            render:text=><div className="ap-fsm">{text}</div>

        },
        {
            title:<div className="ap-fsm ap-fwmd">Country</div>,
            dataIndex:"country",
            key:"country",
            render:text=><div className="ap-fsm">{text}</div>

        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
              <Space size="small" className="table-btn">
                <Button type="primary" shape="round" className="ap-btn-c"  icon={<Icons.Details />} size="middle" onClick={()=>history.push(`/home/ap/client/details/${record.clientId}`)}>
                    <span className="ap-fesm ap-fwmd">Show Details</span> 
                </Button>
              </Space>
            ),
            width:"150px",
            fixed:"right",
          },
    ].filter(item => !item.hidden);

    const setFormData =(event)=>{
        setData((prev)=>{
            prev = JSON.parse(JSON.stringify(prev))
            if(event.target.name === "partnerId"){
                prev[`${event.target.name}`] = event.target.value.value
                prev.key = event.target.name
                prev.value = event.target.value.value

            } else if(event.target.name !== "partnerId"){
            prev.key = event.target.name
            prev.value = event.target.value
        } else {
            prev[`${event.target.name}`] = event.target.value
        }
        return prev
        })
    }
 useEffect(() => {
   console.log(data)
 }, [data])
 

    useEffect(()=>{
        if(state.customers.customers.length > 0){
            setTableData((prev)=>{
                prev = []
                state.customers.customers.map((customer,customerIndex)=>{
                    prev.push({
                        key:customerIndex,
                        clientId:customer.id,
                        clientName:`${customer.firstName} ${customer.lastName}`,
                        email:customer.email,
                        contactNo:`${customer.phoneNumber !== "" ?"+"+customer.isdCode:''}${customer.phoneNumber}`,
                        city:customer.city,
                        state:customer.state,
                        country:customer.country,
                    })
                })
                return prev
            })
        }
    },[state])

    const callRecords = (event) =>{
        setCurrent((prev)=>{
            prev = JSON.parse(JSON.stringify(prev))
            prev.page = prev.size === event.payload.size ? event.payload.page:1
            prev.size = event.payload.size
            return prev
        })
    }

    useEffect(()=>{
      
        partnersList();
        setTableData([]);

    },[current])
    useEffect(()=>{

        let PartnerList= state.partnersList.customers
        .filter((data) => params.get(`partnerId`) == data.id)
        .map(({ id,firstName,lastName }) => ({
            id,
            firstName,lastName
        }));
        console.log(PartnerList)

        if( params.get(`partnerId`) )
        {
            
            setData((prev) => {
                prev = JSON.parse(JSON.stringify(prev));
               
                prev['partnerId'] = PartnerList[0]?.id
                  prev.key = 'partnerId'
                  prev.value =  PartnerList[0]?.id
  
                return prev;
              });
        }
    },[state.partnersList.customers])

    useEffect(()=>{
      
            let offset = current.page === 1 ? 0 : Number(current.page) * 10
            if( params.get(`partnerId`) )
            {            customersList(`limit=${current.size}&offset=${offset}&q=${data.partnerId ? '' : data.value }&partnerId=${params.get(`partnerId`)}`)
        }else{
            customersList(`limit=${current.size}&offset=${offset}&q=${data.partnerId ? '' : data.value }&partnerId=${data.partnerId ? data.value : ''}`)

        }
    },[current])

    return (
      <>
        {mainLoad ? (
          <Loader />
        ) : (
          <div className="ap-flex ap-flex-center-center ap-w100">
            <div className="ap-flex ap-w100">
              <Card style={{ width: "100%" }}>
                <div className="ap-flex ap-w100 ">
                  <div className="ap-flex ap-flex-center-center ap-w33">
                    <div className="ap-flex ap-w100 ap-fsm ap-fwmd">
                      Client Name
                    </div>
                    <Input
                      size="large"
                      style={{ width: "100%" }}
                      placeholder="Enter Client Name"
                      value={data.key === "clientName" ? data.value : ""}
                      name="clientName"
                      disabled={
                        data.key !== "" &&
                        data.key !== "clientName" &&
                        data.value !== ""
                      }
                      onChange={(e) => setFormData(e)}
                    />
                  </div>
                  <div
                    className="ap-flex ap-flex-center-center ap-w33"
                    style={{ margin: "0 10px" }}
                  >
                    <div className="ap-flex ap-w100 ap-fsm ap-fwmd">
                      Client Email
                    </div>
                    <Input
                      size="large"
                      style={{ width: "100%" }}
                      placeholder="Enter Client Email"
                      value={data.key === "clientEmail" ? data.value : ""}
                      name="clientEmail"
                      disabled={
                        data.key !== "" &&
                        data.key !== "clientEmail" &&
                        data.value !== ""
                      }
                      onChange={(e) => setFormData(e)}
                    />
                  </div>
                  <div
                    className="ap-flex ap-flex-center-center ap-w33"
                    style={{ margin: "0 10px 0 0" }}
                  >
                    <div className="ap-flex ap-w100 ap-fsm ap-fwmd">
                      Client Contact No.
                    </div>
                    <Input
                      size="large"
                      type="number"
                      style={{ width: "100%" }}
                      placeholder="Enter Client Contact Number"
                      value={data.key === "clientNumber" ? data.value : ""}
                      name="clientNumber"
                      disabled={
                        data.key !== "" &&
                        data.key !== "clientNumber" &&
                        data.value !== ""
                      }
                      onChange={(e) => setFormData(e)}
                    />
                  </div>

                  <div className="ap-flex ap-flex-center-center ap-w33">
                    <div className="ap-flex ap-fsm ap-fwmd ap-w100">
                      Select Partner
                    </div>
                    <Select
                      size="large"
                      showSearch
                      onSearch={(data) => {
                        partnersList(`q=${data}`);
                      }}
                      labelInValue
                      placeholder="Select Partner"
                      style={{ width: "100%" }}
                      value={
                        data.key === "partnerId" ? data.value : "Select Partner"
                      }
                      loading={state.customers.loading}
                      onChange={(e) => {
                        setFormData({
                          target: { name: "partnerId", value: e },
                        });
                      }}
                      disabled={
                        data.key !== "" &&
                        data.key !== "clientEmail" &&
                        data.value !== ""
                      }
                      notFoundContent={
                        state.customers.loading && (
                          <div className="ap-w100 ap-flex ap-flex-center">
                            <Spin size="small" />
                          </div>
                        )
                      }
                      filterOption={(input, option) =>
                        option.props.children
                          .toUpperCase()
                          .indexOf(input.toUpperCase()) >= 0
                      }
                    >
                      {state.partnersList.customers.map(
                        (customer, customerIndex) => (
                          <Option value={customer.value} key={customerIndex}>
                            {customer.label}
                          </Option>
                        )
                      )}
                    </Select>
                  </div>
                </div>

                <div className="ap-flex ap-w100" style={{ marginTop: "10px" }}>
                  <div className="ap-flex ap-flex-center-center search-btn">
                    <Button
                      type="primary"
                      shape="round"
                      className="ap-btn-c"
                      loading={state.customers.loading ? <Spin /> : false}
                      icon={<Icons.Search />}
                      size="middle"
                      onClick={() =>
                        callRecords({ payload: { page: 1, size: 10 } })
                      }
                    >
                      <span className="ap-fesm ap-fwmd">Search</span>
                    </Button>
                  </div>
                  <div
                    className="ap-flex ap-flex-center-center search-btn"
                    style={{ marginLeft: "10px" }}
                  >
                    <Button
                      type="primary"
                      shape="round"
                      className="ap-btn-c"
                      loading={state.customers.loading ? <Spin /> : false}
                      icon={<Icons.Sweep />}
                      size="middle"
                      onClick={() => {
                        setData({ value: "", key: "" });
                        setCurrent({
                          page: 1,
                          size: 10,
                        });
                        customersList(`limit=10&offset=0&q=&partnerID=`);
                        if (params.get(`partnerId`)) {
                          params.delete("partnerId");
                          history.push({ search: params.toString() });
                        }
                      }}
                    >
                      <span className="ap-fesm ap-fwmd">Clear</span>
                    </Button>
                  </div>
                </div>
              </Card>
            </div>

            {!state.customers.loading ? (
              <div className="ap-flex ap-w100" style={{ marginTop: "20px" }}>
                <Card style={{ width: "100%" }}>
                  <div
                    className="ap-flex ap-w100 ap-fsm ap-fwmd ap-flex-end rst-record"
                    onClick={() => {
                      setData({ key: "", value: "" });
                      customersList(`limit=10&offset=1&q=`);
                    }}
                  >
                    Reset Records
                  </div>

                  <Table
                    columns={columns}
                    dataSource={tableData}
                    scroll={{ x: 900 }}
                    pagination={{
                      current: current.page,
                      pageSize: current.size,
                      position: ["bottomLeft"],
                      total: state.customers.totalRecords,
                      onChange: (data, size) =>
                        callRecords({
                          type: "page",
                          payload: { page: data, size: size },
                        }),
                      showTotal: (total, range) =>
                        `${range[0]}-${range[1]} of ${total} items`,
                    }}
                  />
                </Card>
              </div>
            ) : (
              <div
                className="ap-flex ap-w100 ap-flex-center-center"
                style={{ height: "300px" }}
              >
                <Spin tip="Fetching Records....." />
              </div>
            )}
          </div>
        )}
      </>
    );


}

export default ClientsList;