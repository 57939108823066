import React, { useEffect, useState } from "react"
import "../../Styles/Master.scss"
import "./RaisePayment.scss"
import { Card,Input,Select,Button,Alert,Spin } from "antd";
import {useParams,useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../Redux";
import { API } from "../../Services";

const paymentModes = [
    { value: 'Razor Pay', label: 'Razor Pay ' },
    { value: 'NEFT', label: 'NEFT' },
    { value: 'IMPS', label: 'IMPS' },
    { value: 'Paytm Link', label: 'Paytm Link' },
    { value: 'Bar Code (ICICI', label: 'Bar Code (ICICI' },
    { value: 'Paytm Number', label: 'Paytm Number' },
    { value: 'Bank Deposit (Cash)', label: 'Bank Deposit (Cash)' },
    { value: 'Google Pay', label: 'Google Pay' },
    { value: 'Phone Pay', label: 'Phone Pay' },
    { value: 'PayPal', label: 'PayPal' },
    { value: 'Worldline', label: 'Worldline' },
    { value: 'Cash', label: 'Cash' },
];

const RaisePayment = (props) =>{

    const history = useHistory()
    const { TextArea } =Input;
    const [data,setdata] = useState({})
    const state = useSelector(state=>state)
    const dispatch = useDispatch()
    const { customer,currency,admins } = bindActionCreators(actionCreators,dispatch)
    const { type, id,country,customerId} = useParams();
    const { Option } = Select;
    const [alert,setAlert] = useState({
        status:false,
    })
    const [mainLoad,setMainLoad] = useState(false);
    const [file,setFile] = useState(null)

    useEffect(()=>{
        admins();
        customer(customerId)
        currency(country)
    },[])

    const setForm = (event) =>{
        setdata((prev)=>{
            prev = JSON.parse(JSON.stringify(prev))
            prev[`${event.target.name}`] = `${event.target.value}`
            return prev   
        })
    }

    useEffect(()=>{
        console.log(data)
    },[data])

    useEffect(()=>{
        if(file !== null){
            let formData = new FormData
            formData.append("media",file)
            formData.append("entityType",type==="wallet"?"customer":type)
            formData.append("entityId",id)
            formData.append("returnType",'key')
            setMainLoad(prev=>!prev)
            API.media(formData).then((res)=>{
                setMainLoad(prev=>!prev)
                setAlert((prev)=>{
                    prev = JSON.parse(JSON.stringify(prev))
                    prev.status=true
                    prev.type = "success"
                    prev.message="File Uploaded !"
                    prev.description = "Your file uploaded successfully"
                    return prev
                })
                setdata((prev)=>{
                    prev.file = res.data.url
                    return prev
                })
                setTimeout(()=>{
                    setAlert((prev)=>{
                        prev = JSON.parse(JSON.stringify(prev))
                        prev.status=false
                        prev.type = ""
                        prev.message=""
                        prev.description = ""
                        return prev
                    })
                },3000)
           
            }).catch((err)=>{
                setMainLoad(prev=>!prev)
                setAlert((prev)=>{
                    prev = JSON.parse(JSON.stringify(prev))
                    prev.status=true
                    prev.type = "error"
                    prev.message="Not Uploaded !"
                    prev.description = "Oops ! file not uploaded because of internal error"
                    return prev
                })
                setTimeout(()=>{
                    setAlert((prev)=>{
                        prev = JSON.parse(JSON.stringify(prev))
                        prev.status=false
                        prev.type = ""
                        prev.message=""
                        prev.description = ""
                        return prev
                    })
                },3000)
            })
        }
        console.log(file)
    },[file])

    const sendData = () =>{
        let d = {
            "transactionId": data?.transactionId,
            "amount": data?.amount,
            "bankname": data?.bankName,
            "images": data?.file,
            "comment": data?.comment,
            "paymentMode": data?.paymentMode,
            "transactionDate": data?.transactionDate,
            'assigned_to': data?.assignee,
            "number": data.clientNumber ? data.clientNumber : state.customer.customer.phoneNumber,
            "name": `${state.customer.customer.firstName} ${state.customer.customer.lastName}`,
            "currency":data?.currency,
            addPaymentTo:type,
            orderCode: id
        }

        setMainLoad(prev=>!prev)
        API.paymentRequest(d).then((res)=>{
            setMainLoad(prev=>!prev)
            setAlert((prev)=>{
                prev = JSON.parse(JSON.stringify(prev))
                prev.status=true
                prev.type = "success"
                prev.message="Payment Generated !"
                prev.description = "Your payment generated successfully"
                return prev
            })
            setTimeout(()=>{
                setAlert((prev)=>{
                    prev = JSON.parse(JSON.stringify(prev))
                    prev.status=false
                    prev.type = ""
                    prev.message=""
                    prev.description = ""
                    return prev
                })
                history.push('/home/ap/payment/requests/list',)
            },3000)
            console.log(res)
        }).catch((err)=>{
            setMainLoad(prev=>!prev)
            setAlert((prev)=>{
                prev = JSON.parse(JSON.stringify(prev))
                prev.status=true
                prev.type = "error"
                prev.message="Not Generated !"
                prev.description = "Your payment not generated becuase of some internal issues."
                return prev
            })
            setTimeout(()=>{
                setAlert((prev)=>{
                    prev = JSON.parse(JSON.stringify(prev))
                    prev.status=false
                    prev.type = ""
                    prev.message=""
                    prev.description = ""
                    return prev
                })
            },3000)
            console.log(err)
        })
    }

    return <div className="ap-flex ap-w100 ap-flex-center-center">
        {alert.status && <Alert
                message={alert?.message}
                description={alert?.description}
                type={alert?.type}
                showIcon
                closable
        />}
        <Card 
            style={{width:"100%"}}
            title={<div className="ap-fmd ap-fwmd">Add Payment</div>}
            size="small"
            actions={[
                <div className="ap-flex ap-flex-end ap-w100" style={{borderRadius:"10px",paddingRight:"10px"}}>
                    <Button 
                        shape="round"
                        size="large"
                        className="btn"
                        disabled={mainLoad}
                        loading={mainLoad}
                        onClick={()=>sendData()}
                    >
                        <span className="ap-fsm ap-fwmd ap-lsp">Add Payment</span>
                    </Button>
                </div>
                
            ]}
        >
            <div className="ap-flex ap-w100 ">
                {type === "wallet" ? <div className="ap-flex ap-w50 ap-flex-center-center ap-p5">
                    <div className="ap-flex ap-w100 ap-fsm ap-fwmd" style={{marginBottom:"5px"}}>Wallet Code</div>
                    <Input 
                        size="large"
                        style={{width:"100%"}}
                        placeholder="Enter Client Code"
                        name="clientId"
                        value={id}
                        disabled
                        onChange={(e)=>{setForm(e)}}
                    />
                </div>:
                    <div className="ap-flex ap-w50 ap-flex-center-center ap-p5">
                    <div className="ap-flex ap-w100 ap-fsm ap-fwmd" style={{marginBottom:"5px"}}>Order Code</div>
                    <Input 
                        size="large"
                        style={{width:"100%"}}
                        placeholder="Enter Order Code"
                        name="orderId"
                        value={id}
                        disabled
                        onChange={(e)=>{setForm(e)}}
                    />
                </div>
                }
                    <div className="ap-flex ap-w50 ap-flex-center-center ap-p5" style={{alignItems:"flex-end"}}>
                        <div className="ap-flex ap-w100 ap-fsm ap-fwmd" style={{marginBottom:"5px"}}>Customer Name</div>
                        <Input 
                            size="large"
                            style={{width:"100%"}}
                            placeholder="Enter Customer Name"
                            name="clientName"
                            value={`${state.customer.customer?.firstName} ${state.customer.customer?.lastName}`}
                            disabled
                            onChange={(e)=>{setForm(e)}}
                        />
                    </div>
            </div>
            <div className="ap-flex ap-w100 ">
                <div className="ap-flex ap-w50 ap-flex-center-center ap-p5" >
                    <div className="ap-flex ap-w100 ap-fsm ap-fwmd" style={{marginBottom:"5px"}}>Customer Number</div>
                    <Input 
                        size="large"
                        type="number"
                        style={{width:"100%"}}
                        placeholder="Enter Customer Number"
                        value = {state.customer?.customer?.phoneNumber||data?.phoneNumber}
                        disabled
                        defaultValue={state.customer?.customer?.phoneNumber}
                        name="clientNumber"
                        onChange={(e)=>setForm(e)}
                    />
                </div>
                <div className="ap-flex ap-w50 ap-flex-center-center ap-p5" style={{alignItems:"flex-end"}}>
                <div className="ap-flex ap-w100 ap-fsm ap-fwmd" style={{marginBottom:"5px"}}>Amount</div>
                    <Input 
                        size="large"
                        type="number"
                        style={{width:"100%"}}
                        placeholder="Enter Amount"
                        value = {data?.amount }
                        name="amount"
                        onChange={(e)=>setForm(e)}
                    />
                </div>
            </div>

            <div className="ap-flex ap-w100 ">
                <div className="ap-flex ap-w50 ap-flex-center-center ap-p5" >
                    <div className="ap-flex ap-w100 ap-fsm ap-fwmd" style={{marginBottom:"5px"}}>Bank Name</div>
                    <Input 
                        size="large"
                        style={{width:"100%"}}
                        placeholder="Enter Bank Name"
                        value = {data?.bank }
                        name="bankName"
                        onChange={(e)=>setForm(e)}
                    />
                </div>
                <div className="ap-flex ap-w50 ap-flex-center-center ap-p5" style={{alignItems:"flex-end"}}>
                <div className="ap-flex ap-w100 ap-fsm ap-fwmd" style={{marginBottom:"5px"}}>Payment Mode</div>
                    <Select
                        showSearch
                        size="large"
                        labelInValue
                        onChange={(data)=>setForm({target:{...data,name:"paymentMode"}})}
                        style={{width:"100%"}}
                        placeholder="Select Payment Mode"
                        filterOption={(input, option) =>  
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 
                            
                          }
                    >   
                        {paymentModes.map((pm,pmIndex)=>(
                            <Option value={pm.value} key={pmIndex}>
                                {pm.label}
                            </Option>
                        ))}
                    </Select>
                </div>
            </div>

            <div className="ap-flex ap-w100 ">
                <div className="ap-flex ap-w50 ap-flex-center-center ap-p5" >
                    <div className="ap-flex ap-w100 ap-fsm ap-fwmd" style={{marginBottom:"5px"}}>Select Currency</div>
                    <Select
                        showSearch
                        size="large"
                        labelInValue
                        onChange={(data)=>setForm({target:{...data, name:"currency"}})}
                        style={{width:"100%"}}
                        placeholder="Select Currency"
                        filterOption={(input, option) =>  
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 
                            
                          }
                    >   
                        {state.currencies.currencies.map((currency,currencyIndex)=>(
                            <Option value={currency.value} key={currencyIndex}>
                                {currency.label}
                            </Option>
                        ))}
                    </Select>
                </div>
                <div className="ap-flex ap-w50 ap-flex-center-center ap-p5" style={{alignItems:"flex-end"}}>
                <div className="ap-flex ap-w100 ap-fsm ap-fwmd" style={{marginBottom:"5px"}}>Transaction Date</div>
                    <Input 
                        type="date"
                        size="large"
                        name="transactionDate"
                        placeholder="Enter Transaction Date"
                        onChange={event =>setForm(event)} 
                    />
                </div>
            </div>

            <div className="ap-flex ap-w100 ">
                <div className="ap-flex ap-w50 ap-flex-center-center ap-p5" >
                    <div className="ap-flex ap-w100 ap-fsm ap-fwmd" style={{marginBottom:"5px"}}>Transaction Id</div>
                    <Input 
                        size="large"
                        name="transactionId"
                        placeholder="Enter Transaction Id"
                        onChange={event =>setForm(event)} 
                    />
                </div>
                <div className="ap-flex ap-w50 ap-flex-center-center ap-p5" style={{alignItems:"flex-end"}}>
                <div className="ap-flex ap-w100 ap-fsm ap-fwmd" style={{marginBottom:"5px"}}>Assigned To</div>
                <Select
                        showSearch
                        size="large"
                        labelInValue
                        onChange={(data)=>setForm({target:{...data, name:"assignee"}})}
                        style={{width:"100%"}}
                        placeholder="Select Assignee"
                        filterOption={(input, option) =>  
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 
                            
                          }
                >   
                        {state.admins.admins.map((admin,adminIndex)=>(
                            <Option value={admin.value} key={adminIndex}>
                                {admin.label}
                            </Option>
                        ))}
                    </Select>
                </div>
            </div>

            <div className="ap-flex ap-flex-center-center ap-w100 ap-p5">
                <div className="ap-flex ap-w100 ap-fsm ap-fwmd" style={{marginBottom:"5px"}}>Comment</div>
                <TextArea 
                    rows={4}
                    name="comment"
                    value ={data?.comment}
                    onChange={(e)=>setForm(e)}
                />
            </div>
            <div className="ap-flex ap-flex-center-center ap-w100 ap-p5 ">
                <div className="ap-flex ap-w100 ap-fsm ap-fwmd" style={{marginBottom:"5px"}}>Attachment</div>
                {!mainLoad ?<Input 
                    type="file"
                    onChange={(e)=>setFile(e.target.files[0])}
                />: <Spin tip="File Uploading......"/>}
                {!mainLoad && file !==null && <div className="ap-flex ap-w100"  style={{color:"#368434",padding:"5px 0px"}}>{file.name}</div>}
            </div>
        </Card>
    </div>
}

export default RaisePayment;