import styled from "styled-components";


export const  DetailHeding = styled.h3`
font-size: 16px;
font-weight: 620;
min-height: 35px;
`;

export const  DetailCard = styled.div`
border: 3px solid  var(--primary-light-background);
    border-radius: 5px;
    width: 100%;
    padding: 8px 10px;
    margin: 0 5px;

`;

export const  DetailContent = styled.div`

`;
export const  InfoDiv = styled.div`
display: flex;
    padding: 8px;`;

export const  Info = styled.div`
    display: flex;
    background:     linear-gradient(to right,var(--primary-light-background) ,var(--primary-dark-background));
    padding:2px;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    //color:  var( --primary-green);
    color: white;

width: 60%;
`;
export const  InfoIconDiv = styled.div`

width: 40%;
display: flex;
// background: linear-gradient(to right, #bdffd4 , #048204);
    padding: 10px;
    align-items: center;
    justify-content: center;
    svg{
        font-size: 35px;
    // color: white;
    color:  var( --primary-green);

}
    }
`;

export const  InfoBottonDiv = styled.div`
border-top: 2px solid #c4fac4;
    padding: 2px 5px;
    font-weight: 600;
    span{
        color: gray;
        cursor: pointer;
        :hover{
            color: var( --primary-green);
        }
    }
   
`;

export const  PHeading = styled.b`
display: inline-block,
padding-right: 8px,
font-weight: 600,
`;
export const  PValue = styled.span`
`;
