import React, { useEffect, useState } from "react";
import "../../Styles/Master.scss"
import "./Login.scss";
import LoginImg from "../../Assets/Images/login_img.png";
import BrandLogo from "../../Assets/Images/new_brand_logo.png"
import { Alert} from 'antd';
import { API } from "../../Services";
import { useHistory } from "react-router";
import { Loader} from "../../Components"

const Login = (props) =>{

    const [alert,setAlert] = useState({
        status:false
    });


    const [data,setData] = useState({
        email:"",
        password:"",
    })
    const history = useHistory();
    const [mainLoader, setMainLoader] = useState(false);

    useEffect(() => {
    if(localStorage.getItem('token'))
    {
        history.push("/dashboard")

    }
    }, [])
    

    const handleLogin = (event) =>{
       
        event.preventDefault()
        let d = {
            username:data.email,
            password:data.password,
            'appcode': "ADMN",
            player_id:localStorage.getItem('player_id')
        }  

        if(data.email !== "" && data.password !== ""){
            setMainLoader(prev=>!prev)
            API.login(d).then((res)=>{
                localStorage.setItem("token", res.data.token)
                localStorage.setItem("adminDetails", JSON.stringify(res.data.adminDetails))
                localStorage.setItem("trellotoken", res.data.accessToken)
                setTimeout(()=>{
                    if(localStorage.getItem('backurl')){
                        window.location.reload()
                    }else{
                    history.push("/dashboard")

                    }
                    
                },1000)
               
            }).catch((err)=>{
                setMainLoader(prev=>!prev)
                setAlert((prev)=>{
                    prev = JSON.parse(JSON.stringify(prev))
                    prev.status=true
                    prev.type = "error"
                    prev.message = "Invalid, credentials !"
                    prev.description = "Email or password , is invalid."
                    return prev
                })
                setTimeout(()=>{
                    setAlert((prev)=>{
                        return {
                            status:false
                        }
                    })
                },3000)

            })
        }else{
            setAlert((prev)=>{
                prev = JSON.parse(JSON.stringify(prev))
                prev.status=true
                prev.type = "error"
                prev.message = "Please fill these both credentials"
                return prev
            })
            setTimeout(()=>{
                setAlert((prev)=>{
                    return {
                        status:false
                    }
                })
            },3000)
        }
        
    }

    const setForm = (event) =>{
        setData((prev)=>{
            prev=JSON.parse(JSON.stringify(prev))
            prev[`${event.target.name}`] = event.target.value
            return prev
        })
    }

    return <>{!mainLoader ? <div className="ap-flex ap-w100 login">
            {alert.status && <Alert
                    message={alert?.message}
                    description={alert?.description}
                    type={alert?.type}
                    showIcon
                    closable
                />}
            <div className="ap-flex ap-w50">
                <img src={ LoginImg } width="100%"/>
            </div>
            <div className="ap-flex ap-flex-center-center ap-w50">
                <div className="ap-flex ap-flex-center-center ap-w75">
                    <div className="ap-flex ap-w100">
                        <div className="ap-flex ap-flex-center-center">
                            <img src={BrandLogo} width="200px" />
                        </div>
                    </div>
                    <h1 style={{textAlign:"left",margin:"10px 0",width:"100%",fontSize:"26px",fontWeight:"500"}}>Welcome Back :)</h1>
                    <form className="ap-flex ap-w100 ap-flex-center-center" onSubmit={(event)=>handleLogin(event)}>
                        <div className="ap-flex ap-w100">
                            <input type="email" name="email" className="form__field ap-fsm ap-fwmd" placeholder="Enter your email" onChange={(event)=>setForm(event)}/>
                        </div>
                        <div className="ap-flex ap-w100">
                            <input type="password" name="password" className="form__field ap-fsm ap-fwmd" placeholder="Enter your Password"  onChange={(event)=>setForm(event)}/>   
                        </div>
                        <div className="ap-flex ap-flex-end ap-w100">
                            <button type="submit" className="btn--primary">Login</button>   
                        </div>
				    </form>
                </div>
            </div>
    </div>:<Loader />}</>
}

export default Login