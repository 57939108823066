import { API } from "../../Services";

export const states = (country) =>{
    return (dispatch) =>{
        dispatch({
            type:"FETCH_STATES",
        })
         API.states(country).then((res)=>{
            let data = JSON.parse(JSON.stringify(res)).data.states
            data.map((state,stateIndex)=>{
                state.value = state.code
                state.label = state.name
            })
            setTimeout(()=>{
                dispatch({
                    type:"RECEIVE_STATES",
                    payload:data,
                })
            },1000)
        }).catch((err)=>{
            dispatch({
                type:"ERROR_STATES",
            })
        })
    }
}