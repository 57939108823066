import React, { useState, useEffect, useMemo } from "react";
import { Card, Select, Input, Spin, Button, Alert } from "antd";
import "antd/dist/antd.css";
import "./CreateCustomOrders.scss";
import { CustomerDetails } from "../../../Components";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../../Redux";
import { Icons } from "../../../Utils";
import { useHistory, useParams } from "react-router-dom";
import { API } from "../../../Services";

const CreateCustomOrders = (props) => {
  const history = useHistory();
  const { TextArea } = Input;
  const { Option } = Select;
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const [data, setData] = useState({});
  const [alert, setAlert] = useState({
    status: false,
  });
  const [indexId, setindexId] = useState(0);

  const [load, setLoad] = useState(false);
  const [addButton, setAddButton] = useState([[{id:indexId}]]);

  const { customersList, customer, projects, products2, units, admins, project } =
    bindActionCreators(actionCreators, dispatch);
  const { customerId, projectId, category, type } = useParams();

  useEffect(() => {
    if (customerId && projectId) {
      customersList(`q=${localStorage.getItem("customerNo")}`);
      customer(customerId);
      projects(customerId, projectId);
      products2(`category=${category}`);
      units(`type=${type}`);
      admins(`&groupId=5`);
    }

  }, []);

  useEffect(() => {
    let fData = { products: [] }
    if (customerId && projectId) {
      if (state.customers.customers.length > 0) {
        fData.customer = {
          value: Number(customerId),
          label: `${state.customers.customers[0]?.firstName} ${state.customers.customers[0]?.lastName}`,
        }
      }
    }
    if (state.projects.projects.length > 0) {
      state.projects.projects.map((prj) => {
        if (prj.projectCode === projectId) {
          fData.project = { value: Number(prj.ID), label: `${prj.name}` };
        }
      })
    }
    setData(fData)
  }, [state]);

  useEffect(() => {
    console.log(data);
  }, [data]);

  const setFormData = (event) => {
    setData((prev) => {
      prev = JSON.parse(JSON.stringify(prev));
      if ("key" in event) {
        if (prev.products.length > event.key) {
          let innerObj = prev.products[event.key]
          innerObj[`${event.target.name}`] = event.target.value
          prev.products[event.key] = { ...innerObj }
        } else {
          let iObj = {}
          iObj[`${event.target.name}`] = event.target.value
          prev.products.push(iObj)
        }
      } else {
        prev[`${event.target.name}`] = event.target.value;
      }
      return prev;
    });
  };

  const createOrder = () => {
    setLoad(true);
    let fd = { ...data }
    fd.products.forEach((p) => {
      p['noOfUnit'] = 1
      if (!p.unit) {
        p.unit = 0
      }
    })
    let d = {
      customerID: data?.customer?.value,
      projectID: data?.project?.value,
      approverID: data?.approver?.value,
      messageForApprover: data?.msgApprover,
      products: fd?.products
    };
    API.createCustomOrder(d)
      .then((res) => {
        setLoad(false);
        setAlert((prev) => {
          prev = JSON.parse(JSON.stringify(prev));
          prev.status = true;
          prev.type = "success";
          prev.message = "Created!";
          prev.description = `Order successfully created by you`;
          return prev;
        });
        setTimeout(() => {
          history.push("/home/ap/custom/orders/list");
        }, 2000);
      })
      .catch((err) => {
        setLoad(false);
        setAlert((prev) => {
          prev = JSON.parse(JSON.stringify(prev));
          prev.status = true;
          prev.type = "error";
          prev.message = "Not Created!";
          prev.description = `Something wrong from server`;
          return prev;
        });
        setTimeout(() => {
          setAlert((prev) => {
            prev = {};
            prev.status = false;
            return prev;
          });
        }, 2000);
      });
  };

  const addBut = () => {
    setindexId(indexId+1)
    const abc = [...addButton, [{id:indexId+1}]];
    console.log(abc);
    setAddButton(abc);
  };

  const handleDelete = (id,i) => {

   // const deletAddButton = [...addButton];

   // console.log("delet",i,"-----addButton",deletAddButton)

   // deletAddButton.splice(i,1);
    
    setAddButton((prev) => {


  var deletAddButton = prev.filter((item,index) => id !== item[0]?.id);
  console.log("deletAddButton",i,'--',deletAddButton);
    return deletAddButton;

  });
var newData = data.products.filter((item,index) => i !== index);
    setData((prev) => {

      prev = {...prev,products:newData};

      return prev;

    });

   //setAddButton(deletAddButton);

  };

  const isButtonDisabled = useMemo(() => {
    let status = true;
    let copied_data = {...data}
    delete copied_data['products']
    let allFieldCount = Object.keys(copied_data).length;
    Object.entries(data).forEach(([key, value]) => {
      if (key === "products") {
        value.forEach((v) => {
          allFieldCount = allFieldCount + Object.keys(v).length;
        });
      }
    });
    console.log(allFieldCount);
    if (category === "architectural" && allFieldCount === 6) {
      status = false;
    } else if (
      category === "interior" &&
      allFieldCount === 4 + addButton.length * 4 &&
      allFieldCount > 4
    ) {
      console.log(4 + addButton.length * 4);
      status = false;
    }
    return status;
  }, [data, addButton]);



  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        createOrder();
      }}
    >
      <div className="ap-flex ap-w100">
        {alert.status && (
          <Alert
            message={alert?.message}
            description={alert?.description}
            type={alert?.type}
            showIcon
            closable
          />
        )}
        {/* <div className="row"></div> */}
        <div className="ap-flex ap-flex-center-center ap-w100 ap-p5">
          <Card style={{ width: "100%" }}>
            <div className="ap-flex ap-flex-center-center ap-w100">
              <div className="ap-flex ap-w100" style={{ marginBottom: "2px" }}>
                Customer
              </div>
              <Select
                size="large"
                showSearch
                onSearch={(data) => customersList(`q=${data}`)}
                labelInValue
                placeholder="Select customer"
                style={{ width: "100%" }}
                value={data?.customer}
                loading={state.customers.loading}
                notFoundContent={
                  state.customers.loading && (
                    <div className="ap-w100 ap-flex ap-flex-center">
                      <Spin size="small" />
                    </div>
                  )
                }
                filterOption={false}
                disabled={customerId || projectId ? true : false}
              >
                {state.customers.customers.map((customer, customerIndex) => (
                  <Option value={customer.value} key={customerIndex}>
                    {customer.label}
                  </Option>
                ))}
              </Select>
            </div>
            <div
              className="ap-flex ap-flex-center-center ap-w100"
              style={{ marginTop: "10px" }}
            >
              <div className="ap-flex ap-w100" style={{ marginBottom: "2px" }}>
                Project
              </div>
              <Select
                size="large"
                labelInValue
                placeholder="Select project"
                style={{ width: "100%" }}
                value={data?.project}
                loading={state.projects.loading}
                showSearch
                disabled={customerId || projectId ? true : false}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {state.projects.projects.map((project, projectIndex) => (
                  <Option value={project.value} key={projectIndex}>
                    {project.label}
                  </Option>
                ))}
              </Select>
            </div>
            <div
              className="ap-flex ap-flex-center-center ap-w100"
              style={{ marginTop: "10px" }}
            >
              <div className="ap-flex ap-w100" style={{ marginBottom: "2px" }}>
                Approver
              </div>
              <Select
                size="large"
                labelInValue
                placeholder="Select approver"
                style={{ width: "100%" }}
                loading={state.admins.loading}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                showSearch
                onChange={(d) =>
                  setFormData({ target: { name: "approver", value: { ...d } } })
                }
              >
                {state.admins.admins.map((admin, adminIndex) => (
                  <Option value={admin.value} key={adminIndex}>
                    {admin.label}
                  </Option>
                ))}
              </Select>
            </div>
            {category === "architectural" && (
              <>
                <div
                  className="ap-flex ap-flex-center-center ap-w100"
                  style={{ marginTop: "10px" }}
                ></div>
                <div
                  className="ap-flex ap-flex-center-center ap-w100"
                  style={{ marginTop: "10px" }}
                >
                  <div
                    className="ap-flex ap-w100"
                    style={{ marginBottom: "2px" }}
                  >
                    Service
                  </div>
                  <Select
                    size="large"
                    placeholder="Select service"
                    style={{ width: "100%" }}
                    loading={state.projects.loading}
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    labelInValue
                    showSearch
                    onChange={(d) =>
                      setFormData({
                        target: { name: "productID", value: d.value }, key: 0,
                      })
                    }
                  >
                    {state.products2.products2.map(
                      (category, categoryIndex) => (
                        <Option value={category.value} key={categoryIndex}>
                          {category.label}
                        </Option>
                      )
                    )}
                  </Select>
                </div>

                <div
                  className="ap-flex ap-flex-center-center ap-w100"
                  style={{ marginTop: "10px" }}
                >
                  <div
                    className="ap-flex ap-w100"
                    style={{ marginBottom: "2px" }}
                  >
                    Budget
                  </div>

                  <Input
                    type="number"
                    size="large"
                    name="estimatedBudget"
                    placeholder="Enter budget"
                    onChange={(data) => {
                      setFormData({ ...data, key: 0 });
                    }}
                  />
                </div>
              </>
            )}

            {/* ////////////////////////////////////////////////////////////////////////// */}

            <>
              {category === "interior" &&
                addButton.map((data, i) => {
                  return (
                    <div class="container" key={data[0].id}>
                      <div className="row btnp">
                        <div >
                          <button

                            type="button"

                            className="btn-danger btnx "

                            onClick={() => handleDelete(data[0].id,i)}

                          >

                            x

                          </button>

                        </div>
                      </div>

                      <div style={{display:"flex"}}>
                      <div class="row" style={{paddingRight: "18px"}}>
                      <div  style={{ paddingLeft: "5px" }}> {i + 1}.</div>
                      </div>
                      <div>
                      <div class="row">
                        <div class="col-sm">
                          <div
                            className="ap-flex ap-w100"
                            style={{ marginBottom: "2px" }}
                          >
                            Service
                          </div>
                          <Select
                            size="large"
                            placeholder="Select service"
                            style={{ width: "100%" }}
                            loading={state.projects.loading}
                            filterOption={(input, option) =>
                              option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            labelInValue
                            showSearch
                            onChange={(d) =>
                              setFormData({
                                target: { name: "productID", value: d.value }, key: i
                              })
                            }
                          >
                            {state.products2.products2.map(
                              (category, categoryIndex) => (
                                <Option
                                  value={category.value}
                                  key={categoryIndex}
                                >
                                  {category.label}
                                </Option>
                              )
                            )}
                          </Select>
                        </div>
                        <div class="col-sm">
                          <div
                            className="ap-flex ap-w100"
                            style={{ marginBottom: "2px" }}
                          >
                            Unit
                          </div>
                          <Select
                            size="large"
                            labelInValue
                            showSearch
                            placeholder="Select Unit"
                            style={{ width: "100%" }}
                            loading={state.units.loading}
                            filterOption={(input, option) =>
                              option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            onChange={(d) =>
                              setFormData({ target: { name: "unit", value: d.value }, key: i })
                            }
                          >
                            {state.units.units.map((units, unitsIndex) => (
                              <option value={units.value} key={unitsIndex}>
                                {units.label}
                              </option>
                            ))}
                          </Select>
                        </div>
                        <div class="col-sm">
                          <div
                            className="ap-flex ap-w100"
                            style={{ marginBottom: "2px" }}
                          >
                            Estimated Budget
                          </div>
                          <Input
                            type="number"
                            size="large"
                            name="estimatedBudget"
                            placeholder="Enter budget"
                            onChange={(data) => {
                              setFormData({ ...data, key: i });
                            }}
                          />
                        </div>
                      </div>
                      <div>
                        <div
                          className="ap-flex ap-w100"
                          style={{ marginBottom: "2px" }}
                        >
                          Description
                        </div>
                        <TextArea
                          rows={8}
                          placeholder="Project Description "
                          name="description"
                          type="text"
                          value={data?.description}
                          onChange={(d) => setFormData({ target: { value: d.target.value, name: d.target.name }, key: i })}
                          style={{ marginTop: "10px" }}
                        />
                      </div>
                      </div>
                      </div>
                    </div>
                  );
                })}

              {category === "interior" && (
                <div>
                  <Button
                    type="button"
                    className="btn btn-success"
                    style={{ marginTop: "10px" }}
                    onClick={() => addBut()}
                  >
                    Add
                  </Button>
                </div>
              )}
            </>

            {/* /////////////////////////////////////////////////////////// */}
          </Card>
        </div>
        <div
          className="ap-flex ap-flex-center-center ap-w50 ap-p5"
          style={{ justifyContent: "flex-start" }}
        >
          {data?.customer && (
            <CustomerDetails
              editFunction={() =>
                history.push(
                  `/home/ap/client/details/${state.customer.customer?.Id}/edit`
                )
              }
            />
          )}
        </div>
      </div>
      <div
        className="ap-flex ap-w100 ap-flex-center-center ap-p5"
        style={{ marginTop: "20px" }}
      >
        <TextArea
          rows={8}
          placeholder="Message for approver"
          name="msgApprover"
          type="text"
          value={data?.msgApprover}
          onChange={(d) => setFormData(d)}
          style={{ marginTop: "10px" }}
        />
      </div>

      <div
        className="ap-flex ap-w100 ap-flex-end ap-p5"
        style={{ marginTop: "20px" }}
      >
        <div className="ap-flex ap-flex-center-center co-btn">
          <Button
            type="primary"
            className="ap-btn-c"
            shape="round"
            icon={<Icons.Plus />}
            size="large"
            htmlType="submit"
            disabled={isButtonDisabled}
            loading={load}
          >
            <span className="ap-fsm ap-fwmd">Create</span>
          </Button>
        </div>
      </div>
    </form>
  );
};

export default CreateCustomOrders;
