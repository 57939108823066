import React, { useEffect } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../../Redux";
import { Loader } from "../../../Components";
import { Card, Tag, Input, Button } from "antd";
import {
  DetailCard,
  DetailContent,
  DetailHeding,
  Info,
  InfoBottonDiv,
  InfoDiv,
  InfoIconDiv,
  PHeading,
} from "./partnerStyle";
import { Icons } from "../../../Utils";
import Partnerleadlist from "./Partnerleadlist";

const PartnerDetails = (props) => {
  const { TextArea } = Input;
  const { id } = useParams();
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const history = useHistory();
  const { partnerDetailsSingle, customer } = bindActionCreators(
    actionCreators,
    dispatch
  );

  useEffect(() => {
    partnerDetailsSingle(id);
    customer(id);
    console.log(state.partnerDetailsSingle);
  }, []);

  return state.partnerDetailsSingle.loading &&
    state.partnerDetailsSingle?.details == null ? (
    <Loader />
  ) : (
    <div className="ap-flex ap-flex-center-center ap-w100">
      <Card
        size="small"
        style={{ width: "100%" }}
        title={
          <div className="ap-flex ap-flex-space-bw ap-w100 ap-fmd ap-fwmd">
            <div className="ap-flex">
              <span>Partner Details</span>
            </div>
            <span
              className="ap-fesm ap-fwmd"
              style={{border: "2px solid",
                color: "var( --primary-dark-background)",
                cursor: "pointer",
                padding: "5px",
            }}
              onClick={() => history.goBack()}
            >
              {"<< go back"}
            </span>
          </div>
        }
      >
        <Card size="small" style={{ width: "100%" }}>
          <div
            className="flex  w100"
            style={{ padding: "8px 5px", flexFlow: "wrap" }}
          >
            <PHeading>Name:</PHeading>{" "}
            {state.customer?.customer?.firstName +  " " + state.customer?.customer?.lastName}
          </div>
          <div
            className="flex  w100"
            style={{ padding: "8px 5px", flexFlow: "wrap" }}
          >
            <PHeading>Phone Number:</PHeading>{" "}
            {state.customer?.customer?.isdCode + state.customer?.customer?.phoneNumber}
          </div>
          <div
            className="flex  w100"
            style={{ padding: "8px 5px", flexFlow: "wrap" }}
          >
            <PHeading>Email:</PHeading>{" "}
            {state.customer?.customer?.email }
          </div>
        </Card>

        <div className="ap-flex ap-w100" style={{ padding: "5px" }}>
          <DetailCard>
            <DetailHeding>Total Order No:</DetailHeding>
            <DetailContent>
              <InfoDiv>
                <Info>
                  {state.partnerDetailsSingle?.details?.totalOrderCount ||
                    "N/A"}
                </Info>
                <InfoIconDiv>
                  <Icons.List />
                </InfoIconDiv>
              </InfoDiv>
              <InfoBottonDiv>
                <span
                  onClick={() =>
                    history.push(`/home/ap/orders/list?partnerId=${id}`)
                  }
                >
                  More Details...
                </span>
              </InfoBottonDiv>
            </DetailContent>
          </DetailCard>
          <DetailCard>
            <DetailHeding>Total Customer No:</DetailHeding>
            <DetailContent>
              <InfoDiv>
                <Info>
                  {state.partnerDetailsSingle?.details?.totalCustomerCount ||
                    "N/A"}
                </Info>
                <InfoIconDiv>
                  <Icons.UserGroup />
                </InfoIconDiv>
              </InfoDiv>
              <InfoBottonDiv>
                <span
                  onClick={() =>
                    history.push(`/home/ap/clients/list?partnerId=${id}`)
                  }
                >
                  More Details...
                </span>
              </InfoBottonDiv>
            </DetailContent>
          </DetailCard>
          <DetailCard>
            <DetailHeding>Sum of Order Amount:</DetailHeding>
            <DetailContent>
              <InfoDiv>
                <Info>
                  {state.customer?.customer?.currency?.symbol +
                    " " +
                    (state.partnerDetailsSingle?.details?.totalSumOrderAmount
                      ? state.partnerDetailsSingle?.details?.totalSumOrderAmount
                      : "0")}
                </Info>
                <InfoIconDiv>
                  <Icons.AccountBalanceWallet />
                </InfoIconDiv>
              </InfoDiv>
              <InfoBottonDiv>
                {/* <span
                  onClick={() =>
                    history.push(`/home/ap/partner/commission-list/${id}`)
                  }
                >
                  More Details...
                </span> */}
              </InfoBottonDiv>
            </DetailContent>
          </DetailCard>
          <DetailCard>
            <DetailHeding>Sum of Total Commission:</DetailHeding>
            <DetailContent>
              <InfoDiv>
                <Info>
                  {state.customer?.customer?.currency?.symbol +
                    " " +
                    (state.partnerDetailsSingle?.details?.totalCommissionAmount
                      ? state.partnerDetailsSingle?.details
                          ?.totalCommissionAmount
                      : "0")}
                </Info>
                <InfoIconDiv>
                  <Icons.Payment />
                </InfoIconDiv>
              </InfoDiv>
              <InfoBottonDiv>
                <span
                  onClick={() =>
                    history.push(`/home/ap/partner/commission-list/${id}`)
                  }
                >
                  More Details...
                </span>
              </InfoBottonDiv>
            </DetailContent>
          </DetailCard>
          <DetailCard>
            <DetailHeding>Sum of Total Received Amount:</DetailHeding>
            <DetailContent>
              <InfoDiv>
                <Info>
                  {state.customer?.customer?.currency?.symbol +
                    " " +
                    (state.partnerDetailsSingle?.details?.totalReciveAmount
                      ? state.partnerDetailsSingle?.details?.totalReciveAmount
                      : "0")}
                </Info>
                <InfoIconDiv>
                  <Icons.AccountBalanceWallet />
                </InfoIconDiv>
              </InfoDiv>
              <InfoBottonDiv>
                <span
                  onClick={() =>
                    history.push(
                      `/home/ap/partner/commission-withdrawal-list/${id}`
                    )
                  }
                >
                  More Details...
                </span>
              </InfoBottonDiv>
            </DetailContent>
          </DetailCard>
        </div>
        <Partnerleadlist/>

      </Card>
    </div>
  );
};

export default PartnerDetails;
