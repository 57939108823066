import { API } from "../../Services";

export const projectFolderpath = (querry) =>{
    return (dispatch) =>{
        dispatch({
            type:"FETCH_PROJECTS_DETAIL",
        })
            API.projectFolderpath(querry).then((res)=>{
                let data = JSON.parse(JSON.stringify(res)).data.projects[0]
                setTimeout(()=>{
                    dispatch({
                        type:"RECEIVE_PROJECTS_DETAIL",
                        payload:data,
                    })
                },1000)
            }).catch((err)=>{
               
                dispatch({
                    type:"ERROR_PROJECTS__DETAIL",
                })
            })
        
         
    }
}