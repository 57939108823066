import React, { useEffect, useState } from "react";
import { Container, Row, Col, Table, Button, Spinner } from "reactstrap";
import { Checkbox } from "antd";
import {
  Form,
  FormGroup,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  FormText,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import toast from "react-hot-toast";
import { Select, Spin, Input,Radio } from "antd";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { RWebShare } from "react-web-share";
import { Icons } from "../../../Utils";
import { CustomerDetails } from "../../../Components";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../../Redux";
import PaymentRequestLink from "../../../chatapp/views/OrderDetailing/PaymentRequestLink";
import { API } from "../../../Services";
import { useHistory } from "react-router-dom";
export default function GeneratePaymentRequest() {
  const [isLinkGenerated, setLinkGenerated] = useState(false);
  const [isUserGst, setUserGst] = useState(false);
  const [isDisable, setDisable] = useState(false);
  const [isCurrencyDisable, setCurrencyDisable] = useState(false);
  const [isModelOpen, setModelOpen] = useState(false);
  const [isSpinner, setSpinner] = useState(false);
  const [walletBalance, setWalletBalance] = useState("");
  const [generatedLink, setGeneratedLink] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [selectedAdmin, setSelectedAdmin] = useState("");
  const [selectedGateway, setSelectedGateway] = useState("");
  const [selectedPurpose, setSelectedPurpose] = useState("");
  const [selectedAmount, setAmount] = useState(1);
  const [selectedLinktype, setSelectedLinktype] = useState('standard');
  const [selectedCurrencie, setSelectedCurrencie] = useState("");
  const [currencies, setCurrencies] = useState([]);
  const options = [
    { value: "Architectural Design Services", label: "Architectural Design Services" },
    { value: "Interior Design Services", label: "Interior Design Services" },
    { value: "Site Visit", label: "Site Visit" },
    { value: "Architectural Design Services and Site Visit", label: "Architectural Design Services and Site Visit" },
    { value: "Interior Design Services and Site Visit", label: "Interior Design Services and Site Visit" },
  ];

  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const { customersList, gatewayList, customer,downlineAdmins  } = bindActionCreators(
    actionCreators,
    dispatch
  );
  const { Option } = Select;
  const history = useHistory();
  const [gstData, setGstData] = useState({
    businessName: "",
    gstNo: "",
  });
  const admin =  JSON.parse(localStorage.getItem("adminDetails"))

  useEffect(() => {
    console.log(selectedCustomer);
    setSelectedAdmin({value:admin?.adminId,label:`${admin?.firstName} ${admin?.lastName}`})
    downlineAdmins()
    gatewayList();
  }, []);
  useEffect(() => {
    if (state.customer?.customer?.gst_no) {
      setUserGst(true);
      setGstData((prev) => {
        prev = JSON.parse(JSON.stringify(prev));
        prev[`businessName`] = state.customer?.customer?.companyname || "-";
        prev[`gstNo`] = state.customer?.customer?.gst_no || "";

        return prev;
      });
    }else{
     
      setUserGst(false);
      setGstData({
        businessName: "",
        gstNo: "",
      });
      
    }
    setLinkGenerated(false);
      setSelectedCurrencie("");
      setSelectedAdmin({value:admin?.adminId,label:`${admin?.firstName} ${admin?.lastName}`})
      setAmount(1);
      setSelectedGateway("");
      setSelectedPurpose("");
      setCurrencies([]);
      setGeneratedLink("");
      setSelectedLinktype('standard')
  }, [state.customer?.customer]);
  useEffect(() => {
    if (selectedCustomer) {
      customer(selectedCustomer);
      setCurrencyDisable(false);
      API.getWalletBalance(selectedCustomer)
        .then((res) => {
          console.log("currencies", res.data.balance);
          setWalletBalance(res.data.balance);
        })
        .catch((err) => {});
    }
  }, [selectedCustomer]);
  useEffect(() => {
    console.log(selectedGateway);
    if (selectedGateway) {
      const userData = localStorage.getItem("token");
      let currencies = [];
      // let currencies = [{label:"INR",value:"INR"}]
      let gatewayCode = state?.gatewayList?.gateway
        .filter((gateway) => gateway.value == selectedGateway)
        .map((data) => {
          return data.code;
        });

      console.log(gatewayCode);
      API.currencyList(gatewayCode)
        .then((res) => {
          if (res.data.currencies.length > 0) {
            res.data.currencies.map((c, i) => {
              currencies.push({
                label: c,
                value: c,
              });
            });
          }
          console.log("currencies", res.data.currencies);
          res.data.currencies.map((val)=>{
            if(val == state.customer?.customer?.currency?.code)
            {
              setSelectedCurrencie(state.customer?.customer?.currency?.code);
              setCurrencyDisable(true);
            }
          })
          setCurrencies(currencies);
        })
        .catch((err) => {});
    }
  }, [selectedGateway]);
  const GetPaymentLink = () => {
    setSpinner(true);
    var newItem = {
      currency: selectedCurrencie,
      gateway: selectedGateway,
      amount: selectedAmount,
      purpose: selectedPurpose,
      isGst: isUserGst == true ? 1 : 0,
      gst_no: isUserGst == true ? gstData.gstNo : "",
      companyname: isUserGst == true ? gstData.businessName : "",
      linkType:selectedLinktype,
      adminId:selectedAdmin?.value
    };
    console.log(newItem);

    API.generatePaymentRequest(selectedCustomer, newItem)
      .then((res) => {
        console.log(res);
        setSpinner(false);
        setLinkGenerated(true);
        setModelOpen(true);
        setGeneratedLink(res?.data?.link);
        setDisable(true);
      })
      .catch((err) => {
        toast.error("Error! ", err?.msg, {
          position: "top-right",
        });
        setSpinner(false);

        console.log(err);
      });
  };
  return (
    <div>
      {/* {this?.state?.loader ? (
      <Loader loading={true} />
    ) : ( */}
      <Container fluid>
        <Form
          style={{ marginTop: 20 }}
          // onSubmit={() => GetPaymentLink()}
          className="form-horizontal"
        >
          <Card>
            <CardHeader>Generate Payment Request</CardHeader>
            <CardBody style={{ display: "flex" }}>
              <div style={{ width: "50%", padding: "0 5px" }}>
                <FormGroup row>
                  <Col xs="12" md="12">
                    <FormText color="muted">Select Customer</FormText>
                    <Select
                      disabled={isDisable}
                      size="large"
                      showSearch
                      onSearch={(data) => customersList(`q=${data}`)}
                      // labelInValue
                      placeholder="Select Customer"
                      style={{ width: "100%" }}
                      value={selectedCustomer}
                      loading={state.customers.loading}
                      onChange={(data) => setSelectedCustomer(data)}
                      notFoundContent={
                        state.customers.loading && (
                          <div className="ap-w100 ap-flex ap-flex-center">
                            <Spin size="small" />
                          </div>
                        )
                      }
                      filterOption={false}
                    >
                      <Option value={""}>Select Customer</Option>

                      {state.customers.customers.map(
                        (customer, customerIndex) => (
                          <Option value={customer.value} index={customerIndex}>
                            {customer.label} ({customer.phoneNumber})
                          </Option>
                        )
                      )}
                    </Select>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col xs="12" md="12">
                    <FormText color="muted">Assigned To</FormText>
                    <Select 
                                        size="large"
                                        showSearch 
                                        disabled={isDisable}
                                        value={selectedAdmin}
                                        labelInValue placeholder="Select Assignee" 
                                        style={{ width: "100%" }} 
                                        loading={state.downlineAdmins.loading}
                                        filterOption={(input, option) =>  
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 
                                            
                                          }
                                        onChange={(data)=>{
                                          console.log(data);
                                          setSelectedAdmin(data)}}
                                    >
                                        {state.downlineAdmins.admins.map((admin,adminIndex)=>(
                                            <Option value={admin.value} key={adminIndex}>{admin.label}</Option>
                                        ))}
                                    </Select>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col xs="12" md="12">
                    <FormText className="help-block">Payment Gateway*</FormText>
                    <Select
                      disabled={isDisable}
                      size="large"
                      placeholder="Select Gateway"
                      style={{ width: "100%" }}
                      value={selectedGateway}
                      loading={state.gatewayList.loading}
                      onChange={(data) => {setSelectedGateway(data);
                      setCurrencyDisable(false);
                      setSelectedCurrencie('');
                      }}
                      notFoundContent={
                        state.gatewayList.loading && (
                          <div className="ap-w100 ap-flex ap-flex-center">
                            <Spin size="small" />
                          </div>
                        )
                      }
                      filterOption={false}
                    >
                      <Option value={""}>Select Gateway</Option>

                      {state.gatewayList.gateway.map(
                        (gateway, gatewayIndex) => (
                          <Option value={gateway.value} index={gatewayIndex}>
                            {gateway.label}
                          </Option>
                        )
                      )}
                    </Select>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col xs="12" md="12">
                    <FormText className="help-block">Payment Link Type</FormText>
                    <Radio.Group onChange={(e)=>setSelectedLinktype(e.target.value)} value={selectedLinktype}>
      <Radio value={'standard'}> Standard Payment Link </Radio>
      <Radio value={'upi'}>UPI Payment Link</Radio>
    </Radio.Group>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col xs="12" md="12">
                    <FormText color="muted">Currency</FormText>
                    <Select
                      disabled={isDisable || isCurrencyDisable}
                      size="large"
                      placeholder="Select Currencie"
                      style={{ width: "100%" }}
                      value={selectedCurrencie}
                      loading={state.gatewayList.loading}
                      onChange={(data) => setSelectedCurrencie(data)}
                      filterOption={false}
                    >
                      <Option value={""}>Select Currency</Option>

                      {currencies.map((currencie, currenciesIndex) => (
                        <Option value={currencie.value} index={currenciesIndex}>
                          {currencie.label}
                        </Option>
                      ))}
                    </Select>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col xs="12" md="12">
                    <FormText color="muted">Amount</FormText>
                    <Input
                      disabled={isDisable}
                      defaultValue={1}
                      value={selectedAmount}
                      type="text"
                      //onWheel={(e) => {return;}}
                      id="amount"
                      name="amount"
                      placeholder="Enter Amount"
                      onChange={(event) => {
                        const re = /^[0-9\b]+$/;
                        if (event.target.value === '' || re.test(event.target.value)) {
                          setAmount(event.target.value);
                        }
                        console.log(event.target.value);
                      }}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col xs="12" md="12">
                    <FormText color="muted">Purpose</FormText>
                    <Select
                      size="large"
                      disabled={isDisable}
                      placeholder="Select Purpose"
                      style={{ width: "100%" }}
                      value={selectedPurpose}
                      loading={state.gatewayList.loading}
                      onChange={(data) => setSelectedPurpose(data)}
                      filterOption={false}
                    >
                      <Option value={""}>Select Purpose</Option>

                      {options.map((purpose, purposeIndex) => (
                        <Option value={purpose.value} index={purposeIndex}>
                          {purpose.label}
                        </Option>
                      ))}
                    </Select>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col xs="12" md="12">
                    <div
                      className="ap-flex ap-flex-center-center ap-w100"
                      style={{ paddingLeft: "10px" }}
                    >
                      <div className="ap-flex ap-flex ap-w100">
                        <Checkbox
                          name="isGst"
                          checked={isUserGst}
                          onChange={(e) => {
                            e = JSON.parse(JSON.stringify(e));
                            console.log(e);
                            if (e.target.checked) {
                              e.target.value = "Yes";
                              setUserGst(true);
                            } else {
                              e.target.value = "No";
                              setUserGst(false);
                            }
                          }}
                          disabled={false}
                        >
                          Attach GST Invoice
                        </Checkbox>
                      </div>{" "}
                    </div>
                  </Col>
                  <Col xs="12" md="12">
                    {isUserGst == true && (
                      <>
                        <div
                          className="ap-flex ap-flex-center-center ap-w100"
                          style={{ marginTop: "10px" }}
                        >
                          <div
                            className="ap-flex ap-w100"
                            style={{ marginBottom: "2px" }}
                          >
                            GST Number
                          </div>
                          <Input
                            type="text"
                            size="large"
                            name="gstNo"
                            disabled={true}
                            value={gstData.gstNo}
                            placeholder="Enter GST number"
                          />
                        </div>
                        <div
                          className="ap-flex ap-flex-center-center ap-w100"
                          style={{ marginTop: "10px" }}
                        >
                          <div
                            className="ap-flex ap-w100"
                            style={{ marginBottom: "2px" }}
                          >
                            Business Name
                          </div>
                          <Input
                            type="text"
                            size="large"
                            name="businessName"
                            disabled={true}
                            value={gstData.businessName}
                            placeholder="Enter business name"
                          />
                        </div>
                      </>
                    )}
                  </Col>
                </FormGroup>
              </div>
              {selectedCustomer && (
                <div style={{ width: "50%", padding: "0 5px" }}>
                  <CustomerDetails
                    editFunction={() =>
                      history.push(
                        `/home/ap/client/details/${state.customer.customer?.Id}/edit`
                      )
                    }
                    showWallet={true}
                    walletBalance={walletBalance}
                  />
                </div>
              )}
            </CardBody>

            <CardFooter style={{ display: "flex" }}>
              <Button
                //  type="submit"
                disabled={
                  isLinkGenerated ||
                  !selectedAmount ||
                  !selectedCurrencie ||
                  !selectedCustomer ||
                  !selectedPurpose ||
                  !selectedGateway
                }
                size="sm"
                color="primary"
                onClick={() => GetPaymentLink()}
              >
                {isSpinner == true ? (
                  <Spinner size="sm" type="grow" color="light" />
                ) : (
                  <>
                    <i className="fa fa-dot-circle-o"></i> Submit
                  </>
                )}
              </Button>
              {isLinkGenerated && (
                <Button
                  style={{ margin: "0 5px" }}
                  onClick={() => {
                    setLinkGenerated(false);
                    setSelectedCurrencie("");
                    setSelectedCustomer("");
                    setSelectedGateway("");
                    setSelectedPurpose("");
                    setCurrencies([]);
                    setAmount(1);
                    setGeneratedLink("");
                    setSelectedLinktype('standard');
                    setCurrencyDisable(false);
                    setDisable(false);
                    setUserGst(false);
                    setSelectedAdmin({value:admin?.adminId,label:`${admin?.firstName} ${admin?.lastName}`})
                    setGstData({
                      businessName: "",
                      gstNo: "",
                    });
                  }}
                  size="sm"
                  color="primary"
                >
                  <i className="fa fa-dot-circle-o"></i> Generate Another Link
                </Button>
              )}
            </CardFooter>
          </Card>
        </Form>
        <div>
          {selectedCustomer && (
            <PaymentRequestLink
              isPaymentRequest={true}
              customerId={selectedCustomer}
              isLinkGenerated={generatedLink ? true : ""}
            />
          )}
        </div>
        <Modal isOpen={isModelOpen} toggle={() => setModelOpen(!isModelOpen)}>
          <ModalHeader toggle={() => setModelOpen(!isModelOpen)}>
            Payment Link
          </ModalHeader>

          <ModalBody>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <a
                alt="sample"
                target="_blank"
                href={generatedLink}
                style={{ fontSize: 20, marginTop: 5, marginLeft: 10 }}
                color="black"
              >
                {generatedLink}
              </a>
            </div>
          </ModalBody>

          <ModalFooter>
            <Button
               className="submit_right1" 
              onClick={() => {
                navigator.clipboard.writeText(generatedLink);
                toast.success("Link Copied!", {
                  position: "top-right",
                });
              }}
              size="sm"
              style={{
                fontSize: "22px",
                display: "flex",
                padding: "5px 10px",
              }}
            >
              <Icons.CopyOutlined />
            </Button>
            <RWebShare
              data={{
                text: "Generated Payment Request link ",
                url: generatedLink,
                title: "Payment Request link",
              }}
              onClick={() => console.log("shared successfully!")}
            >
              <Button  className="submit_right1"  size="sm" style={{ margin: "0 5px" }}>
                <Icons.Share />
              </Button>
            </RWebShare>
          </ModalFooter>
        </Modal>
      </Container>
      {/* )} */}
    </div>
  );
}
