const countryReducer = (state = {
    loading:false,
    countries:[]
}, action) => {
    switch (action.type) {
        case "FETCH_COUNTRIES":
            return { ...state ,loading:true,countries:[]}
        case "RECEIVE_COUNTRIES":
            return {...state, countries:[...action.payload],loading:false,}
        case "ERROR_COUNTRIES":
            return { ...state ,countries:[],loading:true}
        default:
            return { ...state }
    }
}

export default countryReducer;