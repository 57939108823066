import { axiosInstance } from ".."

const masterProjects = async (querry) =>{

    return new Promise(async (resolve,reject)=>{
        axiosInstance
          .get(
            `internal/projects/list?permission=2&projectSource=LOCAL&projectCategory=architectural&rs=1&${querry}`
          )
          .then((res) => {
            if (res.status === 200) {
              return resolve(res);
            }
            return resolve(res);
          })
          .catch((err) => {
            return reject(err);
          });
    })
}

export default masterProjects;