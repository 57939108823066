import React from "react";
import mmhLoader from "../../Assets/Images/mmh_loader.gif";
import "../../Styles/Master.scss"
import "./Loader.scss";

const Loader = (props) =>{
    return <div className="ap-flex ap-w100 ap-flex-center-center loader" >
        <img src={mmhLoader} alt="Make My House Loader" width="250px" height="250px" />
    </div>
}

export default Loader