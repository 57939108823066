 import { axiosInstance } from ".."

// const createCheckList = async (cardId, data) =>{

//     return new Promise(async (resolve,reject)=>{
//         trelloInstance.post(`cards/${cardId}/checklists/?key=6862df56e3522a842ef862dd84f70a44&token=${localStorage.getItem('trellotoken')}`,data).then((res) => { 
//             if(res.status === 200){
//                 return resolve(res)
//             }
//             return resolve(res)
//         }).catch((err) => {
//             return reject(err)
//         })
//     })
// }

// export default createCheckList;

const createCheckList = async (checkItemID,data) =>{

    return new Promise(async (resolve,reject)=>{
        axiosInstance.post(
          `/internal/pm/addCheckItem/${checkItemID}`,data
                )
                .then((res) => {
            console.log(res)
            if(res.status === 200){
                return resolve(res)
            }
            return resolve(res)
        }).catch((err) => {
            return reject(err)
        })
    })
  }
  
  export default createCheckList;