import { API } from "../../Services";

export const interiorDesignSubCategoriesList = (querry, blank = false) => {
  return (dispatch) => {
    dispatch({
      type: "FETCH_INTERIOR_DESIGN_SUB_CATEGORIES_LIST",
    });
    if (!blank) {
      API.interiorDesignSubCategoriesList(querry)
        .then((res) => {
          setTimeout(() => {
            dispatch({
              type: "RECEIVE_INTERIOR_DESIGN_SUB_CATEGORIES_LIST",
              payload: Array.isArray(res.data)
                ? { totalRecords: 0, data: [] }
                : res.data,
            });
          }, 1000);
        })
        .catch((err) => {
          let data = JSON.parse(JSON.stringify(err.message));
          dispatch({
            type: "ERROR_INTERIOR_DESIGN_SUB_CATEGORIES_LIST",
            payload: data,
          });
        });
    } else {
      dispatch({
        type: "ERROR_INTERIOR_DESIGN_SUB_CATEGORIES_LIST_BLANK",
      });
    }
  };
};
