const ProjectDetailReducer = (state = {
    totalRecords:0,
    loading:false,
    data:[]
}, action) => {
    switch (action.type) {
      case "FETCH_PROJECT_DETAIL":
        return { ...state, loading: true, data: [], totalRecords: 0 };
      case "RECEIVE_PROJECT_DETAIL":
        return {
          ...state,
          data: [...action.payload?.data],
          loading: false,
          totalRecords: action.payload?.totalRecords,
        };
      case "ERROR_PROJECT_DETAIL":
        return { ...state, data: [], loading: false, totalRecords: 0 };
      default:
        return { ...state };
    }
}

export default ProjectDetailReducer;