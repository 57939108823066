const customOrdersReducer = (state = {
    loading:false,
    customOrders:[],totalRecords:0
}, action) => {
    switch (action.type) {
        case "FETCH_CUSTOM_ORDERS":
            return { ...state ,loading:true,customOrders:[],totalRecords:0}
        case "RECEIVE_CUSTOM_ORDERS":
            return {...state, customOrders:[...action.payload.data],loading:false,totalRecords:action.payload.totalRecords}
        case "ERROR_CUSTOM_ORDERS":
            return { ...state ,customOrders:[],loading:true,totalRecords:0}
        default:
            return { ...state }
    }
}

export default customOrdersReducer;