import { API } from "../../Services";

export const trelloCheckList = (id) =>{
    return (dispatch) =>{
        dispatch({
            type:"FETCH_TRELLO_CHECK_LIST",
        })
        API.trelloCheckList(id).then((res)=>{
            dispatch({
                type:"RECEIVE_TRELLO_CHECK_LIST",
                payload:res.data.orders,
            })
        }).catch((err)=>{
            dispatch({
                type:"ERROR_TRELLO_CHECK_LIST",
            })
        })
    }
}