import React, { useState, useEffect } from "react";
import { Card, Spin, Table } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../../Redux";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";

export default function PartnerCommissionList() {
  const state = useSelector((state) => state);
  const { id } = useParams();

  const dispatch = useDispatch();
  const [data, setData] = useState({
    value: "",
    key: "",
  });
  const history = useHistory();
  const [tableData, setTableData] = useState([]);
  const [current, setCurrent] = useState({
    page: 1,
    size: 10,
  });
  const { partnersComissionList, customer } = bindActionCreators(
    actionCreators,
    dispatch
  );

  const columns = [
    {
      title: <div className="ap-fsm ap-fwmd"> ID</div>,
      dataIndex: "commission_id",
      key: "commission_id",
      render: (text) => <div className="ap-fsm">{text}</div>,
    },
    {
      title: <div className="ap-fsm ap-fwmd">Currency</div>,
      dataIndex: "currency",
      key: "currency",
      render: (text) => <div className="ap-fsm">{text}</div>,
    },
    {
      title: <div className="ap-fsm ap-fwmd"> Amount</div>,
      dataIndex: "commission_amount",
      key: "commission_amount",
      render: (text) => <div className="ap-fsm">{text}</div>,
    },
    {
      title: <div className="ap-fsm ap-fwmd">Date</div>,
      dataIndex: "date",
      key: "date",
      render: (text) => (
        <div className="ap-fsm">
          {moment(text).format("MMM D, YYYY [at] h:mm A z")}
        </div>
      ),
    },
    {
      title: <div className="ap-fsm ap-fwmd">Status</div>,
      dataIndex: "commissionStatus",
      key: "commissionStatus",
      render: (text) => <div className="ap-fsm">{text}</div>,
    },
    {
      title: <div className="ap-fsm ap-fwmd">Mature Date</div>,
      dataIndex: "matureDate",
      key: "matureDate",
      render: (text) => (
        <div className="ap-fsm">
          {moment(text).format("MMM D, YYYY [at] h:mm A z")}
        </div>
      ),
    },
  ].filter((item) => !item.hidden);
  const setFormData = (event) => {
    setData((prev) => {
      prev = JSON.parse(JSON.stringify(prev));
      prev.key = event.target.name;
      prev.value = event.target.value;
      return prev;
    });
  };

  useEffect(() => {
    if (state.partnersComissionList.comission.length > 0) {
      console.log(state.partnersComissionList.comission);
      setTableData((prev) => {
        prev = [];
        state.partnersComissionList.comission.map((partner, customerIndex) => {
          prev.push({
            commission_id: partner.commission_id,
            currency: partner.currency,
            commission_amount: partner.commission_amount,
            date: partner.date,
            commissionStatus: partner.commissionStatus,
            matureDate: partner.matureDate,
          });
        });
        return prev;
      });
    } else {
      setTableData((prev) => {
        console.log(prev);
      });
    }
  }, [state]);

  const callRecords = (event) => {
    setCurrent((prev) => {
      prev = JSON.parse(JSON.stringify(prev));
      prev.page = prev.size === event.payload.size ? event.payload.page : 1;
      prev.size = event.payload.size;
      return prev;
    });
  };

  useEffect(() => {
    let offset = current.page === 1 ? 0 : Number(current.page) * 10;
    partnersComissionList(id, `limit=${current.size}&offset=${offset}`);
    customer(id);
  }, [current]);
  return (
    <>
      {" "}
      <div className="ap-flex ap-flex-center-center ap-w100">
        {!state.partnersComissionList.loading ? (
          <div className="ap-flex ap-w100" style={{ marginTop: "20px" }}>
            {/* <Card style={{width:"100%"}}> */}
            <Card
              size="small"
              style={{ width: "100%" }}
              title={
                <div className="ap-flex ap-flex-space-bw ap-w100 ap-fmd ap-fwmd">
                  <div>
                    <span style={{ display: "flex", flexDirection: "column" }}>
                      Commission List
                    </span>
                    <span
                      style={{
                        fontSize: "15px",
                        color: "gray",
                        fontStyle: "italic",
                        fontWeight: "400",
                      }}
                    >
                      (Partner Name:{" "}
                      {state.customer?.customer?.firstName +
                        " " +
                        state.customer?.customer?.lastName}
                      ){" "}
                    </span>
                  </div>

                  <span
                    className="ap-fesm ap-fwmd"
                    style={{ color: "blue", cursor: "pointer" }}
                    onClick={() => history.goBack()}
                  >
                    {"<< go back"}
                  </span>
                </div>
              }
            >
              {/* <div className="ap-flex ap-w100 ap-fsm ap-fwmd ap-flex-end rst-record" onClick={()=>{
            setData({key:'',value:''})
            partnersComissionList(id,`limit=10&offset=0&q=`)
        }}>Reset Records</div> */}

              <Table
                columns={columns}
                dataSource={tableData}
                scroll={{ x: 900 }}
                pagination={{
                  current: current.page,
                  pageSize: current.size,
                  position: ["bottomLeft"],
                  total: state.partnersComissionList.totalRecords,
                  onChange: (data, size) =>
                    callRecords({
                      type: "page",
                      payload: { page: data, size: size },
                    }),
                  showTotal: (total, range) =>
                    `${range[0]}-${range[1]} of ${total} items`,
                }}
              />
            </Card>
          </div>
        ) : (
          <div
            className="ap-flex ap-w100 ap-flex-center-center"
            style={{ height: "300px" }}
          >
            <Spin tip="Fetching Records....." />
          </div>
        )}
      </div>
    </>
  );
}
