import React, {useState,useEffect} from "react";
import { Card, Input,Spin,Button,Table,Space} from 'antd';
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../../Redux";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";


export default function PartnerCommissionWithdrawalList() {
    const state = useSelector((state)=>state)
    const { id } = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const [data,setData] = useState({
        value:"",
        key:"",
    });
    const [tableData,setTableData] = useState([]);
    const [current,setCurrent] =useState({
        page:1,
        size:10,
    })
    const { partnersComissionWithdrawalList,customer } = bindActionCreators(actionCreators,dispatch)

   
    const columns = [
       

        {
            title:<div className="ap-fsm ap-fwmd">ID</div>,
            dataIndex:"withdrawalid",
            key:"withdrawalid",
            render:text=><div className="ap-fsm">{text}</div>,

        },
        {
            title:<div className="ap-fsm ap-fwmd">Date</div>,
            dataIndex:"date",
            key:"date",
            render:text=><div className="ap-fsm">   {moment(text).format("MMM D, YYYY [at] h:mm A z")}</div>,
           
        },
        
        {
            title:<div className="ap-fsm ap-fwmd">Amount</div>,
            dataIndex:"amount",
            key:"amount",
            render:text=><div className="ap-fsm">{text}</div>,
        },
        {
            title:<div className="ap-fsm ap-fwmd">Request Date</div>,
            dataIndex:"requestdate",
            key:"requestdate",
            render:text=><div className="ap-fsm">   {moment(text).format("MMM D, YYYY [at] h:mm A z")}</div>,
           
        },
        {
            title:<div className="ap-fsm ap-fwmd">Status</div>,
            dataIndex:"status",
            key:"status",
            render:text=><div className="ap-fsm">{text}</div>

        },
       
        
    ].filter(item => !item.hidden);
    const setFormData =(event)=>{
        setData((prev)=>{
            prev = JSON.parse(JSON.stringify(prev))
            prev.key = event.target.name
            prev.value = event.target.value
            return prev
        })
    }

    useEffect(()=>{
        if(state.partnersComissionWithdrawalList.comission.length > 0){
            console.log(state.partnersComissionWithdrawalList.comission);
            setTableData((prev)=>{
                prev = []
                state.partnersComissionWithdrawalList.comission.map((partner,customerIndex)=>{
                    prev.push({
                        withdrawalid:partner.withdrawalid,
                        date:partner.date,
                        amount:partner.amount,
                        requestdate:partner.requestdate,
                        status:partner.status,
                    })
                })
                return prev
            })
        } else {
            setTableData([])
    }
    },[state])

    const callRecords = (event) =>{
        setCurrent((prev)=>{
            prev = JSON.parse(JSON.stringify(prev))
            prev.page = prev.size === event.payload.size ? event.payload.page:1
            prev.size = event.payload.size
            return prev
        })
    }

    useEffect(()=>{
            let offset = current.page === 1 ? 0 : Number(current.page) * 10
            partnersComissionWithdrawalList(id,`limit=${current.size}&offset=${offset}`)
            customer(id);
    },[current])
  return (
    <> <div className="ap-flex ap-flex-center-center ap-w100">
  
                
    { !state.partnersComissionWithdrawalList.loading ? <div className="ap-flex ap-w100" style={{marginTop:"20px"}}>
    <Card
              size="small"
              style={{ width: "100%" }}
              title={
                <div className="ap-flex ap-flex-space-bw ap-w100 ap-fmd ap-fwmd">
                  <div>
                    <span style={{ display: "flex", flexDirection: "column" }}>
                      Commission Withdrawal List
                    </span>
                    <span
                      style={{
                        fontSize: "15px",
                        color: "gray",
                        fontStyle: "italic",
                        fontWeight: "400",
                      }}
                    >
                      (Partner Name:{" "}
                      {state.customer?.customer?.firstName +
                        " " +
                        state.customer?.customer?.lastName}
                      ){" "}
                    </span>
                  </div>

                  <span
                    className="ap-fesm ap-fwmd"
                    style={{ color: "blue", cursor: "pointer" }}
                    onClick={() => history.goBack()}
                  >
                    {"<< go back"}
                  </span>
                </div>
              }
            >
        {/* <div className="ap-flex ap-w100 ap-fsm ap-fwmd ap-flex-end rst-record" onClick={()=>{
            setData({key:'',value:''})
            partnersComissionWithdrawalList(id,`limit=10&offset=0&q=`)
        }}>Reset Records</div> */}

            <Table 
                columns={columns} 
                dataSource={tableData} 
                scroll={{ x: 900 }} 
                pagination={
                    {
                        current:current.page,
                        pageSize:current.size,
                        position:["bottomLeft"],
                        total:state.partnersComissionWithdrawalList.totalRecords,
                        onChange:(data,size)=>callRecords({type:"page",payload:{page:data,size:size}}),
                        showTotal:(total, range) => `${range[0]}-${range[1]} of ${total} items`,
                    }
                }
            />
        </Card>
    </div>:
        <div className="ap-flex ap-w100 ap-flex-center-center" style={{height:"300px"}}>
            <Spin tip="Fetching Records....."/>
        </div>
    }
</div></>
  )
}
