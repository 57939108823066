import React, { useState } from "react";
import { Card, Input, Button, Alert } from "antd";
import { useSelector } from "react-redux";
import { API } from "../../../Services";
import { Box, Center, Flex, Text } from "@chakra-ui/react";
import { useParams, useHistory } from "react-router-dom";

const Requestor = (props) => {
  const [load, setLoad] = useState(false);
  const state = useSelector((state) => state);
  const { id } = useParams();
  const history = useHistory();
  const [alert, setAlert] = useState({
    status: false,
  });

  const getProductName = () => {
    let pname = "";
    state.products2.products2.map((product, productIndex) => {
      if (product.id === state.customOrder.customOrder?.productID) {
        pname = product.name;
      }
    });
    return pname;
  };

  const generateOrder = () => {
    setLoad((prev) => !prev);
    API.generateOrderViaCustomOrder(id)
      .then((res) => {
        setLoad((prev) => !prev);
        setAlert((prev) => {
          prev = JSON.parse(JSON.stringify(prev));
          prev.status = true;
          prev.type = "success";
          prev.message = "Order Generated";
          prev.description = `This order is successfully generated !`;
          return prev;
        });
        setTimeout(() => {
          setAlert((prev) => {
            prev = {};
            prev.status = false;
            return prev;
          });
         // history.push("/home/ap/orders/list");

         history.push(`/home/ap/order/details/${res?.data?.order?.orderCode}`);
        }, 2000);
      })
      .catch((err) => {
        setLoad((prev) => !prev);
        setAlert((prev) => {
          prev = JSON.parse(JSON.stringify(prev));
          prev.status = true;
          prev.type = "error";
          prev.message = "Not Generated";
          prev.description = `This order is not generated !`;
          return prev;
        });
        setTimeout(() => {
          setAlert((prev) => {
            prev = {};
            prev.status = false;
            return prev;
          });
        }, 2000);
      });
  };

  return (
    <form
      className="ap-flex ap-w100"
      style={{ marginTop: "20px" }}
      onSubmit={(event) => {
        event.preventDefault();
      }}
    >
      {alert.status && (
        <Alert
          message={alert?.message}
          description={alert?.description}
          type={alert?.type}
          showIcon
          closable
        />
      )}
      <Card size="small" style={{ width: "100%" }}>
        <Card size="small" style={{ width: "100%" }}>
          {state.customOrder.customOrder?.products?.map(
            (product, productIndex) => (
              <Box
                w="100%"
                border="1px solid rgba(0,0,0,0.5)"
                p={3}
                borderRadius={10}
                mt={5}
                key={productIndex}
              >
                <div>
                  <Box className="ap-flex ap-w100 ap-flex-space-bw  ap-p5">
                    <Text color="black" fontSize="xl" fontWeight="600" className="ap-flex ap-flex-center-center ap-fsm ap-fwmd">
                      Service
                    </Text>
                    <Text color="black" fontSize="md" fontWeight="400" className="ap-flex ap-flex-center-center ap-fsm">
                      {product?.product?.name}
                    </Text>
                  </Box>

                  {!Array.isArray(product.unit) && (
                    <Box  className="ap-flex ap-w100 ap-flex-space-bw  ap-p5">
                      <Text color="black" fontSize="xl" fontWeight="600" className="ap-flex ap-flex-center-center ap-fsm ap-fwmd">
                        Unit
                      </Text>
                      <Text color="black" fontSize="md" fontWeight="400" className="ap-flex ap-flex-center-center ap-fsm">
                        {product?.unit?.name}
                      </Text>
                    </Box>
                  )}

                  <Box  className="ap-flex ap-w100 ap-flex-space-bw  ap-p5">
                    <Text color="black" fontSize="xl" fontWeight="600" className="ap-flex ap-flex-center-center ap-fsm ap-fwmd">
                      Estimated Budget
                    </Text>
                    <Text color="black" fontSize="md" fontWeight="400" className="ap-flex ap-flex-center-center ap-fsm">
                      {product.estimatedBudget}
                    </Text>
                  </Box>
                </div>
                {product.description && product.description !== "" && (
                  <Flex mt={4}>
                    <Box className="ap-flex ap-w100 ap-flex-space-bw  ap-p5">
                      <Text color="black" fontSize="xl" fontWeight="600">
                        Description
                      </Text>
                      <Text color="black" fontSize="md" fontWeight="400">
                        {product.description}
                      </Text>
                    </Box>
                  </Flex>
                )}
                <Flex mt={4}>
                  <div className="ap-flex ap-flex-center-center ap-w50">
                    <div className="ap-flex ap-w100 ap-fsm ap-fwmd">Price</div>
                    <Input
                      size="large"
                     // type="number"
                      style={{ width: "100%" }}
                      placeholder="Enter price"
                      value={product?.price}
                      name="price"
                      disabled={true}
                    />
                  </div>
                  <div
                    className="ap-flex ap-flex-center-center ap-w50"
                    style={{ marginLeft: "10px" }}
                  >
                    <div className="ap-flex ap-w100 ap-fsm ap-fwmd">
                      Activation Amount
                    </div>
                    <Input
                      size="large"
                     // type="number"
                      style={{ width: "100%" }}
                      placeholder="Enter activation amount"
                      value={product?.activationAmount}
                      name="activation"
                      disabled={true}
                    />
                  </div>
                </Flex>
              </Box>
            )
          )}
        </Card>
        <div className="ap-flex  ap-w100">
          <div className="ap-flex ap-w50">
            <Card size="small" style={{ width: "100%" }}>
              <div className="ap-flex ap-w100 ap-flex-center-center  ap-p5">
                <span
                  className="ap-w100 ap-fsm ap-fwmd"
                  style={{ marginBottom: "5px" }}
                >
                  First Installment
                </span>
                <Input
                  size="large"
                  style={{ width: "100%" }}
                  placeholder="Enter Order Id"
                  value={state.customOrder.customOrder?.activationAmount}
                  name="activationAmount"
                  readOnly
                  disabled
                />
              </div>
            </Card>
          </div>

          <div className="ap-flex ap-w50" style={{ marginLeft: "10px" }}>
            <Card size="small" style={{ width: "100%" }}>
              <div className="ap-flex ap-w100 ap-flex-space-bw  ap-p5">
                <div className="ap-flex ap-flex-center-center ap-fsm ap-fwmd">
                  Quotation Id
                </div>
                <div className="ap-flex ap-flex-center-center ap-fsm">
                  #{state.customOrder.customOrder?.orderid}
                </div>
              </div>
              <div className="ap-flex ap-w100 ap-flex-space-bw  ap-p5">
                <div className="ap-flex ap-flex-center-center ap-fsm ap-fwmd">
                  Amount
                </div>
                <div className="ap-flex ap-flex-center-center ap-fsm">
                  {state.customOrder.customOrder?.baseAmount
                    ? state.customOrder.customOrder?.baseAmount
                    : state.customOrder.customOrder?.price}
                </div>
              </div>
              <div className="ap-flex ap-w100 ap-flex-space-bw  ap-p5">
                <div className="ap-flex ap-flex-center-center ap-fsm ap-fwmd">
                  GST Amount
                </div>
                <div className="ap-flex ap-flex-center-center ap-fsm">
                  {state.customOrder.customOrder?.gstAmount
                    ? state.customOrder.customOrder?.gstAmount
                    : 0}
                </div>
              </div>
              <div
                className="ap-flex ap-w100 ap-flex-space-bw  ap-p5"
                style={{ borderTop: "1px solid black" }}
              >
                <div className="ap-flex ap-flex-center-center ap-fsm ap-fwmd">
                  Total
                </div>
                <div className="ap-flex ap-flex-center-center ap-fsm">
                  {state.customOrder.customOrder?.price}
                </div>
              </div>
             { state.customOrder.customOrder?.orderStatus === "approved" && state.customOrder.customOrder?.ispublish === 0 &&<div
                className="ap-flex ap-w100 ap-flex-end ap-p5"
                style={{ marginTop: "20px" }}
              >
                <Button
                  type="primary"
                  shape="round"
                  size="large"
                  htmlType="button"
                  loading={load}
                  width={100}
                  onClick={() => generateOrder()}
                >
                  <span className="ap-fsm ap-fwmd">Generate Order</span>
                </Button>
              </div>}
            </Card>
          </div>
        </div>
      </Card>
    </form>
  );
};

export default Requestor;
