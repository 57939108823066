
const Images = {
    logo: require('./logo-main.png'),
    user: require('./user.png'),
    files: require('./paperclip.png'),
    send: require('./send.png'),
    pdf: require('./checked.png'),
    loading: require('./loading.gif'),
    arrowDown: require('./arrowDown.png'),
    logout: require('./logout.png'),
    file: require('./file.png'),
    trello: require('./trello.png'),
    doubleTick: require('./doubleChecking.png'),
    rightTick: require('./rightTick.png')
}

export default Images;
