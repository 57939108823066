const trelloNewListReducer = (state = {
    loading:false,
    newlist:[]
}, action) => {
    switch (action.type) {
        case "FETCH_TRELLO_NEW_LIST":
            return { ...state ,loading:true,newlist:[]}
        case "RECEIVE_TRELLO_NEW_LIST":
            return {...state, newlist:[...action.payload],loading:false}
        case "ERROR_TRELLO_NEW_LIST":
            return { ...state ,newlist:[],loading:false}
        default:
            return { ...state }
    }
}

export default trelloNewListReducer;