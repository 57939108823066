
const paymentLinkListReducer = (state = {
    loading:false,
    links:[],totalRecords:0
}, action) => {
    switch (action.type) {
        case "FETCH_PAYMENT_LINK_LIST":
            return { ...state ,loading:true,links:[],totalRecords:0}
        case "RECEIVE_PAYMENT_LINK_LIST":
            return {...state, links:[...action.payload.data],loading:false,totalRecords:action.payload.totalRecords}
        case "ERROR_PAYMENT_LINK_LIST":
            return { ...state ,links:[],loading:true,totalRecords:0}
        default:
            return { ...state }
    }
}

export default paymentLinkListReducer;