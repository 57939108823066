import { API } from "../../Services"

export const customer = (id) =>{
    return (dispatch) =>{
        dispatch({
            type:"FETCH_CUSTOMER",
        })
         API.customer(id).then((res)=>{
            let representedData = {}
            representedData.Id = res.data.id
            representedData.Name = `${res.data.firstName} ${res.data.lastName}`
            representedData.Email = res.data.email
            representedData["Contact Number"] = `${res.data.phoneNumber !== ""?`+${res.data.isdCode} ${res.data.phoneNumber}`:""}`
            representedData.City = res.data.city
            representedData.State = res.data.state
            representedData.Country = res.data.country
            representedData[`Currency Code`] = res.data.currency?.code
            representedData[`Currency`] = res.data.currency?.code
            representedData[`Currency Name`] = res.data.currency?.name
            representedData[`Currency Multiplier`] = res.data.currency?.multiplier
            representedData.Symbol = res.data.currency?.symbol
            representedData.Manager = `${res.data.managerId?.name} ${res.data.managerId?.lastName}`
            representedData["Manager Id"] = res.data.managerId?.Admin_id
            representedData["Lead No"] = res.data.leadNo
            setTimeout(()=>{
                dispatch({
                    type:"RECEIVE_CUSTOMER",
                    payload:{...representedData,...res.data},
                })
            },1000)
        }).catch((err)=>{
            dispatch({
                type:"ERROR_CUSTOMER",
            })
        })
    }
}