const trelloAllMembersListReducer = (state = {
    loading:false,
    allmembers:[],
}, action) => {
    switch (action.type) {
        case "FETCH_TRELLO_ALL_MEMBERS":
            return { ...state ,loading:true,allmembers:[]}
        case "RECEIVE_TRELLO_ALL_MEMBERS":
            return {...state, allmembers:[...action.payload],loading:false}
        case "ERROR_TRELLO_ALL_MEMBERS":
            return { ...state ,allmembers:[],loading:false}
        default:
            return { ...state }
    }
}

export default trelloAllMembersListReducer;