import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import {
  Select,
  Spin,
  Input,
  Checkbox,
  Button,
  Skeleton,
  notification,
} from "antd";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../../Redux";
import useMediaQuery from "react-use-media-query-hook";
import "./CreateNewProject.scss";
import { Icons } from "../../../Utils";
import { API } from "../../../Services";

const residentialType = [
  { value: "RESD", label: "Residential" },
  { value: "COMM", label: "Commercial" },
];

const directions = [
  { value: "EE", label: "East " },
  { value: "WW", label: "West" },
  { value: "NN", label: "North" },
  { value: "SS", label: "South" },
  { value: "NE", label: "North-East" },
  { value: "NW", label: "North-West" },
  { value: "SE", label: "South-East" },
  { value: "SW", label: "South-West" },
];

const CreateNewProject = (props) => {
  const [data, setData] = useState({});
  const [load, setLoad] = useState(false);
  const [isFormDisabled, setIsFormDisabled] = useState(true);
  const state = useSelector((state) => state);

  const isTablet = useMediaQuery("(max-wdith: 756px)");
  const { f } = useParams();
  const dispatch = useDispatch();
  const { customersList, customer } = bindActionCreators(
    actionCreators,
    dispatch
  );
  const { Option } = Select;
  const history = useHistory();

  useEffect(() => {
    let fieldCount = data.isGst || data.isGst === "Yes" ? 11 : 8;
    let check =
      Object.values(data).length === fieldCount &&
      !Object.values(data).includes("")
        ? false
        : true;
    setIsFormDisabled(check);
  }, [data]);

  useEffect(() => {
    console.log(data);
  }, [data]);

  const setFormData = (event) => {
    setData((prev) => {
      prev = JSON.parse(JSON.stringify(prev));
      if (
        ["customer", "resident", "direction", "projectCategory"].includes(
          event.target.name
        )
      ) {
        prev[`${event.target.name}`] = event.target;
        if (
          event.target.name === "projectCategory" &&
          event.target.value === "interior"
        ) {
          prev.width = 0;
          prev.depth = 0;
          prev.floor = 0;
          prev.area = 0;
          prev.direction = { label: "East", value: "E" };
        } else if (event.target.value === "architectural") {
          delete prev.width;
          delete prev.depth;
          delete prev.floor;
          delete prev.area;
          delete prev.direction;
        }
      } else {
        if (event.target.value === "No") {
          delete prev.isGst;
          delete prev.business;
          delete prev.gstNo;
        } else {
          prev[`${event.target.name}`] = event.target.value;
          if (prev.width && prev.depth && prev.floor) {
            prev.area =
              Number(prev.width) * Number(prev.depth) * Number(prev.floor);
          }
        }
      }
      return prev;
    });
    if (event.target.name === "customer") {
      customer(event.target.value);
    }
  };

  useEffect(() => {
    if (state.customer?.customer !== null) {
      if (
        state.customer?.customer?.country === null ||
        state.customer?.customer?.country === ""
      ) {
        alert("Please edit customer's details");
        history.push(`/home/ap/client/details/${state.customer?.customer?.id}`);
      } else {
        API.states(state.customer?.customer?.country).then((res) => {
          let match = false;
          res.data.states.map((s, i) => {
            if (s.code === state.customer?.customer?.state) {
              match = true;
            }
          });
          if (match === false) {
            alert("Please edit customer's details");
            history.push(`/home/ap/client/details/${res.data.id}`);
          }
        });
      }
    }
  }, [state]);

  const handleCreateProject = (event) => {
    event.preventDefault();
    let arrangedData = {
      length: data?.depth,
      width: data?.width,
      floor: data?.floor,
      direction: data?.direction.value,
      type: data?.resident.value,
      buildArea: data?.area,
      customerID: data?.customer.value,
      category: data?.projectCategory?.value,
    };
    arrangedData =
      data?.isGst === "Yes"
        ? {
            ...arrangedData,
            isGst: data?.isGst,
            gstNo: data?.gstNo,
            businessName: data?.business,
          }
        : { ...arrangedData };

    setLoad((prev) => !prev);
    API.createProject(arrangedData)
      .then((res) => {
        setLoad((prev) => !prev);
        notification.success({
          message: "Project Created",
          description: "Project is successfully created",
          duration: 3,
          placement: "bottomRight",
        });
        setTimeout(() => {
          history.push(
            `/home/ap/project/details/${res.data?.ID}/${data?.customer?.value}/${res.data?.projectCode}`
          );
        }, 3000);
      })
      .catch((err) => {
        setLoad((prev) => !prev);
        notification.error({
          message: "Internal Error",
          description: "Some error(s) is/are occured",
          duration: 3,
          placement: "bottomRight",
        });
      });
  };

  return (
    <div className="ap-flex ap-flex-center-center ap-w100 create-new-project">
      {/* Card */}
      <div className="ap-flex ap-w100 ap-flex-center-center custom-card">
        {/* Heading */}
        <div className="ap-flex ap-w100 ap-flex-space-bw head">
          <div className="ap-flex ap-flex-center-center ap-fmd ap-fwmd">
            Create Project
          </div>
        </div>

        <div
          className={`ap-flex ap-w100 ${
            isTablet ? "ap-flex-center-center" : ""
          }`}
        >
          <div className="ap-flex ap-w60" style={{ padding: "15px" }}>
            {/* Card */}
            <form
              className="ap-flex ap-w100 ap-flex-center-center custom-card2"
              onSubmit={(event) => handleCreateProject(event)}
            >
              {/* Row */}
              <div
                className={`ap-flex ap-w100 ${
                  isTablet ? "ap-flex-center-center" : ""
                }`}
              >
                <div
                  className={`ap-flex ap-flex-center-center ${
                    isTablet ? "ap-w100" : "ap-w33"
                  }`}
                  style={{ padding: "7px" }}
                >
                  <div
                    className="ap-flex ap-fsm ap-fwmd ap-w100"
                    style={{ marginBottom: "2px" }}
                  >
                    Customer
                  </div>
                  <Select
                    size="medium"
                    showSearch
                    onSearch={(data) => customersList(`q=${data}`)}
                    labelInValue
                    placeholder="Select customer"
                    style={{ width: "100%" }}
                    value={data?.customer}
                    loading={state.customers.loading}
                    onChange={(d) =>
                      setFormData({ target: { name: "customer", ...d } })
                    }
                    notFoundContent={
                      state.customers.loading && (
                        <div className="ap-w100 ap-flex ap-flex-center">
                          <Spin size="small" />
                        </div>
                      )
                    }
                    filterOption={false}
                  >
                    {state.customers.customers.map(
                      (customer, customerIndex) => (
                        <Option value={customer.value} key={customerIndex}>
                          {customer.label}
                        </Option>
                      )
                    )}
                  </Select>
                </div>
                <div
                  className={`ap-flex ap-flex-center-center ${
                    isTablet ? "ap-w100" : "ap-w33"
                  }`}
                  style={{ padding: "7px" }}
                >
                  <div
                    className="ap-flex ap-fsm ap-fwmd ap-w100"
                    style={{ marginBottom: "2px" }}
                  >
                    Project Type
                  </div>
                  <Select
                    size="medium"
                    labelInValue
                    placeholder="Select customer"
                    style={{ width: "100%" }}
                    value={data?.resident}
                    loading={state.customers.loading}
                    onChange={(d) =>
                      setFormData({ target: { name: "resident", ...d } })
                    }
                    notFoundContent={
                      state.customers.loading && (
                        <div className="ap-w100 ap-flex ap-flex-center">
                          <Spin size="small" />
                        </div>
                      )
                    }
                    filterOption={false}
                  >
                    {residentialType.map((resident, residentIndex) => (
                      <Option value={resident.value} key={residentIndex}>
                        {resident.label}
                      </Option>
                    ))}
                  </Select>
                </div>

                <div
                  className={`ap-flex ap-flex-center-center ${
                    isTablet ? "ap-w100" : "ap-w33"
                  }`}
                  style={{ padding: "7px" }}
                >
                  <div
                    className="ap-flex ap-fsm ap-fwmd ap-w100"
                    style={{ marginBottom: "2px" }}
                  >
                    Project Category
                  </div>
                  <Select
                    size="medium"
                    labelInValue
                    placeholder="Select category"
                    style={{ width: "100%" }}
                    value={data?.projectCategory}
                    onChange={(d) =>
                      setFormData({ target: { name: "projectCategory", ...d } })
                    }
                  >
                    <Option value="architectural">Architectural</Option>
                    <Option value="interior">Interior</Option>
                  </Select>
                </div>
              </div>
              {/* Row */}
              {data?.projectCategory?.value === "architectural" && (
                <>
                  <div
                    className={`ap-flex ap-w100 ${
                      isTablet ? "ap-flex-center-center" : ""
                    }`}
                  >
                    <div
                      className={`ap-flex ap-flex-center-center ${
                        isTablet ? "ap-w100" : "ap-w33"
                      }`}
                      style={{ padding: "7px" }}
                    >
                      <div
                        className="ap-flex ap-fsm ap-fwmd ap-w100"
                        style={{ marginBottom: "2px" }}
                      >
                        Direction
                      </div>
                      <Select
                        size="medium"
                        labelInValue
                        placeholder="Select customer"
                        style={{ width: "100%" }}
                        value={data?.direction}
                        loading={state.customers.loading}
                        onChange={(d) =>
                          setFormData({ target: { name: "direction", ...d } })
                        }
                        notFoundContent={
                          state.customers.loading && (
                            <div className="ap-w100 ap-flex ap-flex-center">
                              <Spin size="small" />
                            </div>
                          )
                        }
                        filterOption={false}
                      >
                        {directions.map((direction, directionIndex) => (
                          <Option value={direction.value} key={directionIndex}>
                            {direction.label}
                          </Option>
                        ))}
                      </Select>
                    </div>

                    <div
                      className={`ap-flex ap-flex-center-center ${
                        isTablet ? "ap-w100" : "ap-w33"
                      }`}
                      style={{ padding: "7px" }}
                    >
                      <div
                        className="ap-flex ap-fsm ap-fwmd ap-w100"
                        style={{ marginBottom: "2px" }}
                      >
                        Width
                      </div>
                      <Input
                        size="medium"
                        style={{ width: "100%" }}
                        placeholder="Enter width"
                        name="width"
                        type="number"
                        onChange={(e) => setFormData(e)}
                      />
                    </div>
                    <div
                      className={`ap-flex ap-flex-center-center ${
                        isTablet ? "ap-w100" : "ap-w33"
                      }`}
                      style={{ padding: "7px" }}
                    >
                      <div
                        className="ap-flex ap-fsm ap-fwmd ap-w100"
                        style={{ marginBottom: "2px" }}
                      >
                        Depth
                      </div>
                      <Input
                        size="medium"
                        style={{ width: "100%" }}
                        placeholder="Enter depth"
                        value={data?.depth}
                        name="depth"
                        type="number"
                        onChange={(e) => setFormData(e)}
                      />
                    </div>
                  </div>
                  {/* Row */}
                  <div
                    className={`ap-flex ap-w100 ${
                      isTablet ? "ap-flex-center-center" : ""
                    }`}
                  >
                    <div
                      className={`ap-flex ap-flex-center-center ${
                        isTablet ? "ap-w100" : "ap-w50"
                      }`}
                      style={{ padding: "7px" }}
                    >
                      <div
                        className="ap-flex ap-fsm ap-fwmd ap-w100"
                        style={{ marginBottom: "2px" }}
                      >
                        Floor(s)
                      </div>
                      <Input
                        size="medium"
                        style={{ width: "100%" }}
                        placeholder="Enter floor"
                        value={data?.floor}
                        name="floor"
                        type="number"
                        onChange={(e) => setFormData(e)}
                      />
                    </div>
                    <div
                      className={`ap-flex ap-flex-center-center ${
                        isTablet ? "ap-w100" : "ap-w50"
                      }`}
                      style={{ padding: "7px" }}
                    >
                      <div
                        className="ap-flex ap-fsm ap-fwmd ap-w100"
                        style={{ marginBottom: "2px" }}
                      >
                        Built Area
                      </div>
                      <Input
                        size="medium"
                        style={{ width: "100%" }}
                        placeholder="Enter total area"
                        value={data?.area}
                        name="area"
                        type="number"
                        disabled={!(data.width && data.depth && data.floor)}
                        onChange={(e) =>
                          setData((prev) => {
                            prev = JSON.parse(JSON.stringify(prev));
                            prev[`${e.target.name}`] = e.target.value;
                            return prev;
                          })
                        }
                      />
                    </div>
                  </div>
                </>
              )}

              <div
                className={`ap-flex ap-w100 ap-flex-end`}
                style={{ padding: "10px" }}
              >
                <Button
                  type="primary"
                  className="ap-btn-c ap-fwmd btn"
                  size="large"
                  htmlType="submit"
                  disabled={isFormDisabled}
                  loading={load ? <Spin /> : false}
                >
                  Create
                </Button>
              </div>
            </form>
          </div>
          <div className="ap-flex ap-w40" style={{ padding: "15px" }}>
            {/* Card */}
            {data.customer && (
              <div className="ap-flex ap-w100 ap-flex-center-center custom-card2">
                {/* Row */}
                <div className={`ap-flex ap-w100 ap-flex-space-bw head`}>
                  <div className="ap-flex ap-flex-center-center ap-fmd ap-fwmd ">
                    Customer Details
                  </div>
                  <div
                    className="ap-flex ap-flex-center-center edit-btn"
                    onClick={() => {
                      history.push(
                        `/home/ap/client/details/${state.customer.customer?.Id}/edit`
                      );
                    }}
                  >
                    <Icons.Edit />
                  </div>
                </div>
                {/* Row */}
                {!state.customer.loading ? (
                  Object.entries(state.customer?.customer).map(
                    ([key, value], index) =>
                      [
                        "Id",
                        "Name",
                        "Email",
                        "Contact Number",
                        "Currency",
                        "City",
                        "State",
                        "Country",
                      ].includes(key) && (
                        <div
                          className={`ap-flex ap-w100 ap-flex-space-bw`}
                          key={index}
                        >
                          <div
                            className="ap-flex ap-flex-center-center ap-fsm ap-fwmd"
                            style={{ padding: "5px 10px" }}
                          >
                            {key}
                          </div>
                          <div
                            className="ap-flex ap-flex-center-center ap-fsm "
                            style={{ padding: "5px 10px" }}
                          >
                            {value}
                          </div>
                        </div>
                      )
                  )
                ) : (
                  <div className="ap-flex ap-w100" style={{ padding: "8px" }}>
                    <Skeleton active />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default CreateNewProject;
