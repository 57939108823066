import React from 'react'
import {  Spin } from "antd";

export default function SpinLoader() {
  return (
    <div
                style={{
                  minHeight: "20px",
                  // overflow: "auto",
                }}
              >
                <div
                  className="ap-flex ap-w100 ap-flex-center-center"
                  style={{ height: "40px" }}
                >
                  <Spin tip="Fetching Records....." />
                </div>
              </div>
  )
}
