import React, { useState, useEffect } from "react";
import "./Orders.scss";
import { 
    Card,
    Spin,
    Table,
    Tag
} from 'antd';
import {
    Button
} from "@chakra-ui/react";
import { useParams, useHistory } from "react-router-dom";


const Orders = (props) =>{

    const [ current,setCurrent ] = useState({
        page:1,
        size:10,
    })
    const [ tableData, setTableData ] = useState([]) 
    const { client_id } = useParams();
    const history = useHistory();

    const columns = [
        {
            title:<div className="ap-fsm ap-fwmd">Order code</div>,
            dataIndex:"orderCode",
            key:"orderCode",
            width:"200px",
            fixed:"left",
            render:text=><div className="ap-fsm">{text}</div>,
        },
        {
            title:<div className="ap-fsm ap-fwmd">Created at</div>,
            dataIndex:"createdAt",
            key:"createdAt",
            render:text=><div className="ap-fsm">{text}</div>,
        },
        {
            title:<div className="ap-fsm ap-fwmd">Total amount</div>,
            dataIndex:"totalAmount",
            key:"totalAmount",
            render:text=><div className="ap-fsm">{text}</div>,
        },
        {
            title:<div className="ap-fsm ap-fwmd">Created by</div>,
            dataIndex:"createdBy",
            key:"createdBy",
            render:text=><div className="ap-fsm">{text}</div>,
        },
        {
            title:<div className="ap-fsm ap-fwmd">Status</div>,
            dataIndex:"status",
            key:"status",
            render:status=>(
                <Tag color={status === "Active" ? " var( --primary-green)": status==="Cancelled"?"red":"yellow"}>
                    <div className="ap-fsm">{status}</div>
                </Tag>
            )
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <Button 
                    size="sm"
                    className="submit_right1"
                    onClick={()=>{
                        history.push(`/home/ap/order/details/${record.orderCode}`);
                        localStorage.setItem("orderid",record.orderCode)
                    }}
                >
                    Show details 
                </Button>
            ),
            width:"fit-content",
            fixed:"right",
          },
    ].filter(item => !item.hidden);

    useEffect(()=>{
        if(props.state.orders.orders.length > 0){
            setTableData((prev)=>{
                prev = []
                props.state.orders.orders.map((order,orderIndex)=>{
                    prev.push({
                        key:orderIndex,
                        orderCode:order.orderCode,
                        createdAt:order.orderedAt,
                        createdBy:`${order.createdBy?.name} ${order.createdBy?.lastname}`,
                        totalAmount:`${order.currency?.code} ${order.orderTotalAmount}`,
                        status:order.orderStatus,
                    })
                })
                return prev
            })
        }else{
            setTableData([])
        }
    },[props.state])

    useEffect(()=>{
        let offset = current.page === 1 ? 0 : Number(current.page) * 10
        props.orders(`customerID=${client_id}&limit=10&offset=${offset}&orderBy=DESC&orderByKey=ordered_at`)
    },[current])

    return(
        <div className="ap-flex ap-w100" style={{marginTop:"10px"}}>
            <Card 
                size="small" 
                style={{width:"100%"}}
            >
                <div 
                    className="ap-flex ap-w100 ap-fmd ap-fwmd" 
                    style={{marginBottom:'5px'}}
                >
                    Client orders
                </div>
                {!props.state.orders.loading ? <Table 
                    columns={columns} 
                    dataSource={tableData} 
                    scroll={{ x: 1000 }}
                    size='small' 
                    pagination={
                        {
                            current:current.page,
                            pageSize:current.size,
                            position:["bottomRight"],
                            total:props.state.orders.totalRecords,
                            onChange:(data,size)=>{setCurrent({page:data,size:size})},
                            showTotal:(total, range) => `${range[0]}-${range[1]} of ${total} items`,
                        }
                    }
                />:
                <div 
                    className="ap-flex ap-w100 ap-flex-center-center" 
                    style={{height:"300px"}}
                >
                    <Spin tip="Fetching Records....."/>
                </div>}
            </Card>
        </div>    
    ) 
}

export default Orders;