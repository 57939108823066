import { API } from "../../Services";

export const partnerLocations = () =>{
    return (dispatch) =>{
        dispatch({
            type:"FETCH_PARTNER_LOCATIONS",
        })
         API.partnerLocations().then((res)=>{
            let data = JSON.parse(JSON.stringify(res)).data
            if(Array.isArray(data)){
                data = null
            }
            setTimeout(()=>{
                dispatch({
                    type:"RECEIVE_PARTNER_LOCATIONS",
                    payload:data,
                })
            },1000)
        }).catch((err)=>{
            dispatch({
                type:"ERROR_PARTNER_LOCATIONS",
            })
        })
    }
}