import { axiosInstance } from "..";

const updateSiteDetail = async (id, data) => {
  return new Promise(async (resolve, reject) => {
    axiosInstance
      .put(`internal/ConstructionSite/${id}`, data)
      .then((res) => {
        if (res.status === 200) {
          return resolve(res);
        }
        return resolve(res);
      })
      .catch((err) => {
        return reject(err);
      });
  });
};

export default updateSiteDetail;
