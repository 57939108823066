import { API } from "../../Services";

export const walletLedger = (querry) =>{
    return (dispatch) =>{
        dispatch({
            type:"FETCH_LEDGER",
        })
         API.walletLedger(querry).then((res)=>{
            let data = JSON.parse(JSON.stringify(res)).data
            data.ledger.map((ledger,ledgerIndex)=>{
                let dateObj = new Date(Number(ledger.addedOn)*1000)
                ledger.txnDate = `${dateObj.getUTCDate()}-${dateObj.getUTCMonth()+1}-${dateObj.getUTCFullYear()}`
            })
            setTimeout(()=>{
                dispatch({
                    type:"RECEIVE_LEDGER",
                    payload:data,
                })
            },1000)
        }).catch((err)=>{
            dispatch({
                type:"ERROR_LEDGER",
            })
        })
    }
}