import { API } from "../../Services"
export const gatewayList = (query='') =>{
    return (dispatch) =>{
        dispatch({
            type:"FETCH_GATEWAY_LIST",
        })
         API.gatewayList(query).then((res)=>{
            let data = JSON.parse(JSON.stringify(res)).data.gateways
            data.map((g,customerIndex)=>{
                g.value = g.entityGatewayID
                g.label = `${g.name}`
            })
            setTimeout(()=>{
                dispatch({
                    type:"RECEIVE_GATEWAY_LIST",
                    payload:{data:data,totalRecords:res?.data?.totalRecords},
                })
            },1000)
        }).catch((err)=>{
            dispatch({
                type:"ERROR_GATEWAY_LIST",
            })
        })
    }
}