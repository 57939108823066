
import { axiosInstance } from ".."




const trelloAttachmentList = async (projectID,querry) =>{

    return new Promise(async (resolve,reject)=>{
        axiosInstance.get(`/internal/pm/listAttachments/${projectID}?${querry}`).then((res) => { 
          //  console.log(page)
            if(res.status === 200){
                return resolve(res)
            }
            return resolve(res)
        }).catch((err) => {
            return reject(err)
        })
    })
}

export default trelloAttachmentList;