const adminsReducer = (state = {
    loading:false,
    admins:[]
}, action) => {
    switch (action.type) {
        case "FETCH_ADMINS_LIST":
            return { ...state ,loading:true,admins:[]}
        case "RECEIVE_ADMINS_LIST":
            return {...state, admins:[...action.payload],loading:false,}
        case "ERROR_ADMINS_LIST":
            return { ...state ,admins:[],loading:true}
        default:
            return { ...state }
    }
}

export default adminsReducer;