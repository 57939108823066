import OneSignal from 'react-onesignal';
import {onesignal} from "../chatapp/apis/Networks"

export default async function runOneSignal() {
  await OneSignal.init({ appId: onesignal});
  OneSignal.showHttpPrompt();
  OneSignal.getUserId((id)=>{
    localStorage.setItem('player_id',id)
    console.log(id)
    })
  OneSignal.on('subscriptionChange', function(isSubscribed) {
        OneSignal.getUserId((id)=>{
            localStorage.setItem('player_id',id)
            console.log(id)
            }) 
    });  
}