import { axiosInstance } from ".."



const SyncRsformFromMaster = async (projectID) => {
  return new Promise(async (resolve, reject) => {
    axiosInstance
      .post(`/internal/form/syncRsformFromMaster/${projectID}`)
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          return resolve(res);
        }
        return resolve(res);
      })
      .catch((err) => {
        return reject(err);
      });
  });
};
  
  export default SyncRsformFromMaster;