import { axiosInstance } from ".."
const trelloCommentList = async (projectID,limit,page) =>{

        return new Promise(async (resolve,reject)=>{
            console.log(limit);
            let lim =(limit==undefined?'10':limit )
            axiosInstance.get(`/internal/pm/listComments/${projectID}?limit=${lim}&page=${page}`).then((res) => { 
                if(res.status === 200){
                    return resolve(res)
                }
                return resolve(res)
            }).catch((err) => {
                return reject(err)
            })
        })
    }
    
    export default trelloCommentList;