const trelloSearchCardListReducer = (state = {
    totalRecords:0,
    loading:false,
    data:[]
}, action) => {
    switch (action.type) {
        case "FETCH_TRELLO_SEARCH_CARDS":
            return { ...state ,loading:true,data:[],totalRecords:0}
        case "RECEIVE_TRELLO_SEARCH_CARDS":
            return {...state, data:[...action.payload?.data],loading:false,totalRecords:action.payload?.totalRecords}
        case "ERROR_TRELLO_SEARCH_CARDS":
            return { ...state ,data:[],loading:false,totalRecords:0}
        default:
            return { ...state }
    }
}

export default trelloSearchCardListReducer;