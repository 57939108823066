import { API } from "../../Services";

export const trelloCards = (id) =>{
    return (dispatch) =>{
        dispatch({
            type:"FETCH_TRELLO_CARDS",
        })
    
        API.trelloCards(id).then((cardres)=>{
            let cards = []
            cardres = JSON.parse(JSON.stringify(cardres)).data
            cardres.map((c)=>{
                if(c?.idMembers.includes(localStorage.getItem('trelloMeId'))){
                    cards.push(c)
                }
            })
            setTimeout(()=>{
                dispatch({
                    type:"RECEIVE_TRELLO_CARDS",
                    payload:{cards,totalRecords:cards.length},
                })
            },1000)
        }).catch((err)=>{
            dispatch({
                type:"ERROR_TRELLO_CARDS",
            })
        })
    }
}