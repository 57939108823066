const phoneCountryInputReducer = (state = {
    loading:false,
    phoneCountryInput:[]
}, action) => {
    switch (action.type) {
        case "FETCH_PHONE_COUNTRY_INPUT":
            return { ...state ,loading:true,phoneCountryInput:[]}
        case "RECIEVE_PHONE_COUNTRY_INPUT":
            return {...state, phoneCountryInput:[...action.payload],loading:false}
        case "ERROR_PHONE_COUNTRY_INPUT":
            return {...state, phoneCountryInput:[],loading:false}
        default:
            return { ...state }
    }
}

export default phoneCountryInputReducer;