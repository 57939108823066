import { API } from "../../Services"
export const customOrder = (id) =>{
    return (dispatch) =>{
        dispatch({
            type:"FETCH_CUSTOM_ORDER",
        })
         API.customOrder(id).then((res)=>{
            let data = JSON.parse(JSON.stringify(res))
            
            setTimeout(()=>{
                dispatch({
                    type:"RECEIVE_CUSTOM_ORDER",
                    payload:data.data,
                })
            },1000)
        }).catch((err)=>{
            dispatch({
                type:"ERROR_CUSTOM_ORDER",
            })
        })
    }
}