import React, { useEffect, useRef, useState } from "react";
import Quill from "quill";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

import { CommentQuillDiv } from "./trelloStyle";

import "quill-mention/dist/quill.mention.css";
import QuillMention from "quill-mention";

import axios from "axios";
import { Box, Center, Flex, Text } from "@chakra-ui/layout";
import { BaseUrl, commonMediaUpload } from "../../chatapp/apis/Networks";
import { SendRounded } from "@material-ui/icons";
import { ChatIcon } from "@chakra-ui/icons";
import { htmlToMarkdown } from "./components/commentBox/parser";

export default function CommentEditor({ mentionItems, AddTrelloComment }) {
  const editorContainerRef = useRef(null);
  const [content, setContent] = useState("");

  const [editor, setEditor] = useState(null);
  // const [memberList, setMemberList] = useState([]);
  const fileInputRef = useRef(null);

  const handleLinkClick = () => {
    fileInputRef.current.click();
  };

  // useEffect(() => {
  //   if (mentionItems) {
  //     setMemberList(mentionItems.map((m) => ({ id: m?.ID, value: m?.name })));
  //   }
  // }, [mentionItems]);

  useEffect(() => {
    if (editor) {
      editor.on("text-change", () => {
        const content = editor.root.innerHTML;
        console.log("Editor Content:", content);
        setContent(content);
      });
    }
  }, [editor]);

  useEffect(() => {
    if (!editor ) {
      const memberList = mentionItems.map((m) => ({
        id: m?.ID,
        value: m?.name,
      }));
      if (editorContainerRef.current) {
        const newEditor = new Quill(editorContainerRef.current, {
          modules: {
            toolbar: [
              [{ header: [1, 2, 3, 4, 5, 6, false] }],
              ["bold", "italic"],
              // [{ color: [] }, { background: [] }],
              [{ align: [] }],
              [{ list: "ordered" }, { list: "bullet" }],
              ["link"],

              // ['image', "link"],
              // [{ attachment: [] }]
            ],

            mention: {
              allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
              mentionDenotationChars: ["@"],
              source: function (searchTerm, renderList, mentionChar) {
                if (searchTerm.length === 0) {
                  renderList(memberList, searchTerm);
                } else {
                  const matches = memberList.filter((item) =>
                    item.value.toLowerCase().includes(searchTerm.toLowerCase())
                  );
                  renderList(matches, searchTerm);
                }
              },
            },
          },
          placeholder: "    Enter Comment...",
          theme: "snow", // or 'bubble'
        });
        const toolbar = newEditor.getModule("toolbar");
        toolbar.addHandler("link", handleLinkClick);

        setEditor(newEditor);
      }
    }
  }, [editor, mentionItems]);

  const sendComment = () => {
    const commentcontent = content.replace(/<\/?p[^>]*>/g, "");
    console.log("replacedStr", commentcontent);

    const mentionPattern = new RegExp(
      `<span class="mention" data-index="([^"]+)" data-denotation-char="@" data-id="([^"]+)" data-value="([^"]+)">﻿<span contenteditable="false"><span class="ql-mention-denotation-char">@<\/span>[^<]+<\/span>﻿<\/span>`,
      "g"
    );

    const replacedStr = commentcontent
      .toString()
      .replace(
        mentionPattern,
        (_, dataIndex, dataId, dataValue) =>
          ` [${dataValue}](mention://${dataId})`
      );

    console.log(replacedStr);
    let comment = htmlToMarkdown(replacedStr);
    // let comment = convertToMarkdownWithException(replacedStr);
    const modifiedText = comment.replace(/\\/g, "");

    console.log("htmlToMarkdown", modifiedText);

    AddTrelloComment(modifiedText);
    editor.setContents([]);
  };

  const handleFileComment = (files) => {
    if (files.length === 1) {
      const file = files[0];
      let data = new FormData();
      data.append("media", file);
      data.append("entityType", "order");
      data.append("entityId", localStorage.getItem("orderCode"));
      data.append("acl", "public");
      data.append("returnType", "url");

      axios
        .post(BaseUrl + commonMediaUpload, data, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            "X-API-KEY": `${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          const url = response.data.url;
          const link = `[${file.name}](${url})`;

          if (editor) {
            const range = editor.getSelection(true);
            const extension = response.data.url.split(".").pop().toLowerCase();
            const isImage = ["jpg", "jpeg", "png", "gif", "bmp"].includes(
              extension
            );

            if (isImage) {
              const imageElement = `<img src="${url}" alt="${file.name}" width = '350px'> `;
              // editor.clipboard.dangerouslyPasteHTML(imageElement);
              editor.clipboard.dangerouslyPasteHTML(range.index, imageElement);
              editor.setSelection(range.index + 1);
            }

            editor.insertText(
              range.index + 1,
              `${link} \n`,
              {
                color: "#096dd9",
                italic: true,
              },
              true
            );
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      Array.from(files).forEach((file, index) => {
        let data = new FormData();
        data.append("media", file);
        data.append("entityType", "order");
        data.append("entityId", localStorage.getItem("orderCode"));
        data.append("acl", "public");
        data.append("returnType", "url");

        axios
          .post(BaseUrl + commonMediaUpload, data, {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              "X-API-KEY": `${localStorage.getItem("token")}`,
            },
          })
          .then((response) => {
            const url = response.data.url;
            const link = `[${file.name}](${url})`;
            if (editor) {
              const range = editor.getSelection(true);
              const extension = response.data.url
                .split(".")
                .pop()
                .toLowerCase();
              const isImage = ["jpg", "jpeg", "png", "gif", "bmp"].includes(
                extension
              );

              if (isImage) {
                const imageElement = `<img src="${url}" alt="${file.name}"  width = '250px'>`;
                // editor.clipboard.dangerouslyPasteHTML(imageElement);
                editor.clipboard.dangerouslyPasteHTML(
                  range.index,
                  imageElement
                );
                editor.setSelection(range.index + 1);
              }

              editor.insertText(
                range.index + 1,
                `${link} \n`,
                {
                  color: "#096dd9",
                  italic: true,
                },
                true
              );
            }

            // if (editor) {
            //   const range = editor.getSelection(true);
            //   const isLastFile = index === files.length - 1;
            //   const lineEnding = isLastFile ? '  ' : ' , ';
            //   editor.insertText(
            //     range.index,
            //     `${link}${lineEnding}`,
            //     {
            //       color: "#096dd9",
            //       italic: true,
            //     },
            //     true
            //   );
            // }
          })
          .catch((error) => {
            console.log(error);
          });
      });
    }
  };

  return (
    <CommentQuillDiv>
      <Box w="100%" h="auto">
        <Flex mt={3}>
          <Center>
            <ChatIcon />
          </Center>
          <Center ml={2}>
            <Text fontSize="lg" fontWeight={600} color="#000">
              Comment
            </Text>
          </Center>
        </Flex>

        <input
          type="file"
          id="file"
          ref={fileInputRef}
          style={{
            position: "absolute",
            width: 0,
            height: 0,
            visibility: "hidden",
          }}
          onChange={(event) =>
            handleFileComment(Array.from(event.target.files))
          }
          multiple // Enable multiple file selection
        />
        <Flex mt={1}>
          <Box w="100%" h="auto">
            <div
              ref={editorContainerRef}
              style={{ minHeight: "40px", padding: "1rem" }}
            />
          </Box>
        </Flex>
      </Box>

      <Flex mt={10} justifyContent="flex-end" alignItems="center">
        <div
          onClick={(e) => {
            e.preventDefault();
            console.log("onclicked");
            sendComment();
          }}
          style={{ cursor: "pointer" }}
        >
          <SendRounded />
        </div>
      </Flex>
    </CommentQuillDiv>
  );
}
