import React, {useEffect, useState} from "react";
import { useLocation, useHistory } from "react-router-dom";
import { API } from "../../Services";
import { Loader } from "..";

const Middleware = (props) =>{

    const [ loader, setLoader] = useState(false);
    const location = useLocation();
    const history = useHistory();

    useEffect(()=>{
        if(localStorage.getItem("token") !== null){
            if(localStorage.getItem("backurl")){
                history.push(localStorage.getItem("backurl"))
                localStorage.removeItem('backurl');
            }
        }
    },[])

    useEffect(()=>{
        if(!["/","/dashboard"].includes(location.pathname)){
            setLoader((prev)=>!prev)
            API.validateUser().then((res)=>{
                setLoader((prev)=>!prev)
            }).catch((err)=>{
                setLoader((prev)=>!prev)
                localStorage.clear()
                console.log(location)
                localStorage.setItem("backurl",`${location.pathname}${location.search}`);
                history.push("/")
            })
        }
    },[location])

    return <>{loader ?<Loader />:props.children}</>
}

export default Middleware;