import React, { useEffect, useState } from "react";
import { ActionDiv, FDiv, FHeading, FItem } from "./projectCatlogStyle";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Box,
  FormControl,
  FormLabel,
  RadioGroup,
  HStack,
  Radio,
  FormHelperText,
} from "@chakra-ui/react";
import { Input, Checkbox, Slider, Select, Spin } from "antd";
import { actionCreators } from "../../Redux";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { useHistory, useLocation } from "react-router-dom";

export default function LocalCatlogFilter(props) {
  const [formData, setFormData] = useState({});
  const [floorData, setFloorData] = useState({});
  const [isToolTIp, setIsToolTIp] = useState(true);
  const [isProjectSearch, setIsProjectSearch] = useState(true);
  const [stairCaseCheckedList, setStairCaseCheckedList] = useState("");
  const [directionCheckedList, setSDirectionCheckedList] = useState("");
  const [clearFilter, setFilterClear] = useState(props.clearFilter);
  const dispatch = useDispatch();
  const location = useLocation();
  const { trelloCardInfo, masterProjects } = bindActionCreators(
    actionCreators,
    dispatch
  );
  const state = useSelector((state) => state);
  const { Option } = Select;
  const params = new URLSearchParams(location.search);
  const history = useHistory();

  const directions = [
    { value: "EE", label: "East " },
    { value: "WW", label: "West" },
    { value: "NN", label: "North" },
    { value: "SS", label: "South" },
    { value: "NE", label: "North-East" },
    { value: "NW", label: "North-West" },
    { value: "SE", label: "South-East" },
    { value: "SW", label: "South-West" },
  ];
  const stareCaseType = [
    { value: 1, label: "Internal" },
    { value: 2, label: "External" },
  ];
  const kitchenType = [
    { value: "1", label: "Open" },
    { value: "2", label: "Close" },
  ];
  const siteDetailList = [
    { value: "property", label: "Other's Property" },
    { value: "road", label: "Road" },
    { value: "area", label: "Open Area" },
  ];

  const commonAreaType = [
    { value: "1", label: "Living Come Dining " },
    { value: "2", label: "Living room and drawing area separate" },
    { value: "3", label: "Drawing living and dining area separate" },
    { value: "5", label: "Only living area (no dining)" },
  ];
  const checkBoxOptionsfloors = [
    { value: "washArea", label: "Wash Area" },
    { value: "office", label: "Office" },
    { value: "studyRoom", label: "Study Room" },
    { value: "poojaRoom", label: "Pooja Room" },
    { value: "foyer", label: "Foyer" },
    { value: "garden", label: "Garden" },
    { value: "theatre", label: "Theatre" },
    { value: "commonBathroom", label: "Common Bathroom" },
    { value: "gym", label: "gym" },
    { value: "dressingArea", label: "Dressing Area" },
    { value: "Balcony", label: "Balcony" },
    { value: "recreationArea", label: "Recreation Area" },
  ];
  const checkBoxOptions = [
    { value: "washArea", label: "Wash Area" },
    { value: "office", label: "Office" },
    { value: "shop", label: "Shop" },
    { value: "poojaRoom", label: "Pooja Room" },
    { value: "foyer", label: "Foyer" },
    { value: "garden", label: "Garden" },
    { value: "lift", label: "Lift" },
    { value: "commonBathroom", label: "Common Bathroom" },
    { value: "Basement", label: "Basement" },
    { value: "dressingArea", label: "Dressing Area" },
    { value: "Parking", label: "Parking" },
  ];
  useEffect(() => {
    console.log("formData", formData);

    props.setFilterData(formData);
  }, [formData]);

  const handleSearchForm = (event, floorNo) => {
    console.log(event.target.name, event.target.value, "fllorNO", floorNo);
    if (event.target.name.startsWith("setback")) {
      const direction = event.target.name.replace("setback", "").toLowerCase();
      console.log("direction", direction);
      setFormData((prev) => ({
        ...prev,
        setbacks: {
          ...prev.setbacks,
          [direction]: event.target.value,
        },
      }));
    }
    if (floorNo == 0) {
      const floorDataKey = `floor_${floorNo}`;
      if (event.target.name == "otherIncludes") {
        const newOtherIncludes = checkBoxOptions.reduce((acc, option) => {
          acc[option.value] = event.target.value.includes(option.value);
          return acc;
        }, {});
        setFormData((prev) => ({
          ...prev,
          [floorDataKey]: {
            ...prev[floorDataKey],
            ...newOtherIncludes,
            floorNo: floorNo,
          },
        }));
        setFloorData((prev) => ({
          ...prev,
          [floorDataKey]: {
            ...prev[floorDataKey],
            ...newOtherIncludes,
            floorNo: floorNo,
          },
        }));
      } else {
        setFormData((prev) => ({
          ...prev,
          [floorDataKey]: {
            ...prev[floorDataKey],
            [event.target.name]: event.target.value,
            floorNo: floorNo,
          },
        }));
        setFloorData((prev) => ({
          ...prev,
          [floorDataKey]: {
            ...prev[floorDataKey],
            [event.target.name]: event.target.value,
            floorNo: floorNo,
          },
        }));
      }
    } else if (floorNo <= parseInt(formData?.floor)) {
      // Handle dynamic floors
      const floorDataKey = `floor_${floorNo}`;

      if (event.target.name == "otherIncludes") {
        const newOtherIncludes = checkBoxOptions.reduce((acc, option) => {
          acc[option.value] = event.target.value.includes(option.value);
          return acc;
        }, {});
        setFormData((prev) => ({
          ...prev,
          [floorDataKey]: {
            ...prev[floorDataKey],
            ...newOtherIncludes,
            floorNo: floorNo,
          },
        }));
        setFloorData((prev) => ({
          ...prev,
          [floorDataKey]: {
            ...prev[floorDataKey],
            ...newOtherIncludes,
            floorNo: floorNo,
          },
        }));
      } else {
        setFormData((prev) => ({
          ...prev,
          [floorDataKey]: {
            ...prev[floorDataKey],
            [event.target.name]: event.target.value,
            floorNo: floorNo,
          },
        }));
        setFloorData((prev) => ({
          ...prev,
          [floorDataKey]: {
            ...prev[floorDataKey],
            [event.target.name]: event.target.value,
            floorNo: floorNo,
          },
        }));
      }
    } else {
      console.log("name", event.target.name);
      setFormData((prev) => {
        prev = JSON?.parse(JSON?.stringify(prev));
        prev[`${event.target.name}`] = event.target.value;
        return prev;
      });
    }
  };

  useEffect(() => {
    const isObjectEmpty = (obj) => {
      return Object.keys(obj).length === 0 && obj.constructor === Object;
    };

    if (!isObjectEmpty(floorData)) {
      console.log("floorsData", floorData);

      const updatedFloors = [];
      for (let i = 0; i < formData?.floor; i++) {
        if (!formData[`floor_${i}`]) continue;
        updatedFloors.push(formData[`floor_${i}`]);
      }
      if (!formData?.floor && formData?.floor_0) {
        updatedFloors.push(formData[`floor_0`]);
      }
      setFormData((prev) => ({
        ...prev,
        requirementForfloors: JSON?.stringify(updatedFloors),
      }));
    }
  }, [floorData]);

  useEffect(() => {
    if (params.get(`projectID`) && clearFilter !== 1) {
      console.log("hhhhh", formData.projectID);
      trelloCardInfo(params.get(`projectID`));
    }
  }, [params.get(`projectID`)]);

  useEffect(() => {
    if (
      state.trelloCardInfo?.cardInfo &&
      params.get(`projectID`) &&
      isProjectSearch
    ) {
      let info = state.trelloCardInfo?.cardInfo;
      let queryObj = {};
      const qformData = {};

      const addToFormData = (key, value) => {
        if (
          value !== undefined &&
          value !== null &&
          ((typeof value === "string" && value.trim() !== "") ||
            (typeof value !== "string" && value !== ""))
        ) {
          if (key == "staircaseType") {
            qformData[key] = JSON?.stringify([value]);
          } else if (key == "direction") {
            qformData[key] = JSON?.stringify([value]);
          } else {
            qformData[key] = value;
          }
        }
      };

      addToFormData("projectID", info?.projectID);
      addToFormData("width", info?.width);
      addToFormData("floor", info?.floor);
      addToFormData("depth", info?.depth);
      addToFormData("builtUpArea", info?.builtUpArea);

      if (
        Array.isArray(info?.requirementForfloors) &&
        info.requirementForfloors.length > 0
      ) {
        addToFormData(
          "requirementForfloors",
          JSON?.stringify(info.requirementForfloors)
        );
      }

      addToFormData("staircaseType", info?.staircaseType);
      addToFormData("direction", info?.direction);
      addToFormData("setbackFront", info?.setbacks?.front);
      addToFormData("setbackBack", info?.setbacks?.back);
      addToFormData("setbackRight", info?.setbacks?.right);
      addToFormData("setbackLeft", info?.setbacks?.left);
      addToFormData("inFront", info?.inFront);
      addToFormData("inBack", info?.inBack);
      addToFormData("inRight", info?.inRight);
      addToFormData("inLeft", info?.inLeft);
      addToFormData("parkingForBike", info?.parkingForBike);
      addToFormData("parkingForCar", info?.parkingForCar);
      addToFormData("occupants", info?.occupants);
      addToFormData("vastuPriority", info?.vastuPriority);

      if (info?.requirementForfloors && info.requirementForfloors.length > 0) {
        info.requirementForfloors.forEach((floor, i) => {
          addToFormData(`floor_${i}`, floor);
        });
      }
      if (info?.direction) {
        setSDirectionCheckedList([info?.direction]);
      }
      if (info?.staircaseType) {
        setStairCaseCheckedList([info?.staircaseType]);
      }
      const query = Object.entries(qformData)
        .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
        .join("&");
      console.log("query", query);
      let url = query.includes("days")
        ? `limit=50&offset=0&${query}&page=1`
        : `limit=50&offset=0&${query}&page=1&days=30`;
      masterProjects(url);
      setIsProjectSearch(false);
      setFormData(qformData);

      // let querry = "";
      //   Object.entries(formData).map(([key, value], formDataIndex) => {
      //     querry = querry + `&${key}=${value}`;

      //   });

      // let query = Object.entries(queryObj)
      //       .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      //       .join('&');
      // console.log("query.......2", query);
      //     masterProjects(`limit=10&offset=0&${query}&page=1`);
    }
  }, [state.trelloCardInfo?.cardInfo]);

  useEffect(() => {
    console.log("stairCaseCheckedList", stairCaseCheckedList?.length);
    setFormData((prev) => {
      prev = JSON?.parse(JSON?.stringify(prev));
      if (stairCaseCheckedList?.length === 0) {
        console.log("no staircase");
        delete prev[`staircaseType`];
      } else {
        console.log("inside staircase", stairCaseCheckedList);
        prev[`staircaseType`] = JSON?.stringify(stairCaseCheckedList);
      }
      return prev;
    });
  }, [stairCaseCheckedList]);

  useEffect(() => {
    if (directionCheckedList?.length === 0) {
      console.log("no directionCheckedList");
      setFormData((prev) => {
        prev = JSON?.parse(JSON?.stringify(prev));
        delete prev[`direction`];
        return prev;
      });
    } else {
      setFormData((prev) => {
        prev = JSON?.parse(JSON?.stringify(prev));
        prev[`direction`] = JSON?.stringify(directionCheckedList);
        return prev;
      });
    }
  }, [directionCheckedList]);

  const renderFloorFilters = () => {
    const floorCount = parseInt(formData?.floor);
    const floor = [];

    for (let i = 2; i <= floorCount; i++) {
      floor.push(
        <Accordion allowToggle defaultIndex={[1]} key={i - 1}>
          <AccordionItem>
            <h2>
              <AccordionButton
                style={{ fontWeight: "500", color: "var(--primary-green)" }}
              >
                <Box flex="1" textAlign="left">{`${i - 1} Floor`}</Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <FItem>
                <FormControl as="fieldset">
                  <FormLabel className="f-lable">Master Bedrooms</FormLabel>
                  <Input
                    allowClear
                    value={formData[`floor_${i - 1}`]?.masterBedrooms}
                    placeholder="Enter NO of master Bedrooms..."
                    name="masterBedrooms"
                    onChange={(event) => handleSearchForm(event, i - 1)}
                  />
                </FormControl>
              </FItem>
              <FItem>
                <FormControl as="fieldset">
                  <FormLabel className="f-lable">Guest Bedroom</FormLabel>
                  <Input
                    allowClear
                    value={formData[`floor_${i - 1}`]?.guestBedrooms}
                    placeholder="Enter NO of master Bedrooms..."
                    name="guestBedrooms"
                    onChange={(event) => handleSearchForm(event, i - 1)}
                  />
                </FormControl>
              </FItem>
              <FItem>
                <FormControl as="fieldset" className="p-check-group">
                  <FormLabel className="f-lable">Kitchen</FormLabel>
                  <RadioGroup
                    value={
                      formData[`floor_${i - 1}`]?.kitchenType
                        ? formData[`floor_${i - 1}`]?.kitchenType
                        : ""
                    }
                    onChange={(e) => {
                      handleSearchForm(
                        {
                          target: { name: "kitchenType", value: e },
                        },
                        i - 1
                      );
                    }}
                  >
                    <span>
                      {kitchenType?.map((data, index) => {
                        return (
                          <span className="f-radio-button" key={index}>
                            <Radio value={data.value}>{data.label}</Radio>
                          </span>
                        );
                      })}
                    </span>
                  </RadioGroup>
                </FormControl>
              </FItem>
              <FItem>
                <FormControl as="fieldset">
                  <FormLabel className="f-lable">Common Area Type</FormLabel>
                  <RadioGroup
                    value={
                      formData[`floor_${i - 1}`]?.commonAreaType
                        ? formData[`floor_${i - 1}`]?.commonAreaType
                        : ""
                    }
                    onChange={(e) => {
                      handleSearchForm(
                        {
                          target: { name: "commonAreaType", value: e },
                        },
                        i - 1
                      );
                    }}
                  >
                    <span>
                      {commonAreaType.map((data, index) => {
                        return (
                          <span className="f-radio-button" key={index}>
                            <Radio value={data.value}>{data.label}</Radio>
                          </span>
                        );
                      })}
                    </span>
                  </RadioGroup>
                </FormControl>
              </FItem>
              <FItem>
                <FormControl as="fieldset" className="p-check-group">
                  <FormLabel className="f-lable">Other Includes </FormLabel>
                  <Checkbox.Group
                    options={checkBoxOptionsfloors}
                    value={
                      formData[`floor_${i - 1}`]
                        ? Object?.keys(formData[`floor_${i - 1}`]).filter(
                            (key) => formData[`floor_${i - 1}`][key] === true
                          )
                        : []
                    }
                    onChange={(checkedValues) =>
                      handleSearchForm(
                        {
                          target: {
                            name: "otherIncludes",
                            value: checkedValues,
                          },
                        },
                        i - 1
                      )
                    }
                  />{" "}
                </FormControl>
              </FItem>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      );
    }

    return floor;
  };

  return (
    <>
      <FHeading>
        Filters{" "}
        <ActionDiv>
          <span
            style={{
              color: "#7a7ae6",
              fontSize: "16px",
              cursor: "pointer",
              padding: "0 5px",
            }}
            onClick={() => {
              setFormData({});
              setStairCaseCheckedList("");
              setSDirectionCheckedList("");
              props.setFilterClear(1);
            }}
          >
            Clear
          </span>
          <span
            style={{
              color: "#7a7ae6",
              fontSize: "16px",
              cursor: "pointer",
              padding: "0 5px",
            }}
            onClick={() => {
              if (formData?.projectID && !params.get(`projectID`)) {
                params.set("projectID", formData?.projectID);
                history.push({ search: params.toString() });
                setIsProjectSearch(true);
              } else {
                setIsProjectSearch(false);

                props.setCurrent({ page: 1, size: 50 });
                props.setSearchData(true);
              }
              if (!formData?.projectID) {
                Object.entries(formData).map(([key, value], formDataIndex) => {
                  if (key == "projectID" && !value) {
                    params.delete("projectID");
                    history.replace({ search: params.toString() });
                  }
                });
              }
            }}
          >
            Search
          </span>
        </ActionDiv>
      </FHeading>
      <FDiv>
        <Accordion allowToggle defaultIndex={[0]}>
          <AccordionItem>
            <h2>
              <AccordionButton
                style={{ fontWeight: "500", color: "var(--primary-green)" }}
                onClick={(e) => {
                  setIsToolTIp(!isToolTIp);
                }}
              >
                <Box flex="1" textAlign="left">
                  Primary
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <FItem>
                <FormControl
                  as="fieldset"
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                    paddingBottom: "5px",
                  }}
                >
                  <FormLabel className="f-lable" style={{ width: "28%" }}>
                    Project ID
                  </FormLabel>

                  <Input
                    style={{ width: "65%" }}
                    allowClear
                    placeholder="Enter Project Id..."
                    name="projectID"
                    value={formData?.projectID}
                    onChange={(event) => {
                      handleSearchForm(event);
                    }}
                  />
                </FormControl>
              </FItem>
              <FItem>
                <FormControl as="fieldset">
                  <FormLabel className="f-lable">Days</FormLabel>
                  <Select
                    value={formData?.days}
                    onChange={(value) =>
                      handleSearchForm({
                        target: { name: "days", value },
                      })
                    }
                    placeholder="Select Days"
                    style={{ width: "100%" }}
                  >
                    <Option value="7">7 days old</Option>
                    <Option value="30">30 days old</Option>
                    <Option value="all">All</Option>
                  </Select>
                </FormControl>
              </FItem>
              {/* Media Type Filter */}
              <FItem>
                <FormControl as="fieldset">
                  <FormLabel className="f-lable">Preview Image Type</FormLabel>
                  <Select
                    value={formData?.mediaType}
                    onChange={(value) =>
                      handleSearchForm({
                        target: { name: "mediaType", value },
                      })
                    }
                    placeholder="Select Media Type"
                    style={{ width: "100%" }}
                  >
                    <Option value="P">P</Option>
                    <Option value="E">E</Option>
                    <Option value="all">All</Option>
                  </Select>
                </FormControl>
              </FItem>
              <FItem>
                <FormControl as="fieldset">
                  <FormLabel className="f-lable">
                    Width <span style={{ fontStyle: "italic" }}>(in FT)</span>
                  </FormLabel>
                  <span className="f-sub-group">
                    <FormControl as="fieldset" className="f-sub-input-group">
                      <span className="f-inline-field">
                        {/* <FormLabel className="f-lable">min</FormLabel> */}
                        <Input
                          allowClear
                          placeholder="min Width..."
                          name="width"
                          value={formData?.width}
                          onChange={(event) => handleSearchForm(event)}
                        />
                      </span>
                    </FormControl>
                    <span style={{ fontWeight: "800" }}>-</span>
                    <FormControl as="fieldset" className="f-sub-input-group">
                      <span className="f-inline-field">
                        {/* <FormLabel
                          className="f-lable"
                          style={{ paddingLeft: "5px" }}
                        >
                          max
                        </FormLabel> */}
                        <Input
                          allowClear
                          placeholder="max Width..."
                          name="maxwidth"
                          value={formData?.maxwidth}
                          onChange={(event) => handleSearchForm(event)}
                        />
                      </span>
                    </FormControl>
                  </span>
                </FormControl>
              </FItem>
              <FItem>
                <FormControl as="fieldset">
                  <FormLabel className="f-lable">
                    Depth <span style={{ fontStyle: "italic" }}>(in FT)</span>
                  </FormLabel>
                  <span className="f-sub-group">
                    <FormControl as="fieldset" className="f-sub-input-group">
                      <span className="f-inline-field">
                        {/* <FormLabel className="f-lable">min</FormLabel> */}
                        <Input
                          allowClear
                          placeholder="min Depth..."
                          name="depth"
                          value={formData?.depth}
                          onChange={(event) => handleSearchForm(event)}
                        />
                      </span>
                    </FormControl>
                    <span style={{ fontWeight: "800" }}>-</span>

                    <FormControl as="fieldset" className="f-sub-input-group">
                      <span className="f-inline-field">
                        {/* <FormLabel
                          className="f-lable"
                          style={{ paddingLeft: "5px" }}
                        >
                          max
                        </FormLabel> */}
                        <Input
                          allowClear
                          placeholder="max Depth..."
                          name="maxdepth"
                          value={formData?.maxdepth}
                          onChange={(event) => handleSearchForm(event)}
                        />
                      </span>
                    </FormControl>
                  </span>
                </FormControl>
              </FItem>
              <FItem>
                <span className="f-check-group">
                  <FormControl
                    as="fieldset"
                    className="f-input-group"
                    // style={{ marginRight: "2px" }}
                  >
                    <FormLabel className="f-lable">
                      Area{" "}
                      <span style={{ fontStyle: "italic" }}>(in SqFT)</span>
                    </FormLabel>
                    <Input
                      allowClear
                      placeholder="Enter builtUpArea..."
                      name="builtUpArea"
                      value={formData?.builtUpArea}
                      onChange={(event) => handleSearchForm(event)}
                    />
                  </FormControl>

                  <FormControl
                    as="fieldset"
                    className="f-input-group"
                    style={{ marginLeft: "2px" }}
                  >
                    <FormLabel className="f-lable">Floors</FormLabel>
                    <Input
                      allowClear
                      placeholder="Enter Floors..."
                      name="floor"
                      value={formData?.floor}
                      onChange={(event) => handleSearchForm(event)}
                    />
                  </FormControl>
                </span>
              </FItem>
              <FItem>
                <FormControl as="fieldset" className="p-check-group">
                  <FormLabel className="f-lable">Stair Case Type</FormLabel>
                  <Checkbox.Group
                    options={stareCaseType}
                    value={stairCaseCheckedList}
                    onChange={(e) => {
                      setStairCaseCheckedList(e);

                      console.log(e);
                    }}
                  />{" "}
                </FormControl>
              </FItem>
              <FItem>
                <FormControl as="fieldset" className="p-check-group">
                  <FormLabel className="f-lable">Direction</FormLabel>
                  <Checkbox.Group
                    options={directions}
                    value={directionCheckedList}
                    onChange={(e) => {
                      setSDirectionCheckedList(e);

                      console.log(e);
                    }}
                  />{" "}
                </FormControl>
              </FItem>
              <FItem>
                <FormLabel className="f-lable" style={{ marginTop: "1rem" }}>
                  Set Backs
                </FormLabel>

                <FItem>
                  <span className="f-check-group">
                    <FormControl
                      as="fieldset"
                      className="f-input-group"
                      // style={{ marginRight: "2px" }}
                    >
                      <FormLabel className="f-lable">Front </FormLabel>
                      <Input
                        allowClear
                        placeholder="Enter Setback Front..."
                        name="setbackFront"
                        value={formData?.setbackFront}
                        onChange={(event) => handleSearchForm(event)}
                      />
                    </FormControl>

                    <FormControl
                      as="fieldset"
                      className="f-input-group"
                      style={{ marginLeft: "2px" }}
                    >
                      <FormLabel className="f-lable">Back</FormLabel>
                      <Input
                        allowClear
                        placeholder="Enter Setback Back..."
                        name="setbackBack"
                        value={formData?.setbackBack}
                        onChange={(event) => handleSearchForm(event)}
                      />
                    </FormControl>
                  </span>
                </FItem>
                <FItem>
                  <span className="f-check-group">
                    <FormControl
                      as="fieldset"
                      className="f-input-group"
                      // style={{ marginRight: "2px" }}
                    >
                      <FormLabel className="f-lable">Right </FormLabel>
                      <Input
                        allowClear
                        placeholder="Enter Setback Right..."
                        name="setbackRight"
                        value={formData?.setbackRight}
                        onChange={(event) => handleSearchForm(event)}
                      />
                    </FormControl>

                    <FormControl
                      as="fieldset"
                      className="f-input-group"
                      style={{ marginLeft: "2px" }}
                    >
                      <FormLabel className="f-lable">Left</FormLabel>
                      <Input
                        allowClear
                        placeholder="Enter Setback Left..."
                        name="setbackLeft"
                        value={formData?.setbackLeft}
                        onChange={(event) => handleSearchForm(event)}
                      />
                    </FormControl>
                  </span>
                </FItem>
              </FItem>
              <FItem>
                <FormLabel className="f-lable" style={{ marginTop: "1rem" }}>
                  Site Details
                </FormLabel>

                <FItem>
                  <span className="f-check-group">
                    <FormControl
                      as="fieldset"
                      className="f-input-group"
                      // style={{ marginRight: "2px" }}
                    >
                      <FormLabel className="f-lable">Front </FormLabel>
                      <Select
                        size="medium"
                        labelInValue
                        placeholder="Select in site Front"
                        style={{ width: "100%" }}
                        value={formData?.inFront}
                        onChange={(d) =>
                          handleSearchForm({
                            target: { name: "inFront", value: d.value },
                          })
                        }
                        notFoundContent={
                          state.customers.loading && (
                            <div className="ap-w100 ap-flex ap-flex-center">
                              <Spin size="small" />
                            </div>
                          )
                        }
                        filterOption={false}
                      >
                        {siteDetailList.map((direction, directionIndex) => (
                          <Option value={direction.value} key={directionIndex}>
                            {direction.label}
                          </Option>
                        ))}
                      </Select>
                    </FormControl>

                    <FormControl
                      as="fieldset"
                      className="f-input-group"
                      style={{ marginLeft: "2px" }}
                    >
                      <FormLabel className="f-lable">Back</FormLabel>
                      <Select
                        size="medium"
                        labelInValue
                        placeholder="Select in site Back"
                        style={{ width: "100%" }}
                        value={formData?.inBack}
                        onChange={(d) =>
                          handleSearchForm({
                            target: { name: "inBack", value: d.value },
                          })
                        }
                        notFoundContent={
                          state.customers.loading && (
                            <div className="ap-w100 ap-flex ap-flex-center">
                              <Spin size="small" />
                            </div>
                          )
                        }
                        filterOption={false}
                      >
                        {siteDetailList.map((direction, directionIndex) => (
                          <Option value={direction.value} key={directionIndex}>
                            {direction.label}
                          </Option>
                        ))}
                      </Select>
                    </FormControl>
                  </span>
                </FItem>
                <FItem>
                  <span className="f-check-group">
                    <FormControl
                      as="fieldset"
                      className="f-input-group"
                      // style={{ marginRight: "2px" }}
                    >
                      <FormLabel className="f-lable">Right </FormLabel>
                      <Select
                        size="medium"
                        labelInValue
                        placeholder="Select in site Right"
                        style={{ width: "100%" }}
                        value={formData?.inRight}
                        onChange={(d) =>
                          handleSearchForm({
                            target: { name: "inRight", value: d.value },
                          })
                        }
                        notFoundContent={
                          state.customers.loading && (
                            <div className="ap-w100 ap-flex ap-flex-center">
                              <Spin size="small" />
                            </div>
                          )
                        }
                        filterOption={false}
                      >
                        {siteDetailList.map((direction, directionIndex) => (
                          <Option value={direction.value} key={directionIndex}>
                            {direction.label}
                          </Option>
                        ))}
                      </Select>
                    </FormControl>

                    <FormControl
                      as="fieldset"
                      className="f-input-group"
                      style={{ marginLeft: "2px" }}
                    >
                      <FormLabel className="f-lable">Left</FormLabel>
                      <Select
                        size="medium"
                        labelInValue
                        placeholder="Select in site Left"
                        style={{ width: "100%" }}
                        value={formData?.inLeft}
                        onChange={(d) =>
                          handleSearchForm({
                            target: { name: "inLeft", value: d.value },
                          })
                        }
                        notFoundContent={
                          state.customers.loading && (
                            <div className="ap-w100 ap-flex ap-flex-center">
                              <Spin size="small" />
                            </div>
                          )
                        }
                        filterOption={false}
                      >
                        {siteDetailList.map((direction, directionIndex) => (
                          <Option value={direction.value} key={directionIndex}>
                            {direction.label}
                          </Option>
                        ))}
                      </Select>
                    </FormControl>
                  </span>
                </FItem>
              </FItem>
              <FItem>
                <FormLabel className="f-lable" style={{ marginTop: "1rem" }}>
                  Parking
                </FormLabel>

                <FItem>
                  <span className="f-check-group">
                    <FormControl
                      as="fieldset"
                      className="f-input-group"
                      // style={{ marginRight: "2px" }}
                    >
                      <FormLabel className="f-lable">Bikes </FormLabel>
                      <Input
                        allowClear
                        placeholder="Enter No of Bikes..."
                        name="parkingForBike"
                        value={formData?.parkingForBike}
                        onChange={(event) => handleSearchForm(event)}
                      />
                    </FormControl>

                    <FormControl
                      as="fieldset"
                      className="f-input-group"
                      style={{ marginLeft: "2px" }}
                    >
                      <FormLabel className="f-lable">Cars</FormLabel>
                      <Input
                        allowClear
                        placeholder="Enter No of Cars..."
                        name="parkingForCar"
                        value={formData?.parkingForCar}
                        onChange={(event) => handleSearchForm(event)}
                      />
                    </FormControl>
                  </span>
                </FItem>
              </FItem>
              <FItem>
                <span className="f-check-group">
                  <FormControl
                    as="fieldset"
                    // className="f-input-group"
                    // style={{ marginRight: "2px" }}
                  >
                    <FormLabel className="f-lable">
                      {" "}
                      No. of occupant in the house
                    </FormLabel>
                    <Input
                      allowClear
                      placeholder="Enter No of people..."
                      name="occupants"
                      value={formData?.occupants}
                      onChange={(event) => handleSearchForm(event)}
                    />
                  </FormControl>
                </span>
              </FItem>
              <FItem>
                <span className="f-check-group">
                  <FormControl
                    as="fieldset"
                    // className="f-input-group"
                    // style={{ marginRight: "2px" }}
                  >
                    <FormLabel className="f-lable"> Vastu Priority</FormLabel>

                    <Slider
                      min={0}
                      max={10}
                      value={formData?.vastuPriority}
                      onChange={(e) => {
                        console.log("vastuPriority", e);
                        handleSearchForm({
                          target: { name: "vastuPriority", value: e },
                        });
                      }}
                    />
                  </FormControl>
                </span>
              </FItem>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
        <Accordion allowToggle defaultIndex={[0]}>
          <AccordionItem>
            <h2>
              <AccordionButton
                style={{ fontWeight: "500", color: "var(--primary-green)" }}
              >
                <Box flex="1" textAlign="left">
                  Ground Floor
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <FItem>
                <FormControl as="fieldset">
                  <FormLabel className="f-lable">Master Bedrooms</FormLabel>
                  <Input
                    allowClear
                    value={formData[`floor_0`]?.masterBedrooms}
                    placeholder="Enter NO of master Bedrooms..."
                    name="masterBedrooms"
                    onChange={(event) => handleSearchForm(event, 0)}
                  />
                </FormControl>
              </FItem>
              <FItem>
                <FormControl as="fieldset">
                  <FormLabel className="f-lable">Guest Bedroom</FormLabel>
                  <Input
                    allowClear
                    value={formData[`floor_0`]?.guestBedrooms}
                    placeholder="Enter NO of master Bedrooms..."
                    name="guestBedrooms"
                    onChange={(event) => handleSearchForm(event, 0)}
                  />
                </FormControl>
              </FItem>
              <FItem>
                <FormControl as="fieldset" className="p-check-group">
                  <FormLabel className="f-lable">Kitchen</FormLabel>
                  <RadioGroup
                    value={
                      formData[`floor_0`]?.kitchen
                        ? formData[`floor_0`]?.kitchen
                        : ""
                    }
                    onChange={(e) => {
                      handleSearchForm(
                        {
                          target: { name: "kitchen", value: e },
                        },
                        0
                      );
                    }}
                  >
                    <span>
                      {kitchenType?.map((data, index) => {
                        return (
                          <span className="f-radio-button" key={index}>
                            <Radio value={data.value}>{data.label}</Radio>
                          </span>
                        );
                      })}
                    </span>
                  </RadioGroup>
                </FormControl>
              </FItem>
              <FItem>
                <FormControl as="fieldset">
                  <FormLabel className="f-lable">Common Area Type</FormLabel>
                  <RadioGroup
                    value={
                      formData[`floor_0`]?.commonAreaType
                        ? formData[`floor_0`]?.commonAreaType
                        : ""
                    }
                    onChange={(e) => {
                      handleSearchForm(
                        {
                          target: { name: "commonAreaType", value: e },
                        },
                        0
                      );
                    }}
                  >
                    <span>
                      {commonAreaType.map((data, index) => {
                        return (
                          <span className="f-radio-button" key={index}>
                            <Radio value={data.value}>{data.label}</Radio>
                          </span>
                        );
                      })}
                    </span>
                  </RadioGroup>
                </FormControl>
              </FItem>
              <FItem>
                <FormControl as="fieldset" className="p-check-group">
                  <FormLabel className="f-lable">Other Includes </FormLabel>
                  <Checkbox.Group
                    options={checkBoxOptions}
                    value={
                      formData[`floor_0`]
                        ? Object?.keys(formData[`floor_0`])?.filter(
                            (key) => formData[`floor_0`][key] === true
                          )
                        : []
                    }
                    onChange={(checkedValues) =>
                      handleSearchForm(
                        {
                          target: {
                            name: "otherIncludes",
                            value: checkedValues,
                          },
                        },
                        0
                      )
                    }
                  />{" "}
                </FormControl>
              </FItem>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
        {parseInt(formData?.floor) > 1 && <> {renderFloorFilters()}</>}
      </FDiv>
    </>
  );
}
