import { API } from "../../Services";

export const trelloSearchCardList = (memberId,boardId,listId,searchin,quarry,page,dueDate,orderby,order) =>{
    return (dispatch) =>{
        dispatch({
            type:"FETCH_TRELLO_SEARCH_CARDS",
        })
    
        API.trelloSearchCardList(memberId,boardId,listId,searchin,quarry,page,dueDate,orderby,order).then((res)=>{
            let data = JSON.parse(JSON.stringify(res)).data.projects
            let totalData = JSON.parse(JSON.stringify(res)).data?.totalProjects

            setTimeout(()=>{
                dispatch({
                    type:"RECEIVE_TRELLO_SEARCH_CARDS",
                    payload:{data,totalRecords:totalData}
                })
            },1000)
            // setTimeout(()=>{
            //     dispatch({
            //         type:"RECEIVE_TRELLO_SEARCH_CARDS",
            //         payload:{cards,totalRecords:cards.length},
            //     })
            // },1000)
        }).catch((err)=>{
            dispatch({
                type:"ERROR_TRELLO_SEARCH_CARDS",
            })
        })
    }
}