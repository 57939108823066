import {axiosInstance} from '..'


const trelloCardUpdate = async (projectID,data) =>{

  return new Promise(async (resolve,reject)=>{
      axiosInstance.post(`/internal/pm/updateProject/${projectID}`,data).then((res) => { 
          console.log(res)
          if(res.status === 200){
              return resolve(res)
          }
          return resolve(res)
      }).catch((err) => {
          return reject(err)
      })
  })
}

export default trelloCardUpdate;