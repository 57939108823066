import { API } from "../../Services";

export const BrowserFileList = (id,query = "") => {
  return (dispatch) => {
    dispatch({
      type: "FETCH_BROWSER_FILE_LIST",
    });

    API.browserFile(id,query)
      .then((res) => {
        console.log(res);
        let data = JSON.parse(JSON.stringify(res)).data.data;

        setTimeout(() => {
          dispatch({
            type: "RECEIVE_BROWSER_FILE_LIST",
            payload: { data: data, totalRecords: res.data.totalRecords },
          });
        }, 1000);
      })
      .catch((err) => {
        let data = JSON.parse(JSON.stringify(err?.response?.data?.message));

        dispatch({
          type: "ERROR_BROWSER_FILE_LIST",
          payload: data,
        });
      });
  };
};

