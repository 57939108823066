import React, { useEffect, useState } from "react";
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
} from "@chakra-ui/form-control";
import toast from "react-hot-toast";
import {
    ModalHeader,
    ModalBody,
    Grid,
    GridItem,
    Button,
    Input,
    CloseButton,
  } from "@chakra-ui/react";

import { useSelector, useDispatch } from "react-redux";
import { actionCreators } from "../../Redux";
import { bindActionCreators } from "redux";

import { Formik, Form, Field, ErrorMessage } from "formik";
import { API } from "../../Services";
import { useParams } from "react-router-dom";


export default function CurrentService(props) {
    const state = useSelector((state) => state);
    const dispatch = useDispatch();
    const { id } = useParams();
    const {trelloCardInfo,trelloCommentList} = bindActionCreators(
      actionCreators,
      dispatch
    );
    useEffect(() => {
      //  trelloCardInfo(id);
    
      }, []);
      const UpdateCurrentService = (values) => {
        let data = {
          currentService:values.currentService ,
        //   dueDate:values.dueDate,
        //   projectSource:""
        };
        var formData = new FormData();
        for (var i in data) {
          formData.append(i, data[i]);
        }
        //  setLoad((prev)=>!prev)
        API.trelloCardUpdate(id, formData)
          .then((res) => {
            //  setLoad((prev)=>!prev)
           // trelloList(id);
            trelloCardInfo(id);
            trelloCommentList(id, 20, 1);

            toast.success("Updated successfully.", {
              position: "top-right",
            });
            console.log(res);
            props.onClose();
          })
          .catch((err) => {
            // setLoad((prev)=>!prev)
            toast.error(err.message, {
              position: "top-right",
            });
            props.onClose();
          });
      };
  return (
    <div>
    <Formik
      initialValues={{
        currentService: "",
      }}
      onSubmit={(values) => {props.SetCommentPage(1);UpdateCurrentService(values)}}
    >
      {({ isSubmitting, values, setFieldValue }) => (
        <Form>
          <Field name="name">
            {({ field, form }) => (
              <>
              <FormControl>
                 
                 <span>Cureent Service: {state?.trelloCardInfo?.cardInfo?.currentService}</span>
                 <br />
                 <br />

               </FormControl>
                <FormControl>
                 
                  <FormLabel htmlFor="text-input">Enter Current Service</FormLabel>
                  <Input
                          type="text"
                          id="currentService"
                          name="currentService"
                          placeholder="Enter Service"
                          onChange={(e) => {
                            setFieldValue(
                              "currentService",
                              e.target.value
                            );
                            setFieldValue(
                                "dueDate",
                                state?.trelloCardInfo?.cardInfo?.dueDate
                              );
                          }}
                        />
                  <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                </FormControl>
              
              </>
            )}
          </Field>
          <Button
          //  disabled={!selectedListId || !selectedBoardId}
            mt={4}
            className="submit_right1"
            isLoading={isSubmitting}
            type="submit"
          >
            Update
          </Button>
        </Form>
      )}
    </Formik>
  </div>
  )
}
