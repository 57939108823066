import { axiosInstance } from ".."

const setApprovalStatus = async (projectID,data) =>{

    return new Promise(async (resolve,reject)=>{
        axiosInstance.post(`/internal/pm/updateAttachmentsApprovalStatus/${projectID}`,data).then((res) => { 
            if(res.status === 200){
                return resolve(res)
            }
            return resolve(res)
        }).catch((err) => {
            return reject(err)
        })
    })
}

export default setApprovalStatus;