import React, { useState, useEffect } from "react";
import {
  useDisclosure,
  Button,
  Checkbox,
  Text,
  Textarea,
  Collapse,
  IconButton,
  Tooltip,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  CloseButton,
  ModalBody,
  ModalHeader,
  Modal,
  ModalFooter,
  ModalOverlay,
  ModalContent,
} from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../Redux";
import { useParams } from "react-router-dom";
import { Select } from "antd";

import { LoaderDiv, TButton } from "./trelloStyle";
import { Icons } from "../../Utils";
import { API } from "../../Services";
import toast from "react-hot-toast";
import { SpinLoader } from "../../Components";
import UploadAttachment from "./Attachements/UploadAttachment";

const TrelloCheckList = (props) => {
  const state = useSelector((state) => state);
  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useDisclosure();
  const {
    isOpen: isMemberOpen,
    onOpen: onMemberOpen,
    onClose: onMemberClose,
  } = useDisclosure();
  const {
    isOpen: isUploadOpen,
    onOpen: onUploadOpen,
    onClose: onUploadClose,
  } = useDisclosure();
  const [uploadData, setUploadData] = useState({});
  const dispatch = useDispatch();
  const [taskName, setTaskName] = useState("");
  const [isLoader, setLoader] = useState(false);
  const [isAssigned, setAssigned] = useState(false);
  const { trelloCheckList, trelloCardInfo, trelloCommentList } =
    bindActionCreators(actionCreators, dispatch);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isAddItem, setIsAddItem] = useState(null);
  const [selectedItem, setSelectedItem] = useState("");
  const [isAddList, setIsAddList] = useState(false);
  const [editItem, setEditItem] = useState(null);
  const { id } = useParams();
  const { Option } = Select;
  const [selectedMemberId, setSelectedMemberId] = useState(null);

  useEffect(() => {
    trelloCheckList(id);

    // if(isOpen && state.trelloCardInfo.cardInfo?.idChecklists?.length > 0){
    //     trelloCheckList(id)
    // }
  }, [isOpen]);

  const AssignMember = (ID, itemId) => {
    setAssigned(true);
    console.log(selectedMemberId);
    let d = { checkItemID: itemId, memberID: selectedMemberId.value };

    console.log(d);
    var formData = new FormData();
    for (var i in d) {
      formData.append(i, d[i]);
    }
    API.AssignMemberToChekItem(ID, formData)
      .then((res) => {
        trelloCheckList(id);
        trelloCommentList(id, 20, 1);
        setSelectedItem("");
        setEditItem(null);
        setAssigned(false);
        onMemberClose();

        toast.success(res.data.message, {
          position: "top-right",
        });
      })
      .catch((err) => {
        toast.error("Oops! There is error assigning member", {
          position: "top-right",
        });
        onMemberClose();
        setAssigned(false);
      });
  };

  const handleUpdateCheck = (type, e, ID) => {
    setLoader(true);
    let d = {};
    let checkItemID = "";
    if (type === "task") {
      d.checkItemStatus = e?.target?.checked ? "complete" : "incomplete";
      d.checkItemID = e?.target?.id;
    } else if (type === "taskName") {
      d.checkItemName = taskName;
      d.checkItemID = e?.target?.id;
      d.checkItemStatus = "incomplete";
    }
    console.log(d);
    var formData = new FormData();
    for (var i in d) {
      formData.append(i, d[i]);
    }
    API.updateTrelloCheckItem(ID, formData)
      .then((res) => {
        trelloCheckList(id);
        trelloCommentList(id, 20, 1);

        setEditItem(null);
        setLoader(false);
      })
      .catch((err) => {
        toast.error("Oops! There is error from trello", {
          position: "top-right",
        });
        setLoader(false);
      });
  };
  const handleAddItem = (clId, pos) => {
    setLoader(true);
    let data = {
      checkItemName: taskName,
    };
    API.createCheckList(clId, data)
      .then((res) => {
        trelloCheckList(id);
        trelloCommentList(id, 20, 1);

        setIsAddItem(null);
        setLoader(false);
      })
      .catch((res) => {
        toast.error("Oops! There is error from trello", {
          position: "top-right",
        });
        setLoader(false);
      });
  };

  const handleRemoveItem = (clId, itemId) => {
    setLoader(true);
    let data = {
      checkItemID: itemId,
    };
    var formData = new FormData();
    for (var i in data) {
      formData.append(i, data[i]);
    }
    API.removeCheckListItem(clId, formData)
      .then((res) => {
        trelloCheckList(id);
        trelloCommentList(id, 20, 1);
        setSelectedItem("");
        setIsAddItem(null);
        setLoader(false);
      })
      .catch((res) => {
        toast.error("Oops! There is error from trello", {
          position: "top-right",
        });
        setLoader(false);
      });
  };

  const handleNewCheckList = () => {
    setLoader(true);

    API.createCheckList(id, { name: taskName })
      .then((res) => {
        trelloCheckList(id);
        trelloCommentList(id, 20, 1);

        setIsAddList(false);
        setLoader(false);
      })
      .catch((err) => {
        toast.error("Oops! There is error from trello", {
          position: "top-right",
        });
        setLoader(false);
      });
  };

  return (
    <>
      {/* <TButton
            onClick={() => {
                onOpen();
            }}
        >
            <Icons.CheckBox /> Trello check list
        </TButton> */}
      {/* <Modal 
            isOpen = { isOpen }
            onClose = { onClose }
            size = "3xl"
        >
            <ModalOverlay/>
            <ModalContent>

                <ModalHeader>
                    <div className="ap-flex ap-w100 ap-flex-space-bw">
                            <Text
                                color = "gray.600"
                                fontSize = "2xl"
                            >
                                Trello check list
                            </Text>
                    </div>
                </ModalHeader> */}

      <div>
        {isLoader == true ? (
          <div
            className="ap-flex ap-w100 ap-flex-center"
            style={{ alignItems: "center", height: "300px" }}
          >
            <LoaderDiv>
              <SpinLoader />
            </LoaderDiv>
          </div>
        ) : (
          <>
            <Collapse in={isAddList} mt={5} mb={5}>
              <div className="ap-flex ap-w100">
                <Textarea
                  placeholder="Please enter an item"
                  size="sm"
                  mt={2}
                  mb={2}
                  w="100%"
                  value={taskName}
                  onChange={(e) => {
                    props.SetCommentPage(1);
                    setTaskName(e.target.value);
                  }}
                />
              </div>
              <div className="ap-flex ap-w100" style={{ marginTop: "10px" }}>
                <Button
                  className="submit_right_outline"
                  variant="outline"
                  size="sm"
                  onClick={() => {
                    props.SetCommentPage(1);
                    handleNewCheckList();
                  }}
                >
                  {!isAddItem ? "Add an item" : "Add"}
                </Button>
                <Button
                  colorScheme="red"
                  variant="ghost"
                  size="sm"
                  ml={3}
                  onClick={() => setIsAddList((prev) => !prev)}
                >
                  Close
                </Button>
              </div>
            </Collapse>

            {state.trelloCardInfo.cardInfo?.idChecklists !== null ? (
              <>
                {state.trelloCheckList?.list?.map((cl, clIndex) => (
                  <>
                    <div className="ap-flex ap-w100">
                      <div className="ap-flex ap-flex-center-center ">
                        <Text
                          color="gray.500"
                          fontSize="2xl"
                          casing="capitalize"
                        >
                          {cl?.name}
                        </Text>
                      </div>
                    </div>
                    {cl?.services?.map((cItem, cItemIndex) => (
                      <div
                        className={`ap-flex ap-w100 ${
                          editItem === cItem?.ID ? "" : "ap-flex-space-bw"
                        }`}
                        key={cItemIndex}
                      >
                        <div
                          className={`ap-flex ap-flex-center-center ${
                            editItem === cItem?.ID ? "" : "ap-w100"
                          }`}
                          style={
                            editItem === cItem?.id
                              ? {
                                  alignItems: "flex-start",
                                  justifyContent: "flex-start",
                                }
                              : {
                                  alignItems: "flex-start",
                                  width: editItem === cItem?.ID ? "" : "55%",
                                }
                          }
                        >
                          <Checkbox
                            size="lg"
                            colorScheme="green"
                            isChecked={cItem?.status === "complete"}
                            ml={5}
                            mb={0}
                            name={cItem?.name}
                            id={cItem?.ID}
                            onChange={(e) => {
                              props.SetCommentPage(1);
                              handleUpdateCheck("task", e, cl?.ID);
                            }}
                          >
                            {editItem !== cItem?.ID && (
                              <div
                                style={{ minWidth: "185px", display: "flex" }}
                              >
                                <div>
                                  <Text
                                    color="gray.500"
                                    fontSize="md"
                                    width="100%"
                                    mb={0}
                                  >
                                    {cItem?.name}
                                  </Text>
                                </div>
                                {/* <IconButton 
                                    colorScheme = "green" 
                                    size = "sm"
                                    variant = "ghost" 
                                    icon = { <Icons.UserFilled /> }
                                    onClick = { () => {setEditItem(cItem?.ID);setTaskName(cItem?.name);setIsAddList(false)}}
                                /> */}
                              </div>
                            )}
                          </Checkbox>
                        </div>
                        {editItem === cItem?.ID && (
                          <div className={`ap-flex ap-flex-center ap-w100`}>
                            <Collapse
                              style={{ width: "100%" }}
                              in={editItem === cItem?.ID}
                              mt={5}
                              mb={5}
                            >
                              <div className="ap-flex ap-w100">
                                <Textarea
                                  placeholder="Please enter an item"
                                  size="sm"
                                  ml={2}
                                  mb={2}
                                  w="100%"
                                  value={taskName}
                                  onChange={(e) => {
                                    setTaskName(e.target.value);
                                  }}
                                />
                              </div>
                              <div className="ap-flex ap-w100">
                                <Button
                                  className="submit_right_outline"
                                  variant="outline"
                                  size="sm"
                                  ml={2}
                                  id={cItem?.ID}
                                  onClick={(e) => {
                                    props.SetCommentPage(1);
                                    handleUpdateCheck("taskName", e, cl?.ID);
                                  }}
                                >
                                  Update
                                </Button>
                                <Button
                                  colorScheme="red"
                                  variant="ghost"
                                  size="sm"
                                  ml={1}
                                  onClick={() => setEditItem(null)}
                                >
                                  Close
                                </Button>
                              </div>
                            </Collapse>
                          </div>
                        )}
                        <div
                          className="ap-flex"
                          style={{
                            width: "30%",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          {cItem?.assignedTo?.name && (
                            <Text
                              color="#000000c4"
                              fontSize="sm"
                              width="100%"
                              mb={0}
                              style={{
                                width: "auto",

                                fontStyle: "italic",
                              }}
                            >
                              Assigned To: {cItem?.assignedTo?.name}
                            </Text>
                          )}
                        </div>
                        <div className="ap-flex " style={{ width: "15%" }}>
                          <div className="ap-flex ap-flex-center-center">
                            <Tooltip
                              label="Assign member"
                              size="sm"
                              fontSize="sm"
                              color="white"
                              bg="gray.400"
                              placement="right"
                            >
                              <IconButton
                                colorScheme="green"
                                size="sm"
                                variant="ghost"
                                icon={<Icons.UserFilled />}
                                onClick={() => {
                                  onMemberOpen();
                                  setSelectedItem({
                                    id: cl?.ID,
                                    itemId: cItem?.ID,
                                    name: cItem?.name,
                                  });
                                }}
                              />
                            </Tooltip>
                          </div>
                          {editItem !== cItem?.ID && (
                            <div className="ap-flex ap-flex-center-center">
                              <Tooltip
                                label="Edit item"
                                size="sm"
                                fontSize="sm"
                                color="white"
                                bg="gray.400"
                                placement="right"
                              >
                                <IconButton
                                  colorScheme="green"
                                  size="sm"
                                  variant="ghost"
                                  icon={<Icons.Edit />}
                                  onClick={() => {
                                    setEditItem(cItem?.ID);
                                    setTaskName(cItem?.name);
                                    setIsAddList(false);
                                  }}
                                />
                              </Tooltip>
                            </div>
                          )}
                             <Tooltip
                            label="Upload attachments"
                            size="sm"
                            fontSize="sm"
                            color="white"
                            bg="gray.400"
                            placement="right"
                          >
                            <IconButton
                              colorScheme="green"
                              size="sm"
                              variant="ghost"
                              icon={<Icons.ArrowUpward />}
                              onClick={() => {
                                onUploadOpen();
                                setUploadData({
                                  serviceID: cItem?.ID,
                                  orderID: cl?.ID,
                                });
                                // console.log('cItem?.ID',cItem?.ID,' ','cItem?.name',cl?.ID)
                              }}
                            />
                          </Tooltip>
                          <div className="ap-flex ap-flex-center-center">
                            <Tooltip
                              label="Edit item"
                              size="sm"
                              fontSize="sm"
                              color="white"
                              bg="gray.400"
                              placement="right"
                            >
                              <IconButton
                                colorScheme="green"
                                size="sm"
                                variant="ghost"
                                icon={<Icons.Delete />}
                                onClick={() => {
                                  onDeleteOpen();
                                  setSelectedItem({
                                    id: cl?.ID,
                                    itemId: cItem?.ID,
                                    name: cItem?.name,
                                  });
                                }}
                              />
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                    ))}
                    <Collapse
                      in={isAddItem === cl?.ID && editItem === null}
                      mt={5}
                      mb={5}
                    >
                      <div className="ap-flex ap-w100">
                        <Textarea
                          placeholder="Please enter an item"
                          size="sm"
                          ml={5}
                          mt={2}
                          mb={2}
                          w="100%"
                          value={taskName}
                          onChange={(e) => {
                            setTaskName(e.target.value);
                          }}
                        />
                      </div>
                    </Collapse>
                    {editItem === null && (
                      <div
                        className="ap-flex ap-w100"
                        style={{ marginTop: "10px" }}
                      >
                        <Button
                          className="submit_right_outline"
                          variant="outline"
                          size="sm"
                          ml={5}
                          onClick={() => {
                            if (!isAddItem) {
                              setIsAddItem(cl?.ID);
                              setTaskName("");
                              setIsAddList(false);
                            } else {
                              props.SetCommentPage(1);
                              handleAddItem(cl?.ID, cl?.pos);
                            }
                          }}
                        >
                          {isAddItem !== cl?.ID ? "Add an item" : "Add"}
                        </Button>
                        {isAddItem !== null && isAddItem === cl?.ID && (
                          <Button
                            colorScheme="red"
                            variant="ghost"
                            size="sm"
                            ml={3}
                            onClick={() => setIsAddItem((prev) => !prev)}
                          >
                            Close
                          </Button>
                        )}
                      </div>
                    )}
                  </>
                ))}
              </>
            ) : (
              <div className="ap-flex ap-w100 ap-flex-center-center">
                <Text as="i" size="3xl">
                  There is no checklist, please create new one.
                </Text>
              </div>
            )}
          </>
        )}
      </div>

      <Modal isOpen={isMemberOpen} onClose={onMemberClose}>
        <ModalOverlay />
        <ModalContent maxH="38rem" minH="15rem">
          <ModalHeader
            style={{ justifyContent: "space-between", display: "flex" }}
          >
            Assign {selectedItem?.name}
            <div>
              <CloseButton onClick={onMemberClose} />
            </div>
          </ModalHeader>
          <ModalBody>
            <span className="ap-flex ap-fsm ap-fwmd ap-w100">
              Select Member
            </span>

            <Select
              showSearch
              size="middle"
              labelInValue
              placeholder="Please Select Member name"
              onChange={(data) => {
                setSelectedMemberId(data);
              }}
              style={{ width: "100%" }}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {/* <Option value={""}>Please select Member name</Option> */}
              {state.trelloCardInfo?.cardInfo?.members?.map((member) => (
                <Option value={member?.ID} key={member?.ID}>
                  {member?.name}
                </Option>
              ))}
            </Select>
          </ModalBody>
          <ModalFooter>
            <Button
              className="submit_right1"
              isLoading={isAssigned}
              onClick={() => {
                AssignMember(selectedItem?.id, selectedItem?.itemId);
              }}
              size="sm"
              style={{ margin: "0 5px" }}
            >
              Assign
            </Button>
            <Button
              className="submit_right1"
              onClick={onMemberClose}
              size="sm"
              style={{ margin: "0 5px" }}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <AlertDialog isOpen={isDeleteOpen} onClose={onDeleteClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Checklist Item
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You want to Delete {selectedItem?.name}!!
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button onClick={onDeleteClose} size="sm">
                Cancel
              </Button>
              <Button
                colorScheme="red"
                size="sm"
                onClick={() => {
                  props.SetCommentPage(1);
                  handleRemoveItem(selectedItem?.id, selectedItem?.itemId);
                  onDeleteClose();
                }}
                ml={3}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <Modal isOpen={isUploadOpen} onClose={onUploadClose} size="4xl">
        <ModalOverlay />
        <ModalContent maxH="38rem" minH="30rem" minW="30rem">
          <UploadAttachment
            onClose={onUploadClose}
            isCheklist={true}
            orderID={uploadData?.orderID}
            serviceID={uploadData?.serviceID}
          />
        </ModalContent>
      </Modal>
    </>
  );
};

export default TrelloCheckList;
