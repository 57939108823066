import { axiosInstance } from ".."

const customers = async (query='') =>{

    return new Promise(async (resolve,reject)=>{
        axiosInstance.get(`internal/customers?${query}&downline=1&show=1`).then((res) => { 
            if(res.status === 200){
                return resolve(res)
            }
            return resolve(res)
        }).catch((err) => {
            return reject(err)
        })
    })
}
export default customers;