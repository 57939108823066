import { axiosInstance } from "..";

const browserFileDownload = async (projectID, querry) => {
  return new Promise(async (resolve, reject) => {
    axiosInstance
      .get(`/internal/OfficeData/download/${projectID}?${querry}`)
      // .get(
      //   `internal/OfficeData/download/PRJ202301300001`
      // )
      .then((res) => {
        //  console.log(res);
        if (res.status === 200) {
          return resolve(res);
        }
        return resolve(res);
      })
      .catch((err) => {
        return reject(err);
      });
  });
};

export default browserFileDownload;
