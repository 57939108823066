const trelloCardInfoReducer = (state = {
    loading:false,
    cardInfo:null
}, action) => {
    switch (action.type) {
        case "FETCH_TRELLO_CARD_INFO":
            return { ...state ,loading:true,cardInfo:null}
        case "RECEIVE_TRELLO_CARD_INFO":
            return {...state, cardInfo:{...action.payload},loading:false}
        case "ERROR_TRELLO_CARD_INFO":
            return { ...state ,cardInfo:null,loading:false}
        default:
            return { ...state }
    }
}

export default trelloCardInfoReducer;