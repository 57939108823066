import React, { useEffect } from "react";
import "./Home.scss";
import { Icons } from "../../Utils"
import { useHistory } from "react-router-dom";
import { API } from "../../Services";

const Home = (props) =>{

    const history = useHistory();
    return <></>
}

export default Home;