const BrowserFileActionLogsReducer = (
  state = {
    loading: false,
    browserLogs: [],
    totalRecords: 0,
    msg: "",
  },
  action
) => {
  switch (action.type) {
    case "FETCH_BROWSER_FILE_LOGS_LIST":
      return {
        ...state,
        loading: true,
        browserLogs: [],
        totalRecords: 0,
        msg: "",
      };
    case "RECEIVE_BROWSER_FILE_LOGS_LIST":
      return {
        ...state,
        browserLogs: [...action?.payload?.data],
        loading: false,
        totalRecords: action.payload.totalRecords,
        msg: "",
      };
    case "ERROR_BROWSER_FILE_LOGS_LIST":
      return {
        ...state,
        browserLogs: [],
        loading: true,
        totalRecords: 0,
        msg: action.payload,
      };
    default:
      return { ...state };
  }
};

export default BrowserFileActionLogsReducer;
