import { axiosInstance } from ".."




const addTrelloAttachment = async (projectID,data) =>{

  return new Promise(async (resolve,reject)=>{
      axiosInstance.post(
        `/internal/pm/addAttachment/${projectID}`,data
              )
              .then((res) => {
          console.log(res)
          if(res.status === 200){
              return resolve(res)
          }
          return resolve(res)
      }).catch((err) => {
          return reject(err)
      })
  })
}

export default addTrelloAttachment;