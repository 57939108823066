import { API } from "../../Services";

export const masterProjects = (querry,blank=false) =>{
    return (dispatch) =>{
        dispatch({
            type:"FETCH_MASTER_PROJECTS",
        })
        if(!blank){
            API.masterProjects(querry).then((res)=>{
                let data = JSON.parse(JSON.stringify(res)).data
                data.projects = data.projects ? data.projects : []
                data.msg = data.projects ? `${data.totalRecords} records are fetched are successfully !`:"0 record is fetched"
                setTimeout(()=>{
                    dispatch({
                        type:"RECEIVE_MASTER_PROJECTS",
                        payload:data,
                    })
                },1000)
            }).catch((err)=>{
                let data = JSON.parse(JSON.stringify(err?.response?.data?.message))
                dispatch({
                    type:"ERROR_MASTER_PROJECTS",
                    payload:data

                })
            })
        }else{
            dispatch({
                type:"ERROR_MASTER_PROJECTS_BLANK",
            })
        }
         
    }
}