
const transectionListReducer = (state = {
    loading:false,
    transections:[],totalRecords:0
}, action) => {
    switch (action.type) {
        case "FETCH_TRANSECTION_LIST":
            return { ...state ,loading:true,transections:[],totalRecords:0}
        case "RECEIVE_TRANSECTION_LIST":
            return {...state, transections:[...action.payload.data],loading:false,totalRecords:action.payload.totalRecords}
        case "ERROR_TRANSECTION_LIST":
            return { ...state ,transections:[],loading:true,totalRecords:0}
        default:
            return { ...state }
    }
}

export default transectionListReducer;