
const partnerleadlistReducer = (state = {
    loading:false,
    leads:[],totalRecords:0,msg:''
}, action) => {
    switch (action.type) {
        case "FETCH_PARTNER_LEAD_LIST":
            return { ...state ,loading:true,leads:[],totalRecords:0,msg:''}
        case "RECEIVE_PARTNER_LEAD_LIST":
            return {...state, leads:[...action.payload.data],loading:false,totalRecords:action.payload.totalRecords,msg:''}
        case "ERROR_PARTNER_LEAD_LIST":
            return { ...state ,leads:[],loading:false,totalRecords:0,msg:action.payload}
        default:
            return { ...state }
    }
}

export default partnerleadlistReducer;