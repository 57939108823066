import React, { useEffect, useState } from "react";
import "./MasterProjectsList.scss";
import { Collapse,Input,Select,Button,Spin,Table,Alert } from 'antd';
import {Icons} from "../../../Utils";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../../Redux";
import { useHistory } from "react-router-dom";
import { API } from "../../../Services";

const MasterProjectsList = (props) =>{

    const [formData,setFormData] = useState({})
    const [tableData,setTableData] = useState([]);
    const [current,setCurrent] =useState({
        page:1,
        size:10,
    });
    const [alert,setAlert] = useState({
        status:false
    });
    const { Panel } = Collapse;
    const { Option } = Select;
    const state = useSelector((state)=>state)
    const dispatch = useDispatch();
    const history = useHistory();
    const { masterProjects } = bindActionCreators(actionCreators,dispatch)


    const directions = [
        { value: "EE", label: "East " },
        { value: "WW", label: "West" },
        { value: "NN", label: "North" },
        { value: "SS", label: "South" },
        { value: "NE", label: "North-East" },
        { value: "NW", label: "North-West" },
        { value: "SE", label: "South-East" },
        { value: "SW", label: "South-West" },
    ];

    const propertyType = [
        { value:"RESD", label:"Residential" },
        { value:"COMM", label:"Commercial" },
    ]

    const columns = [
       

        {
            title:<div className="ap-fsm ap-fwmd">Project Id</div>,
            dataIndex:"id",
            key:"id",
            render:text=><div className="ap-fsm">{text}</div>,

        },
        {
            title:<div className="ap-fsm ap-fwmd">Project Name</div>,
            dataIndex:"Project_Name",
            key:"Project_Name",
            render:text=><div className="ap-fsm">{text}</div>,
            width:"300px",
            fixed:"left",

        },
        {
            title:<div className="ap-fsm ap-fwmd">MMH Code</div>,
            dataIndex:"MMH_Code",
            key:"MMH_Code",
            render:text=><div className="ap-fsm">{text}</div>,
           
        },
        {
            title:<div className="ap-fsm ap-fwmd">Trello Link</div>,
            dataIndex:"Trello_Link",
            key:"Trello_Link",
            render:text=><div className="ap-fsm">
                <a href={text} className="link-btn ap-btn-c" target="_blank">Go to trello</a>
            </div>

        },
        {
            title:<div className="ap-fsm ap-fwmd">Folder Link</div>,
            dataIndex:"Folder_Link",
            key:"Folder_Link",
            render:text=><div className="ap-fsm">
                 <a href={text} target="_blank" className="link-btn ap-btn-c" style={{textOverflow:"ellipse"}}>Go to folder</a>
            </div>

        },
    ]

    const handleSearchForm = (event) =>{
        setFormData((prev)=>{
            prev = JSON.parse(JSON.stringify(prev))
            prev[`${event.target.name}`] = event.target.value
            return prev
        })
    }

    useEffect(()=>{
        if(Object.keys(formData).length > 0){
            let querry=''
            Object.entries(formData).map(([key,value],formDataIndex)=>{
                querry = querry + `&${key}=${value}`
            })
            console.log("current---",current)
            let offset = current.page === 1 ? 0 : Number(current.page) * 10 - 10
            masterProjects(`limit=${current.size}&offset=${offset}${querry}&page=${current.page}`)
        }
    },[current])

    useEffect(()=>{
        if(state.masterProjects.projects !== null){
            setTableData((prev)=>{
                prev = []
                state.masterProjects.projects.map((project,projectIndex)=>{
                    prev.push({
                        key:projectIndex,
                        id:project._id,
                        MMH_Code:project.projectID,
                        Project_Name:(project.projectName||"NA"),
                        Trello_Link:project.Trello_Link,
                        Folder_Link:project.folderLink,
                    })
                })
                return prev
            })
        }

        if(state.masterProjects.msg !== ''){
            setAlert((prev)=>{
                prev = JSON.parse(JSON.stringify(prev))
                prev.status = true
                prev.type = "success"
                prev.message = "Records Fetched!"
                prev.description = `${state.masterProjects.msg}`
                return prev
            })
            setTimeout(()=>{
                setAlert((prev)=>{
                    prev = {}
                    prev.status = false
                    return prev
                })
            },3000)
        }
    },[state])

    const callRecords = (event) =>{
        setCurrent((prev)=>{
            prev = JSON.parse(JSON.stringify(prev))
            prev.page = prev.size === event.payload.size ? event.payload.page:1
            prev.size = event.payload.size
            return prev
        })
    }

    return <>
        {alert.status && <Alert
                message={alert?.message}
                description={alert?.description}
                type={alert?.type}
                showIcon
                closable
            />}
    <form className="ap-flex ap-flex-center-center ap-w100 master-projects" onSubmit={(event)=>{
            event.preventDefault();
            callRecords({payload:{page:1,size:10}})
        }}
        >
        <Collapse defaultActiveKey={["1"]} className="ap-w100">
            <Panel header={<span className="ap-fsm ap-fwmd">Primary Search</span>} key="1">
                <div className="ap-flex ap-w100">
                    <div className="ap-flex ap-flex-center-center ap-w25 ap-p5">
                        <div className="ap-flex ap-w100 ap-fsm ap-fwmd">MMH Code</div>
                        <Input 
                            size="middle"
                            name="projectID"
                            value={formData?.projectID}
                            placeholder="Enter MMH Code"
                            onChange={(event)=>handleSearchForm(event)}
                        />
                    </div>
                    <div className="ap-flex ap-flex-center-center ap-w25 ap-p5">
                        <div className="ap-flex ap-w100 ap-fsm ap-fwmd">Width</div>
                        <Input 
                            size="middle"
                            name="width"
                            type="number"
                            value={formData?.width}
                            placeholder="Enter Width"
                            onChange={(event)=>handleSearchForm(event)}
                        />
                    </div>
                    <div className="ap-flex ap-flex-center-center ap-w25 ap-p5">
                        <div className="ap-flex ap-w100 ap-fsm ap-fwmd">Depth</div>
                        <Input 
                            size="middle"
                            name="depth"
                            type="number"
                            value={formData?.depth}
                            placeholder="Enter Depth"
                            onChange={(event)=>handleSearchForm(event)}
                        />
                    </div>
                    <div className="ap-flex ap-flex-center-center ap-w25 ap-p5">
                        <div className="ap-flex ap-w100 ap-fsm ap-fwmd">Floors</div>
                        <Input 
                            size="middle"
                            type="number"
                            name="floors"
                            value={formData?.floors}
                            placeholder="Enter Floors"
                            onChange={(event)=>handleSearchForm(event)}
                        />
                    </div>
                </div>
                <div className="ap-flex ap-w100">
                    <div className="ap-flex ap-flex-center-center ap-w25 ap-p5">
                        <div className="ap-flex ap-w100 ap-fsm ap-fwmd">Direction</div>
                        <Select 
                            className="ap-w100"
                            size="middle"
                            placeholder="Select Direction"
                            showSearch
                            value={formData?.direction}
                            onChange={(data)=>{handleSearchForm({target:{name:"direction",value:data}})}}
                        >
                            {directions.map((direction,directionIndex)=>(
                                <Option value={direction.value} key={directionIndex}>{direction.label}</Option>
                            ))}
                        </Select>
                    </div>
                    <div className="ap-flex ap-flex-center-center ap-w25 ap-p5">
                        <div className="ap-flex ap-w100 ap-fsm ap-fwmd">Property Type</div>
                        <Select 
                            className="ap-w100"
                            size="middle"
                            placeholder="Select Direction"
                            showSearch
                            value={formData?.Propertytype}
                            onChange={(data)=>{handleSearchForm({target:{name:"Propertytype",value:data}})}}
                        >
                            {propertyType.map((property,propertyIndex)=>(
                                <Option value={property.value} key={propertyIndex}>{property.label}</Option>
                            ))}
                        </Select>
                    </div>
                    <div className="ap-flex ap-flex-center-center ap-w25 ap-p5">
                        <div className="ap-flex ap-w100 ap-fsm ap-fwmd">Staircase Type</div>
                        <Select 
                            className="ap-w100"
                            size="middle"
                            placeholder="Select Direction"
                            showSearch
                            value={formData?.staircaseType}
                            onChange={(data)=>{handleSearchForm({target:{name:"staircaseType",value:data}})}}
                        >
                           <Option value="internal">Internal</Option>
                           <Option value="external">External</Option>
                        </Select>
                    </div>
                    <div className="ap-flex ap-flex-center-center ap-w25 ap-p5">
                        <div className="ap-flex ap-w100 ap-fsm ap-fwmd">Parking</div>
                        <Select 
                            className="ap-w100"
                            size="middle"
                            placeholder="Select Direction"
                            showSearch
                            value={formData?.Parking}
                            onChange={(data)=>{handleSearchForm({target:{name:"Parking",value:data}})}}
                        >
                           <Option value="Yes">Yes</Option>
                           <Option value="No">No</Option>
                        </Select>
                    </div>
                </div>
                <div className="ap-flex ap-w100">
                    <div className="ap-flex ap-flex-center-center ap-w25 ap-p5">
                        <div className="ap-flex ap-w100 ap-fsm ap-fwmd">Vaastu</div>
                        <Select 
                            className="ap-w100"
                            size="middle"
                            placeholder="Select Direction"
                            showSearch
                            value={formData?.Vaastu}
                            onChange={(data)=>{handleSearchForm({target:{name:"Vaastu",value:data}})}}
                        >
                           <Option value="Yes">Yes</Option>
                           <Option value="No">No</Option>
                        </Select>
                    </div>
                    <div className="ap-flex ap-flex-center-center ap-w25 ap-p5">
                        <div className="ap-flex ap-w100 ap-fsm ap-fwmd">Setback Front</div>
                        <Input 
                            size="middle"
                            type="number"
                            name="setbacksFront"
                            value={formData?.setbacksFront}
                            placeholder="Enter setback front"
                            onChange={(event)=>handleSearchForm(event)}
                        />
                    </div>
                    <div className="ap-flex ap-flex-center-center ap-w25 ap-p5">
                        <div className="ap-flex ap-w100 ap-fsm ap-fwmd">Setback Back</div>
                        <Input 
                            size="middle"
                            type="number"
                            name="setbacksBack"
                            value={formData?.setbacksBack}
                            placeholder="Enter setback back"
                            onChange={(event)=>handleSearchForm(event)}
                        />
                    </div>
                    <div className="ap-flex ap-flex-center-center ap-w25 ap-p5">
                        <div className="ap-flex ap-w100 ap-fsm ap-fwmd">Setback Right</div>
                        <Input 
                            size="middle"
                            type="number"
                            name="setbacksRight"
                            value={formData?.setbacksRight}
                            placeholder="Enter setback right"
                            onChange={(event)=>handleSearchForm(event)}
                        />
                    </div>
                </div>
                <div className="ap-flex ap-w100">
                    <div className="ap-flex ap-flex-center-center ap-w25 ap-p5">
                        <div className="ap-flex ap-w100 ap-fsm ap-fwmd">Setback Left</div>
                        <Input 
                            size="middle"
                            type="number"
                            name="setbacksLeft"
                            value={formData?.setbacksLeft}
                            placeholder="Enter setback left"
                            onChange={(event)=>handleSearchForm(event)}
                        />
                    </div>
                    <div className="ap-flex ap-flex-center-center ap-w25 ap-p5">
                        <div className="ap-flex ap-w100 ap-fsm ap-fwmd">Kitchen Type</div>
                        <Select 
                            className="ap-w100"
                            size="middle"
                            placeholder="Select Direction"
                            showSearch
                            value={formData?.kitchen}
                            onChange={(data)=>{handleSearchForm({target:{name:"kitchen",value:data}})}}
                        >
                           <Option value="open">Open</Option>
                           <Option value="close">Close</Option>
                        </Select>
                    </div>
                </div>
            </Panel>
            <Panel header={<span className="ap-fsm ap-fwmd">Ground Floor</span>} key="2">
                <div className="ap-flex ap-w100">
                    <div className="ap-flex ap-flex-center-center ap-w25 ap-p5">
                        <div className="ap-flex ap-w100 ap-fsm ap-fwmd">Master Bed</div>
                        <Input 
                            size="middle"
                            type="number"
                            name="G_MasterBed"
                            value={formData?.G_MasterBed}
                            placeholder="Enter master bed"
                            onChange={(event)=>handleSearchForm(event)}
                        />
                    </div>
                     <div className="ap-flex ap-flex-center-center ap-w25 ap-p5">
                        <div className="ap-flex ap-w100 ap-fsm ap-fwmd">Dressing</div>
                        <Input 
                            size="middle"
                            type="number"
                            name="G_Dressing"
                            value={formData?.G_Dressing}
                            placeholder="Enter dressing"
                            onChange={(event)=>handleSearchForm(event)}
                        />
                    </div>
                    <div className="ap-flex ap-flex-center-center ap-w25 ap-p5">
                        <div className="ap-flex ap-w100 ap-fsm ap-fwmd">Guest Room</div>
                        <Input 
                            size="middle"
                            type="number"
                            name="G_GuestRoom"
                            value={formData?.G_GuestRoom}
                            placeholder="Enter guest room"
                            onChange={(event)=>handleSearchForm(event)}
                        />
                    </div>
                    <div className="ap-flex ap-flex-center-center ap-w25 ap-p5">
                        <div className="ap-flex ap-w100 ap-fsm ap-fwmd">Kitchen</div>
                        <Input 
                            size="middle"
                            type="number"
                            name="G_Kitchen"
                            value={formData?.G_Kitchen}
                            placeholder="Enter kitchen"
                            onChange={(event)=>handleSearchForm(event)}
                        />
                    </div>
                </div>
                <div className="ap-flex ap-w100">
                    <div className="ap-flex ap-flex-center-center ap-w25 ap-p5">
                        <div className="ap-flex ap-w100 ap-fsm ap-fwmd">Wash</div>
                        <Input 
                            size="middle"
                            type="number"
                            name="G_Wash"
                            value={formData?.G_Wash}
                            placeholder="Enter wash"
                            onChange={(event)=>handleSearchForm(event)}
                        />
                    </div>
                    <div className="ap-flex ap-flex-center-center ap-w25 ap-p5">
                        <div className="ap-flex ap-w100 ap-fsm ap-fwmd">Drawing</div>
                        <Input 
                            size="middle"
                            type="number"
                            name="G_Drawing"
                            value={formData?.G_Drawing}
                            placeholder="Enter drawing"
                            onChange={(event)=>handleSearchForm(event)}
                        />
                    </div>
                    <div className="ap-flex ap-flex-center-center ap-w25 ap-p5">
                        <div className="ap-flex ap-w100 ap-fsm ap-fwmd">Living</div>
                        <Input 
                            size="middle"
                            type="number"
                            name="G_Living"
                            value={formData?.G_Living}
                            placeholder="Enter living"
                            onChange={(event)=>handleSearchForm(event)}
                        />
                    </div>
                    <div className="ap-flex ap-flex-center-center ap-w25 ap-p5">
                        <div className="ap-flex ap-w100 ap-fsm ap-fwmd">Dining</div>
                        <Input 
                            size="middle"
                            type="number"
                            name="G_Dining"
                            value={formData?.G_Dining}
                            placeholder="Enter dining"
                            onChange={(event)=>handleSearchForm(event)}
                        />
                    </div>
                </div>
                <div className="ap-flex ap-w100">
                    <div className="ap-flex ap-flex-center-center ap-w25 ap-p5">
                        <div className="ap-flex ap-w100 ap-fsm ap-fwmd">Toilet</div>
                        <Input 
                            size="middle"
                            type="number"
                            value={formData?.G_C_Toilet}
                            name="G_C_Toilet"
                            placeholder="Enter no. of toilet"
                            onChange={(event)=>handleSearchForm(event)}
                        />
                    </div>
                </div>
            </Panel>
            <Panel header={<span className="ap-fsm ap-fwmd">1st Floor</span>} key="3">
                <div className="ap-flex ap-w100">
                    <div className="ap-flex ap-flex-center-center ap-w25 ap-p5">
                        <div className="ap-flex ap-w100 ap-fsm ap-fwmd">Master Bed</div>
                        <Input 
                            size="middle"
                            type="number"
                            name="F_MasterBed"
                            value={formData?.F_MasterBed}
                            placeholder="Enter master bed"
                            onChange={(event)=>handleSearchForm(event)}
                        />
                    </div>
                     <div className="ap-flex ap-flex-center-center ap-w25 ap-p5">
                        <div className="ap-flex ap-w100 ap-fsm ap-fwmd">Dressing</div>
                        <Input 
                            size="middle"
                            type="number"
                            name="F_Dressing"
                            value={formData?.F_Dressing}
                            placeholder="Enter dressing"
                            onChange={(event)=>handleSearchForm(event)}
                        />
                    </div>
                    <div className="ap-flex ap-flex-center-center ap-w25 ap-p5">
                        <div className="ap-flex ap-w100 ap-fsm ap-fwmd">Guest Room</div>
                        <Input 
                            size="middle"
                            type="number"
                            value={formData?.F_GuestRoom}
                            name="F_GuestRoom"
                            placeholder="Enter guest room"
                            onChange={(event)=>handleSearchForm(event)}
                        />
                    </div>
                    <div className="ap-flex ap-flex-center-center ap-w25 ap-p5">
                        <div className="ap-flex ap-w100 ap-fsm ap-fwmd">Kitchen</div>
                        <Input 
                            size="middle"
                            type="number"
                            name="F_Kitchen"
                            value={formData?.F_Kitchen}
                            placeholder="Enter kitchen"
                            onChange={(event)=>handleSearchForm(event)}
                        />
                    </div>
                </div>
                <div className="ap-flex ap-w100">
                    <div className="ap-flex ap-flex-center-center ap-w25 ap-p5">
                        <div className="ap-flex ap-w100 ap-fsm ap-fwmd">Wash</div>
                        <Input 
                            size="middle"
                            type="number"
                            name="F_Wash"
                            value={formData?.F_Wash}
                            placeholder="Enter wash"
                            onChange={(event)=>handleSearchForm(event)}
                        />
                    </div>
                    <div className="ap-flex ap-flex-center-center ap-w25 ap-p5">
                        <div className="ap-flex ap-w100 ap-fsm ap-fwmd">Drawing</div>
                        <Input 
                            size="middle"
                            type="number"
                            name="F_Drawing"
                            value={formData?.F_Drawing}
                            placeholder="Enter drawing"
                            onChange={(event)=>handleSearchForm(event)}
                        />
                    </div>
                    <div className="ap-flex ap-flex-center-center ap-w25 ap-p5">
                        <div className="ap-flex ap-w100 ap-fsm ap-fwmd">Living</div>
                        <Input 
                            size="middle"
                            type="number"
                            name="F_Living"
                            value={formData?.F_Living}
                            placeholder="Enter living"
                            onChange={(event)=>handleSearchForm(event)}
                        />
                    </div>
                    <div className="ap-flex ap-flex-center-center ap-w25 ap-p5">
                        <div className="ap-flex ap-w100 ap-fsm ap-fwmd">Dining</div>
                        <Input 
                            size="middle"
                            type="number"
                            name="F_Dining"
                            value={formData?.F_Dining}
                            placeholder="Enter dining"
                            onChange={(event)=>handleSearchForm(event)}
                        />
                    </div>
                </div>
                <div className="ap-flex ap-w100">
                    <div className="ap-flex ap-flex-center-center ap-w25 ap-p5">
                        <div className="ap-flex ap-w100 ap-fsm ap-fwmd">Toilet</div>
                        <Input 
                            size="middle"
                            type="number"
                            name="F_C_Toilet"
                            value={formData?.F_C_Toilet}
                            placeholder="Enter no. of toilet"
                            onChange={(event)=>handleSearchForm(event)}
                        />
                    </div>
                    <div className="ap-flex ap-flex-center-center ap-w25 ap-p5">
                        <div className="ap-flex ap-w100 ap-fsm ap-fwmd">Balcony</div>
                        <Input 
                            size="middle"
                            type="number"
                            name="F_Balcony"
                            value={formData?.F_Balcony}
                            placeholder="Enter Balcony"
                            onChange={(event)=>handleSearchForm(event)}
                        />
                    </div>
                </div>
            </Panel>
            <Panel header={<span className="ap-fsm ap-fwmd">2nd Floor</span>} key="4">
                <div className="ap-flex ap-w100">
                    <div className="ap-flex ap-flex-center-center ap-w25 ap-p5">
                        <div className="ap-flex ap-w100 ap-fsm ap-fwmd">Master Bed</div>
                        <Input 
                            size="middle"
                            type="number"
                            name="S_MasterBed"
                            value={formData?.S_MasterBed}
                            placeholder="Enter master bed"
                            onChange={(event)=>handleSearchForm(event)}
                        />
                    </div>
                     <div className="ap-flex ap-flex-center-center ap-w25 ap-p5">
                        <div className="ap-flex ap-w100 ap-fsm ap-fwmd">Dressing</div>
                        <Input 
                            size="middle"
                            type="number"
                            name="S_Dressing"
                            value={formData?.S_Dressing}
                            placeholder="Enter dressing"
                            onChange={(event)=>handleSearchForm(event)}
                        />
                    </div>
                    <div className="ap-flex ap-flex-center-center ap-w25 ap-p5">
                        <div className="ap-flex ap-w100 ap-fsm ap-fwmd">Guest Room</div>
                        <Input 
                            size="middle"
                            type="number"
                            name="S_GuestRoom"
                            value={formData?.S_GuestRoom}
                            placeholder="Enter guest room"
                            onChange={(event)=>handleSearchForm(event)}
                        />
                    </div>
                    <div className="ap-flex ap-flex-center-center ap-w25 ap-p5">
                        <div className="ap-flex ap-w100 ap-fsm ap-fwmd">Kitchen</div>
                        <Input 
                            size="middle"
                            type="number"
                            name="S_Kitchen"
                            value={formData?.S_Kitchen}
                            placeholder="Enter kitchen"
                            onChange={(event)=>handleSearchForm(event)}
                        />
                    </div>
                </div>
                <div className="ap-flex ap-w100">
                    <div className="ap-flex ap-flex-center-center ap-w25 ap-p5">
                        <div className="ap-flex ap-w100 ap-fsm ap-fwmd">Wash</div>
                        <Input 
                            size="middle"
                            type="number"
                            name="S_Wash"
                            value={formData?.S_Wash}
                            placeholder="Enter wash"
                            onChange={(event)=>handleSearchForm(event)}
                        />
                    </div>
                    <div className="ap-flex ap-flex-center-center ap-w25 ap-p5">
                        <div className="ap-flex ap-w100 ap-fsm ap-fwmd">Drawing</div>
                        <Input 
                            size="middle"
                            type="number"
                            name="S_Drawing"
                            value={formData?.S_Drawing}
                            placeholder="Enter drawing"
                            onChange={(event)=>handleSearchForm(event)}
                        />
                    </div>
                    <div className="ap-flex ap-flex-center-center ap-w25 ap-p5">
                        <div className="ap-flex ap-w100 ap-fsm ap-fwmd">Living</div>
                        <Input 
                            size="middle"
                            type="number"
                            name="S_Living"
                            value={formData?.S_Living}
                            placeholder="Enter living"
                            onChange={(event)=>handleSearchForm(event)}
                        />
                    </div>
                    <div className="ap-flex ap-flex-center-center ap-w25 ap-p5">
                        <div className="ap-flex ap-w100 ap-fsm ap-fwmd">Dining</div>
                        <Input 
                            size="middle"
                            type="number"
                            name="S_Dining"
                            value={formData?.S_Dining}
                            placeholder="Enter dining"
                            onChange={(event)=>handleSearchForm(event)}
                        />
                    </div>
                </div>
                <div className="ap-flex ap-w100">
                    <div className="ap-flex ap-flex-center-center ap-w25 ap-p5">
                        <div className="ap-flex ap-w100 ap-fsm ap-fwmd">Toilet</div>
                        <Input 
                            size="middle"
                            type="number"
                            name="S_C_Toilet"
                            value={formData?.S_C_Toilet}
                            placeholder="Enter no. of toilet"
                            onChange={(event)=>handleSearchForm(event)}
                        />
                    </div>
                    <div className="ap-flex ap-flex-center-center ap-w25 ap-p5">
                        <div className="ap-flex ap-w100 ap-fsm ap-fwmd">Balcony</div>
                        <Input 
                            size="middle"
                            type="number"
                            name="S_Balcony"
                            value={formData?.S_Balcony}
                            placeholder="Enter Balcony"
                            onChange={(event)=>handleSearchForm(event)}
                        />
                    </div>
                </div>
            </Panel>
            <Panel header={<span className="ap-fsm ap-fwmd">3rd Floor</span>} key="5">
                <div className="ap-flex ap-w100">
                    <div className="ap-flex ap-flex-center-center ap-w25 ap-p5">
                        <div className="ap-flex ap-w100 ap-fsm ap-fwmd">Master Bed</div>
                        <Input 
                            size="middle"
                            type="number"
                            name="T_MasterBed"
                            value={formData?.T_MasterBed}
                            placeholder="Enter master bed"
                            onChange={(event)=>handleSearchForm(event)}
                        />
                    </div>
                     <div className="ap-flex ap-flex-center-center ap-w25 ap-p5">
                        <div className="ap-flex ap-w100 ap-fsm ap-fwmd">Dressing</div>
                        <Input 
                            size="middle"
                            type="number"
                            name="T_Dressing"
                            value={formData?.T_Dressing}
                            placeholder="Enter dressing"
                            onChange={(event)=>handleSearchForm(event)}
                        />
                    </div>
                    <div className="ap-flex ap-flex-center-center ap-w25 ap-p5">
                        <div className="ap-flex ap-w100 ap-fsm ap-fwmd">Guest Room</div>
                        <Input 
                            size="middle"
                            type="number"
                            name="T_GuestRoom"
                            value={formData?.T_GuestRoom}
                            placeholder="Enter guest room"
                            onChange={(event)=>handleSearchForm(event)}
                        />
                    </div>
                    <div className="ap-flex ap-flex-center-center ap-w25 ap-p5">
                        <div className="ap-flex ap-w100 ap-fsm ap-fwmd">Kitchen</div>
                        <Input 
                            size="middle"
                            type="number"
                            name="T_Kitchen"
                            value={formData?.T_Kitchen}
                            placeholder="Enter kitchen"
                            onChange={(event)=>handleSearchForm(event)}
                        />
                    </div>
                </div>
                <div className="ap-flex ap-w100">
                    <div className="ap-flex ap-flex-center-center ap-w25 ap-p5">
                        <div className="ap-flex ap-w100 ap-fsm ap-fwmd">Wash</div>
                        <Input 
                            size="middle"
                            type="number"
                            name="T_Wash"
                            value={formData?.T_Wash}
                            placeholder="Enter wash"
                            onChange={(event)=>handleSearchForm(event)}
                        />
                    </div>
                    <div className="ap-flex ap-flex-center-center ap-w25 ap-p5">
                        <div className="ap-flex ap-w100 ap-fsm ap-fwmd">Drawing</div>
                        <Input 
                            size="middle"
                            type="number"
                            name="T_Drawing"
                            value={formData?.T_Drawing}
                            placeholder="Enter drawing"
                            onChange={(event)=>handleSearchForm(event)}
                        />
                    </div>
                    <div className="ap-flex ap-flex-center-center ap-w25 ap-p5">
                        <div className="ap-flex ap-w100 ap-fsm ap-fwmd">Living</div>
                        <Input 
                            size="middle"
                            type="number"
                            name="T_Living"
                            value={formData?.T_Drawing}
                            placeholder="Enter living"
                            onChange={(event)=>handleSearchForm(event)}
                        />
                    </div>
                    <div className="ap-flex ap-flex-center-center ap-w25 ap-p5">
                        <div className="ap-flex ap-w100 ap-fsm ap-fwmd">Dining</div>
                        <Input 
                            size="middle"
                            type="number"
                            name="T_Dining"
                            value={formData?.T_Dining}
                            placeholder="Enter dining"
                            onChange={(event)=>handleSearchForm(event)}
                        />
                    </div>
                </div>
                <div className="ap-flex ap-w100">
                    <div className="ap-flex ap-flex-center-center ap-w25 ap-p5">
                        <div className="ap-flex ap-w100 ap-fsm ap-fwmd">Toilet</div>
                        <Input 
                            size="middle"
                            type="number"
                            name="T_C_Toilet"
                            value={formData?.T_C_Toilet}
                            placeholder="Enter no. of toilet"
                            onChange={(event)=>handleSearchForm(event)}
                        />
                    </div>
                    <div className="ap-flex ap-flex-center-center ap-w25 ap-p5">
                        <div className="ap-flex ap-w100 ap-fsm ap-fwmd">Balcony</div>
                        <Input 
                            size="middle"
                            type="number"
                            name="T_Balcony"
                            value={formData?.T_Balcony}
                            placeholder="Enter Balcony"
                            onChange={(event)=>handleSearchForm(event)}
                        />
                    </div>
                </div>
            </Panel>
            <Panel header={<span className="ap-fsm ap-fwmd">4th Floor</span>} key="6">
                <div className="ap-flex ap-w100">
                    <div className="ap-flex ap-flex-center-center ap-w25 ap-p5">
                        <div className="ap-flex ap-w100 ap-fsm ap-fwmd">Master Bed</div>
                        <Input 
                            size="middle"
                            type="number"
                            name="FT_MasterBed"
                            value={formData?.FT_MasterBed}
                            placeholder="Enter master bed"
                            onChange={(event)=>handleSearchForm(event)}
                        />
                    </div>
                     <div className="ap-flex ap-flex-center-center ap-w25 ap-p5">
                        <div className="ap-flex ap-w100 ap-fsm ap-fwmd">Dressing</div>
                        <Input 
                            size="middle"
                            type="number"
                            name="FT_Dressing"
                            value={formData?.FT_Dressing}
                            placeholder="Enter dressing"
                            onChange={(event)=>handleSearchForm(event)}
                        />
                    </div>
                    <div className="ap-flex ap-flex-center-center ap-w25 ap-p5">
                        <div className="ap-flex ap-w100 ap-fsm ap-fwmd">Guest Room</div>
                        <Input 
                            size="middle"
                            type="number"
                            name="FT_GuestRoom"
                            value={formData?.FT_GuestRoom}
                            placeholder="Enter guest room"
                            onChange={(event)=>handleSearchForm(event)}
                        />
                    </div>
                    <div className="ap-flex ap-flex-center-center ap-w25 ap-p5">
                        <div className="ap-flex ap-w100 ap-fsm ap-fwmd">Kitchen</div>
                        <Input 
                            size="middle"
                            type="number"
                            name="FT_Kitchen"
                            value={formData?.FT_Kitchen}
                            placeholder="Enter kitchen"
                            onChange={(event)=>handleSearchForm(event)}
                        />
                    </div>
                </div>
                <div className="ap-flex ap-w100">
                    <div className="ap-flex ap-flex-center-center ap-w25 ap-p5">
                        <div className="ap-flex ap-w100 ap-fsm ap-fwmd">Wash</div>
                        <Input 
                            size="middle"
                            type="number"
                            name="FT_Wash"
                            value={formData?.FT_Wash}
                            placeholder="Enter wash"
                            onChange={(event)=>handleSearchForm(event)}
                        />
                    </div>
                    <div className="ap-flex ap-flex-center-center ap-w25 ap-p5">
                        <div className="ap-flex ap-w100 ap-fsm ap-fwmd">Drawing</div>
                        <Input 
                            size="middle"
                            type="number"
                            name="FT_Drawing"
                            placeholder="Enter drawing"
                            value={formData?.FT_Drawing}
                            onChange={(event)=>handleSearchForm(event)}
                        />
                    </div>
                    <div className="ap-flex ap-flex-center-center ap-w25 ap-p5">
                        <div className="ap-flex ap-w100 ap-fsm ap-fwmd">Living</div>
                        <Input 
                            size="middle"
                            type="number"
                            name="FT_Living"
                            value={formData?.FT_Living}
                            placeholder="Enter living"
                            onChange={(event)=>handleSearchForm(event)}
                        />
                    </div>
                    <div className="ap-flex ap-flex-center-center ap-w25 ap-p5">
                        <div className="ap-flex ap-w100 ap-fsm ap-fwmd">Dining</div>
                        <Input 
                            size="middle"
                            type="number"
                            name="FT_Dining"
                            value={formData?.FT_Dining}
                            placeholder="Enter dining"
                            onChange={(event)=>handleSearchForm(event)}
                        />
                    </div>
                </div>
                <div className="ap-flex ap-w100">
                    <div className="ap-flex ap-flex-center-center ap-w25 ap-p5">
                        <div className="ap-flex ap-w100 ap-fsm ap-fwmd">Toilet</div>
                        <Input 
                            size="middle"
                            type="number"
                            name="FT_C_Toilet"
                            value={formData?.FT_C_Toilet}
                            placeholder="Enter no. of toilet"
                            onChange={(event)=>handleSearchForm(event)}
                        />
                    </div>
                    <div className="ap-flex ap-flex-center-center ap-w25 ap-p5">
                        <div className="ap-flex ap-w100 ap-fsm ap-fwmd">Balcony</div>
                        <Input 
                            size="middle"
                            type="number"
                            name="FT_Balcony"
                            value={formData?.FT_Balcony}
                            placeholder="Enter Balcony"
                            onChange={(event)=>handleSearchForm(event)}
                        />
                    </div>
                </div>
            </Panel>
            <Panel header={<span className="ap-fsm ap-fwmd">Other Search</span>} key="7">
                <div className="ap-flex ap-w100">
                    <div className="ap-flex ap-flex-center-center ap-w25 ap-p5">
                        <div className="ap-flex ap-w100 ap-fsm ap-fwmd">Attachment</div>
                        <Input 
                            size="middle"
                            type="text"
                            name="Attachment"
                            value={formData?.Attachment}
                            placeholder="Enter attachment url"
                            onChange={(event)=>handleSearchForm(event)}
                        />
                    </div>
                    <div className="ap-flex ap-flex-center-center ap-w25 ap-p5">
                        <div className="ap-flex ap-w100 ap-fsm ap-fwmd">Folder Name</div>
                        <Input 
                            size="middle"
                            type="text"
                            name="Foldername"
                            value={formData?.Foldername}
                            placeholder="Enter folder url"
                            onChange={(event)=>handleSearchForm(event)}
                        />
                    </div>
                    <div className="ap-flex ap-flex-center-center ap-w25 ap-p5">
                        <div className="ap-flex ap-w100 ap-fsm ap-fwmd">Services Given</div>
                        <Input 
                            size="middle"
                            type="text"
                            name="Services_Given"
                            value={formData?.Services_Given}
                            placeholder="Enter given services"
                            onChange={(event)=>handleSearchForm(event)}
                        />
                    </div>
                    <div className="ap-flex ap-flex-center-center ap-w25 ap-p5">
                        <div className="ap-flex ap-w100 ap-fsm ap-fwmd">Trello Link</div>
                        <Input 
                            size="middle"
                            type="text"
                            name="Trello_Link"
                            value={formData?.Trello_Link}
                            placeholder="Enter trello link"
                            onChange={(event)=>handleSearchForm(event)}
                        />
                    </div>
                </div>

                <div className="ap-flex ap-w100">
                    <div className="ap-flex ap-flex-center-center ap-w25 ap-p5">
                        <div className="ap-flex ap-w100 ap-fsm ap-fwmd">Folder Link</div>
                        <Input 
                            size="middle"
                            type="text"
                            name="Folder_Link"
                            value={formData?.Folder_Link}
                            placeholder="Enter folder link"
                            onChange={(event)=>handleSearchForm(event)}
                        />
                    </div>
                    <div className="ap-flex ap-flex-center-center ap-w25 ap-p5">
                        <div className="ap-flex ap-w100 ap-fsm ap-fwmd">Plan Type</div>
                        <Select 
                            className="ap-w100"
                            size="middle"
                            placeholder="Select Plan"
                            showSearch
                            value={formData?.Plan_Type}
                            onChange={(data)=>{handleSearchForm({target:{name:"Plan_Type",value:data}})}}
                        >
                           <Option value="1 sided road">1 sided road</Option>
                           <Option value="2 sided road">2 sided road</Option>
                        </Select>
                    </div>
                    <div className="ap-flex ap-flex-center-center ap-w25 ap-p5">
                        <div className="ap-flex ap-w100 ap-fsm ap-fwmd">Store Room</div>
                        <Select 
                            className="ap-w100"
                            size="middle"
                            placeholder="Select Option"
                            showSearch
                            value={formData?.StoreRoom}
                            onChange={(data)=>{handleSearchForm({target:{name:"StoreRoom",value:data}})}}
                        >
                           <Option value="yes">Yes</Option>
                           <Option value="no">No</Option>
                        </Select>
                    </div>
                    <div className="ap-flex ap-flex-center-center ap-w25 ap-p5">
                        <div className="ap-flex ap-w100 ap-fsm ap-fwmd">Foyer</div>
                        <Select 
                            className="ap-w100"
                            size="middle"
                            placeholder="Select Option"
                            showSearch
                            value={formData?.Foyer}
                            onChange={(data)=>{handleSearchForm({target:{name:"Foyer",value:data}})}}
                        >
                           <Option value="yes">Yes</Option>
                           <Option value="no">No</Option>
                        </Select>
                    </div>
                </div>

                <div className="ap-flex ap-w100">
                    <div className="ap-flex ap-flex-center-center ap-w25 ap-p5">
                        <div className="ap-flex ap-w100 ap-fsm ap-fwmd">Pooja Room</div>
                        <Select 
                            className="ap-w100"
                            size="middle"
                            placeholder="Select Option"
                            showSearch
                            value={formData?.PoojaRoom}
                            onChange={(data)=>{handleSearchForm({target:{name:"PoojaRoom",value:data}})}}
                        >
                           <Option value="yes">Yes</Option>
                           <Option value="no">No</Option>
                        </Select>
                    </div>
                    <div className="ap-flex ap-flex-center-center ap-w25 ap-p5">
                        <div className="ap-flex ap-w100 ap-fsm ap-fwmd">Garden</div>
                        <Select 
                            className="ap-w100"
                            size="middle"
                            placeholder="Select Option"
                            showSearch
                            value={formData?.Garden}
                            onChange={(data)=>{handleSearchForm({target:{name:"Garden",value:data}})}}
                        >
                           <Option value="yes">Yes</Option>
                           <Option value="no">No</Option>
                        </Select>
                    </div>
                    <div className="ap-flex ap-flex-center-center ap-w25 ap-p5">
                        <div className="ap-flex ap-w100 ap-fsm ap-fwmd">Shop</div>
                        <Select 
                            className="ap-w100"
                            size="middle"
                            placeholder="Select Option"
                            showSearch
                            value={formData?.Shop}
                            onChange={(data)=>{handleSearchForm({target:{name:"Shop",value:data}})}}
                        >
                           <Option value="yes">Yes</Option>
                           <Option value="no">No</Option>
                        </Select>
                    </div>
                    <div className="ap-flex ap-flex-center-center ap-w25 ap-p5">
                        <div className="ap-flex ap-w100 ap-fsm ap-fwmd">Lift</div>
                        <Select 
                            className="ap-w100"
                            size="middle"
                            placeholder="Select Option"
                            showSearch
                            value={formData?.Lift}
                            onChange={(data)=>{handleSearchForm({target:{name:"Lift",value:data}})}}
                        >
                           <Option value="yes">Yes</Option>
                           <Option value="no">No</Option>
                        </Select>
                    </div>
                </div>

                <div className="ap-flex ap-w100">
                    <div className="ap-flex ap-flex-center-center ap-w25 ap-p5">
                        <div className="ap-flex ap-w100 ap-fsm ap-fwmd">Godown</div>
                        <Select 
                            className="ap-w100"
                            size="middle"
                            placeholder="Select Option"
                            showSearch
                            value={formData?.Godown}
                            onChange={(data)=>{handleSearchForm({target:{name:"Godown",value:data}})}}
                        >
                           <Option value="yes">Yes</Option>
                           <Option value="no">No</Option>
                        </Select>
                    </div>
                    <div className="ap-flex ap-flex-center-center ap-w25 ap-p5">
                        <div className="ap-flex ap-w100 ap-fsm ap-fwmd">Office</div>
                        <Select 
                            className="ap-w100"
                            size="middle"
                            placeholder="Select Option"
                            showSearch
                            value={formData?.Office}
                            onChange={(data)=>{handleSearchForm({target:{name:"Office",value:data}})}}
                        >
                           <Option value="yes">Yes</Option>
                           <Option value="no">No</Option>
                        </Select>
                    </div>
                    <div className="ap-flex ap-flex-center-center ap-w25 ap-p5">
                        <div className="ap-flex ap-w100 ap-fsm ap-fwmd">Basement</div>
                        <Select 
                            className="ap-w100"
                            size="middle"
                            placeholder="Select Option"
                            showSearch
                            value={formData?.Basement}
                            onChange={(data)=>{handleSearchForm({target:{name:"Basement",value:data}})}}
                        >
                           <Option value="yes">Yes</Option>
                           <Option value="no">No</Option>
                        </Select>
                    </div>
                </div>
            </Panel>
        </Collapse>
        <div className="ap-flex ap-w100" style={{marginTop:"10px"}}>
            <div className="ap-flex ap-flex-center-center search-btn">
                <Button 
                    type="primary" 
                    className="ap-btn-c" 
                    shape="round"  
                    icon={<Icons.Search />} 
                    size="middle"
                    disabled={Object.keys(formData).length === 0} 
                    loading={state.masterProjects.loading}
                    htmlType="submit"
                >
                    <span className="ap-fesm ap-fwmd">Search</span>
                </Button>
            </div>
            <div className="ap-flex ap-flex-center-center search-btn" style={{marginLeft:"10px"}}>
                <Button 
                    htmlType="button"
                    type="primary" 
                    shape="round"  
                    className="ap-btn-c" 
                    icon={<Icons.Sweep />} 
                    size="middle"
                    disabled={Object.keys(formData).length === 0} 
                    loading={state.masterProjects.loading}
                    onClick={()=>{setTableData([]);masterProjects('',true);setFormData({});setCurrent({page:1,size:10})}}>
                    <span className="ap-fesm ap-fwmd">Clear</span> 
                </Button>
            </div>
        </div>
    </form>
    
    {tableData.length > 0 && <div className="ap-flex ap-w100" style={{marginTop:"20px"}}>
            <Table 
                columns={columns} 
                dataSource={tableData} 
                scroll={{ x: "100%" }} 
                pagination={
                    {
                        current:current.page,
                        pageSize:current.size,
                        position:["bottomLeft"],
                        total:state.masterProjects.totalRecords,
                        onChange:(data,size)=>{setTableData([]);callRecords({type:"page",payload:{page:data,size:size}})},
                        showTotal:(total, range) => `${range[0]}-${range[1]} of ${total} items`,
                    }
                }
            />
    </div>}
    {state.masterProjects.loading && <div className="ap-flex ap-w100 ap-flex-center-center" style={{height:"300px"}}>
        <Spin tip="Fetching Records....."/>
    </div>}
    </>
}

export default MasterProjectsList;