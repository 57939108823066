import { axiosInstance } from ".."

const rsReminder = async (code) =>{

    return new Promise(async (resolve,reject)=>{
        axiosInstance.post(`/internal/form/sendReminder/${code}`).then((res) => { 
            if(res.status === 200){
                return resolve(res)
            }
            return resolve(res)
        }).catch((err) => {
            return reject(err)
        })
    })
}

export default rsReminder;