import React from "react";
import { BrowserRouter } from "react-router-dom";
import AdminRoutes from "./AdminRoutes";
import OtherRoutes from "./OtherRoutes";
import { Middleware, Loader } from "../Components";

const Routes = (props) =>{

    return <BrowserRouter>
            <Middleware>
                <React.Suspense fallback={<Loader />}>
                    <AdminRoutes />
                    <OtherRoutes />
                </React.Suspense>
            </Middleware>
        </BrowserRouter>   
}

export default Routes