const masterProjectsReducer = (state = {
    loading:false,
    projects:null,totalRecords:0,msg:''
}, action) => {
    switch (action.type) {
        case "FETCH_MASTER_PROJECTS":
            return { ...state ,loading:true,projects:null,totalRecords:0,msg:""}
        case "RECEIVE_MASTER_PROJECTS":
            return {...state, projects:[...action.payload?.projects],loading:false,totalRecords:action.payload.totalProjects,msg:''}
        case "ERROR_MASTER_PROJECTS":
            return { ...state ,projects:null,loading:true,totalRecords:0,msg:action.payload}
        case "ERROR_MASTER_PROJECTS_BLANK":
                return {...state, projects:null,loading:false,totalRecords:0,msg:''}
        default:
            return { ...state }
    }
}

export default masterProjectsReducer;