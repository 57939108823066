import { API } from "../../Services"

export const sitesList = (query) =>{
    return (dispatch) =>{
        dispatch({
          type: "FETCH_SITES",
        });
         API.sitesList(query).then((res)=>{

              
          console.log("response site data ", res.data.data);
            setTimeout(()=>{
                dispatch({
                  type: "RECEIVE_SITES",

                  payload: Array.isArray(res.data.data)
                    ? { totalRecords: 0, projects: [] }
                    : res.data.data,
                });
            },2000)
        }).catch((err)=>{
            dispatch({
              type: "ERROR_SITES",
            });
        })
    }
}