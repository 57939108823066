import React, { useEffect, useState } from "react";
import "../../Styles/Master.scss";
import "./PhoneCountryInput.scss";
import { Input,Select } from "antd";
import { useSelector } from "react-redux";


const PhoneCountryInput = (props) =>{

    const { Option } = Select;
    const state = useSelector(state=>state)
    const [value,setValue] = useState({
        isdCode:props?.value?.code,
        number:props?.value?.no
    })
    const [country,setCountry] = useState("")
    
    useEffect(()=>{
        state.phoneCountryInput.phoneCountryInput.map((pci,pciIndex)=>{
            if(`${pci.value}` === `+${props.value.code}` ){
                setCountry((prev)=>pci)
            }
        })
    },[])

    const setCountryData = (data)=>{
        setValue((prev)=>{
            prev = JSON.parse(JSON.stringify(prev))
            prev.isdCode = data.value.slice(1)
            return prev
        })
        setCountry(data)
    }

    useEffect(()=>{
        props.onChange(value)
    },[value])
    


    return <div className="ap-flex ap-w100">
        <Input.Group compact>
            <Select 
                showArrow={false}
                labelInValue
                value={country}
                style={{width:"20%"}}
                onChange={(data)=>setCountryData(data)}
            >
                {state.phoneCountryInput.phoneCountryInput.map((pci,pciIndex)=>{
                    return <Option value={pci.value} key={pciIndex}>
                            {pci.label}
                    </Option>
                })}
            </Select>
            <Input 
                style={{width:"20%"}}
                value={value?.isdCode}
                placeholder={props?.placeholder}
                readOnly
            />
            <Input 
                type="number"
                value={value?.number}
                placeholder={props?.placeholder}
                style={{ width: '60%' }}
                onChange={(event)=>setValue((prev)=>{
                    prev = JSON.parse(JSON.stringify(prev))
                    prev.number = event.target.value
                    return prev
                })} 
            />
        </Input.Group>
    </div>
}

export default PhoneCountryInput