import {
  Button,
  CloseButton,
  ModalBody,
  ModalHeader,
  Radio,
  RadioGroup,
  Text,
  Textarea,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { Submit } from "./attachementStyle";

export default function SetApprovalStatus(props) {
  const [formData, setFormData] = useState({});

  const handeOnChange = (event) => {
    setFormData((prev) => {
      prev = JSON.parse(JSON.stringify(prev));
      prev[`${event.target.name}`] = event.target.value;
      return prev;
    });
  };
  return (
    <div>
      <ModalHeader style={{ justifyContent: "space-between", display: "flex" }}>
        Set Approval Status of Attachements
        <div>
          <CloseButton
            onClick={() => {
              props.onClose();
            }}
          />
        </div>
      </ModalHeader>
      <ModalBody>
        <RadioGroup
          value={formData?.approvalStatus ? formData?.approvalStatus : ""}
          onChange={(e) => {
            handeOnChange({
              target: { name: "approvalStatus", value: e },
            });
          }}
        >
          <span style={{ padding: " 0.5rem" }}>
            <Radio value={"approved"}>Approved</Radio>
          </span>
          <span style={{ padding: " 0.5rem" }}>
            <Radio value={"declined"}>Declined</Radio>
          </span>
        </RadioGroup>
        <Text mb="8px">Comment</Text>
        <Textarea
          // value={value}
          onChange={(e) => {
            console.log(e.target.value);
            handeOnChange({
              target: { name: "approvalComment", value: e.target.value },
            });
          }}
          placeholder="Approval comment ...."
          size="sm"
        />
        <Submit>
          <Button
            className="submit_right1"
            isDisabled={!(formData?.approvalStatus&&formData?.approvalComment)}
            onClick={() => {
              props.setApprovalStatus(formData);
              props.onClose();
            }}
          >
            Submit
          </Button>
        </Submit>
      </ModalBody>
    </div>
  );
}
