import { axiosInstance } from ".."

const paymentLinkList = async (querry) =>{

    return new Promise(async (resolve,reject)=>{
        axiosInstance.get(`internal/payment/link?${querry}`).then((res) => { 
            console.log(res)
            if(res.status === 200){
                return resolve(res)
            }
            return resolve(res)
        }).catch((err) => {
            return reject(err)
        })
    })
}

export default paymentLinkList;