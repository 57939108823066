const productsReducer = (state = {
    loading:false,
    products:[],totalRecords:0
}, action) => {
    switch (action.type) {
        case "FETCH_PRODUCTS":
            return { ...state ,loading:true,products:[],totalRecords:0}
        case "RECEIVE_PRODUCTS":
            return {...state, products:[...action.payload.products],loading:false,totalRecords:action.payload.totalRecords}
        case "ERROR_PRODUCTS":
            return { ...state ,products:[],loading:true,totalRecords:0}
        default:
            return { ...state }
    }
}

export default productsReducer;