
const projectAttachementSubCategoriesReducer = (state = {
    loading:false,
    subCategories:[],totalRecords:0,msg:''
}, action) => {
    switch (action.type) {
        case "FETCH_ATTACHEMENT_SUB_CATEGORIES_LIST":
            return { ...state ,loading:true,subCategories:[],totalRecords:0,msg:''}
        case "RECEIVE_ATTACHEMENT_SUB_CATEGORIES_LIST":
            return {...state, subCategories:[...action.payload.data],loading:false,totalRecords:action.payload.totalRecords,msg:''}
        case "ERROR_ATTACHEMENT_SUB_CATEGORIES_LIST":
            return { ...state ,subCategories:[],loading:false,totalRecords:0,msg:action.payload}
        default:
            return { ...state }
    }
}

export default projectAttachementSubCategoriesReducer;