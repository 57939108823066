const customerReducer = (state = {
    loading:false,
    customer:null
}, action) => {
    switch (action.type) {
        case "FETCH_CUSTOMER":
            return { ...state ,loading:true}
        case "RECEIVE_CUSTOMER":
            return {...state, customer:action.payload,loading:false,}
        case "ERROR_CUSTOMER":
            return { ...state ,loading:true}
        default:
            return { ...state }
    }
}

export default customerReducer;