import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Home as H,Login } from "../Layouts"

const Chat = React.lazy(() => import('../chatapp/views/chat'));
const PendingApprovals = React.lazy(() => import('../chatapp/views/chat/pendingapprovals'));
const LeadChat = React.lazy(() => import('../chatapp/views/leadchat/index'));
const Home = React.lazy(() => import('../chatapp/views/home'));
const OrderListing = React.lazy(() => import('../chatapp/views/OrderDetailing/index'));
const OrderDetailing = React.lazy(() => import('../chatapp/views/OrderDetailing/orderDetailing'));
const BrowserFile = React.lazy(() => import('../Layouts/TrelloDetails/BrowserFile/BrowserFile.jsx'));

const OtherRoutes = (props) =>{
    return (
      <Switch>
        <Route path={["/login", ""]} exact component={Login} />
        <Route path="/home/new/home" exact={true} component={H} />
        <Route path="/dashboard" name="Home" component={Home} />
        <Route
          path="/chat"
          name="Chat"
          render={(props) => <Chat {...props} />}
        />
        <Route
          path="/file-browse/:id"
          name="file-browse"
          render={(props) => <BrowserFile {...props} />}
        />

        <Route
          path="/leadchat"
          name="Chat"
          render={(props) => <LeadChat {...props} />}
        />
        <Route
          path="/pendingApprovals"
          name="PendingApprovals"
          render={(props) => <PendingApprovals {...props} />}
        />
        <Route
          path="/OrderList"
          name="OrderListing"
          render={(props) => <OrderListing {...props} />}
        />
        <Route
          path="/OrderDetails"
          name="OrderListing"
          render={(props) => <OrderDetailing {...props} />}
        />
      </Switch>
    );
}

export default OtherRoutes;